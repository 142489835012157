export const Abbreviations = {
	// CARRIER DATA
	orderId: 'ID',
	carNumber: 'CAR #',
	inspector: 'INSP.',
	grade: 'GRADE',
	// FACTORS
	testWeight: 'T.W.',
	moisture: 'MO.',
	damage: 'DKT.',
	heat: 'HT.',
	bcfm: 'BCFM',
	fm: 'F.M.',
	sbk: 'SBK',
	splits: 'SPLITS',
	sboc: 'SBOC',
	shb: 'SHB',
	wocl: 'WOCL',
	defects: 'DEFECT',
	cc: 'CC',
	dockage: 'DOCK.',
	ocol: 'OCOL',
	// SAMPLE
	cstb: 'CSTB',
	crot: 'CROT',
	htg: 'HTG',
	ston: 'STON',
	stonCount: 'STON',
	stonWeight: 'STON',
	glass: 'GLASS',
	fsub: 'FSUB',
	odor: 'ODOR',
	cbur: 'CBUR',
	anfl: 'ANFL',
	idk: 'IDK',
	// SPECIAL
	flint: 'FLIN',
	flintDent: 'FLAD',
	waxy: 'WAXY',
	garlicky: 'GAR',
	dgb: 'DGB',
	ggb: 'GGB',
	pms: 'PMS',
	tret: 'TRET',
	erg: 'ERG',
	smut: 'SMUT',
	lsm: 'LSM',
	sbal: 'SBAL'
};

export const CommodityAbbreviations = {
	'Yellow Corn': 'YC',
	'White Corn': 'WC',
	'Mixed Corn': 'MC',
	'Yellow Soybeans': 'YSB',
	'Mixed Soybeans': 'MSB',
	'Soft Red Winter Wheat': 'SRW',
	'Hard Red Winter Wheat': 'HRW',
	'Hard Red Spring Wheat': 'HRS',
	'Durum Wheat': 'DUR',
	'Soft White Wheat': 'SW',
	'Hard White Wheat': 'HW'
};

export default Abbreviations;
