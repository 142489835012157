import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateInspection } from 'Actions';
import { commodityGroups, commodityTypes, inspectionTypes, lotTypes, sampleGroupings, sampleTypes } from 'models/options';
import { getInspection } from 'Selectors';
import { Spinner, Button } from 'Controls';
import styles from './Main.module.css';

const Main = () => {
	const dispatch = useDispatch();
	const [mycotoxins, setMycotoxins] = React.useState(false);
	const { id, inspection } = useSelector(getInspection);

	const update = React.useCallback(data => dispatch(updateInspection(id, data)), [dispatch, id]);

	if (!mycotoxins)
		return (
			<>
				<div className={styles.Columns}>
					<Spinner className={styles.Spinner} values={lotTypes} value={inspection.lotType} onChange={value => update({ lotType: value })} />
					<Spinner
						className={styles.Spinner}
						values={inspectionTypes}
						value={inspection.inspectionType}
						onChange={value => update({ inspectionType: value })}
					/>
					<Spinner className={styles.Spinner} values={sampleTypes} value={inspection.sampleType} onChange={value => update({ sampleType: value })} />
					<Spinner
						className={styles.Spinner}
						values={sampleGroupings}
						value={inspection.sampleGrouping}
						onChange={value => update({ sampleGrouping: value })}
					/>
					<Spinner
						className={styles.Spinner}
						values={commodityGroups}
						value={inspection.commodityGroup}
						onChange={value => update({ commodityGroup: value, commodityType: null })}
					/>
					<Spinner
						className={styles.Spinner}
						values={commodityTypes[inspection.commodityGroup] ?? []}
						value={inspection.commodityType}
						onChange={value => update({ commodityType: value })}
					/>
				</div>
				<Button fullWidth size="large" color="info" className={styles.Button} onClick={() => setMycotoxins(true)}>
					Mycotoxins
				</Button>
			</>
		);
	else
		return (
			<>
				<div className={styles.Columns}>
					<Button
						fullWidth
						size="large"
						color={inspection.afla && 'primary'}
						className={styles.Button}
						onClick={() => update({ afla: !Boolean(inspection.afla) })}>
						AFLA
					</Button>
					<Button
						fullWidth
						size="large"
						color={inspection.don && 'primary'}
						className={styles.Button}
						onClick={() => update({ don: !Boolean(inspection.don) })}>
						DON
					</Button>
					<Button
						fullWidth
						size="large"
						color={inspection.fumn && 'primary'}
						className={styles.Button}
						onClick={() => update({ fumn: !Boolean(inspection.fumn) })}>
						FUMN
					</Button>
					<Button
						fullWidth
						size="large"
						color={inspection.zear && 'primary'}
						className={styles.Button}
						onClick={() => update({ zear: !Boolean(inspection.zear) })}>
						ZEAR
					</Button>
				</div>
				<Button fullWidth light size="large" className={styles.Button} onClick={() => setMycotoxins(false)}>
					Back
				</Button>
			</>
		);
};

export default Main;
