import React from 'react';

const FieldLabel = ({ children, label, ...props }) => (
	<div className="field">
		<label className="label">{label}</label>
		<div className="control">{children}</div>
	</div>
);

export default FieldLabel;
