import React from 'react';

const DialogHeader = ({ title, onClose, disabled, ...props }) => (
	<header className="modal-card-head">
		<div className="modal-card-title">{title}</div>
		<button className="delete" aria-label="close" disabled={disabled} onClick={onClose} />
	</header>
);

export default DialogHeader;
