import React from 'react';
import clsx from 'clsx';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import styles from './Password.module.css';

const Password = ({ className, defaultShow, ...props }) => {
	const [show, setShow] = React.useState(defaultShow ?? false);
	const ref = React.createRef();

	React.useEffect(() => {
		if (ref.current) ref.current.selectionStart = ref.current.selectionEnd = ref.current.value?.length;
	}, [show, ref]);

	return (
		<div className={clsx(styles.Root, className)}>
			<input className={clsx('input', styles.Input)} type={show ? 'text' : 'password'} ref={ref} {...props} />
			<span className={styles.Icon} onClick={() => setShow(s => !s)} onMouseDown={e => e.preventDefault()}>
				{show ? <VisibilityOffIcon /> : <VisibilityIcon />}
			</span>
		</div>
	);
};

export default Password;
