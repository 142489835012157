import { SET_FACTOR_SCROLL, SET_CURRENT_INSPECTION } from '../Actions/types';

const factorScroll = (state = {}, action) => {
	switch (action.type) {
		case SET_FACTOR_SCROLL:
			return { ...state, [action.factor]: action.value };
		case SET_CURRENT_INSPECTION:
			return {};
		default:
			return state;
	}
};

export default factorScroll;
