import React from 'react';
import { readFile } from '../../lib/helpers';
import { Avatar, Collapse, List, Radio } from 'antd';
import { Button, FieldLabel, Loading, Dialog, DialogHeader, DialogContent, DialogFooter } from 'Controls';
import styles from './ImportCarriersDialog.module.css';

const Panel = Collapse.Panel;
const RadioGroup = Radio.Group;

class ImportCarriersDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			file: null,
			reading: false,
			canImport: false,
			separator: '\n'
		};
	}

	render() {
		const { onAccept, onCancel } = this.props;

		return (
			<Dialog onClose={onCancel}>
				<DialogHeader title="Import Carrier Data" onClose={onCancel} />
				<DialogContent>
					{this.state.file && (
						<div>
							<div className={styles.Header}>
								<h2 className="subtitle">Carriers Found: {this.state.file.length}</h2>
								<Button onClick={() => this.setState({ file: null, canImport: false })}>Back</Button>
							</div>
							<Collapse>
								<Panel className={styles.Panel} header="Carrier ID List" key="1">
									<List
										dataSource={this.state.file}
										renderItem={(item, index) => (
											<List.Item>
												<List.Item.Meta avatar={<Avatar>{index + 1}</Avatar>} />
												{item}
											</List.Item>
										)}
									/>
								</Panel>
							</Collapse>
						</div>
					)}
					{this.state.reading && <Loading message="Reading File..." />}
					{!this.state.reading && !this.state.file && (
						<>
							<FieldLabel label="Separator">
								<RadioGroup onChange={e => this.setState({ separator: e.target.value })} value={this.state.separator}>
									<Radio autoFocus value={'\n'}>
										Line Break
									</Radio>
									<Radio value=",">Comma</Radio>
									<Radio value=":">Semicolon</Radio>
									<Radio value=";">Colon</Radio>
								</RadioGroup>
							</FieldLabel>
							<div className="file">
								<label className="file-label">
									<input
										className="file-input"
										accept=".csv"
										type="file"
										name="resume"
										onChange={e => {
											const file = e.target.files[0];
											if (file) {
												this.setState({ reading: true });
												readFile(file).then(data => {
													const cars = data.split(this.state.separator).filter(v => !v.match(/\S/));
													this.setState({ reading: false, file: cars, canImport: true });
												});
											}
										}}
									/>
									<span className="file-cta">Choose a file…</span>
								</label>
							</div>
						</>
					)}
				</DialogContent>
				<DialogFooter>
					<Button
						color="primary"
						disabled={!this.state.canImport}
						onClick={() => {
							onAccept(this.state.file);
							onCancel();
						}}>
						Import
					</Button>
					<Button onClick={onCancel}>Cancel</Button>
				</DialogFooter>
			</Dialog>
		);
	}
}

export default ImportCarriersDialog;
