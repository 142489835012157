import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addLocation, deleteLocation, updateLocation, setDefaultLocation } from 'Actions';
import { COFIRM_DELETE_LOCATION } from 'lib/constants';
import { StringComparer, filterCollection } from 'lib/helpers';
import { getLocations } from 'Selectors';
import { ConfirmDialog } from 'Components/Dialogs';
import EditLocationDialog from './EditLocationDialog';
import { Menu, Popover, Table } from 'antd';
import { Button, MenuButton, Search, Modal } from 'Controls';
import styles from './Admin.module.css';

const ManageLocations = () => {
	const dispatch = useDispatch();

	const [editDialog, setEditDialog] = React.useState(null);
	const [deleteDialog, setDeleteDialog] = React.useState(null);
	const [showDeleted, setShowDeleted] = React.useState(false);
	const [sorter, setSorter] = React.useState({ columnKey: 'locationId', order: 'ascend' });
	const [textFilter, setTextFilter] = React.useState('');

	const locations = useSelector(getLocations);
	const locationsList = showDeleted ? [...locations.list, ...locations.deleted] : locations.list;

	const handleChange = (pagination, filters, sorter) => {
		setSorter(sorter);
	};

	const columnSort = key => sorter.columnKey === key && sorter.order;

	return (
		<div className={styles.Container}>
			<div className={styles.Topbar}>
				<Button color="primary" onClick={() => setEditDialog({})}>
					Add
				</Button>
				<Search placeholder="Search Locations" value={textFilter} onChange={e => setTextFilter(e.target.value)} />
				<MenuButton>
					{showDeleted === true && <Menu.Item onClick={() => setShowDeleted(false)}>Hide Deleted</Menu.Item>}
					{showDeleted === false && <Menu.Item onClick={() => setShowDeleted(true)}>Show Deleted</Menu.Item>}
				</MenuButton>
			</div>
			<div className={styles.Container}>
				<Table
					rowKey="locationId"
					size="small"
					showSorterTooltip={false}
					loading={locations.isFetching}
					scroll={{ scrollToFirstRowOnChange: true }}
					pagination={{ position: ['none', 'bottomCenter'], size: 'default', showSizeChanger: true, defaultPageSize: 20, showTotal: total => `${total} Locations` }}
					rowClassName={row => (row.deleted ? styles.DeletedRow : row.isDefault ? styles.DefaultRow : '')}
					dataSource={filterCollection(textFilter, locationsList)}
					onChange={handleChange}>
					<Table.Column
						title="ID"
						key="locationId"
						dataIndex="locationId"
						sorter={StringComparer('locationId')}
						sortOrder={columnSort('locationId')}
					/>
					<Table.Column title="Location" key="location" dataIndex="location" sorter={StringComparer('location')} sortOrder={columnSort('location')} />
					<Table.Column title="Company" key="company" dataIndex="company" sorter={StringComparer('company')} sortOrder={columnSort('company')} />
					<Table.Column
						title="Email Addresses"
						key="email"
						dataIndex="email"
						render={values =>
							values && values.length > 0 ? (
								<Popover
									content={values.map(v => (
										<div key={v}>{v}</div>
									))}>
									{values.length}
								</Popover>
							) : (
								''
							)
						}
					/>
					<Table.Column
						dataIndex="locationId"
						key="actions"
						width="60px"
						render={(cellData, rowData) => (
							<MenuButton disabled={rowData.deleted}>
								{rowData.isDefault !== true && <Menu.Item onClick={() => dispatch(setDefaultLocation(cellData))}>Set As Default</Menu.Item>}
								<Menu.Item onClick={() => setEditDialog({ ...rowData, isEditing: true })}>Edit</Menu.Item>
								<Menu.Item className="has-text-danger" onClick={() => setDeleteDialog(cellData)}>
									Delete
								</Menu.Item>
							</MenuButton>
						)}
					/>
				</Table>
			</div>
			<Modal show={editDialog}>
				<EditLocationDialog
					{...editDialog}
					onCancel={() => setEditDialog(null)}
					onAccept={(id, data) => (editDialog.isEditing === true ? dispatch(updateLocation(id, data)) : dispatch(addLocation(id, data)))}
				/>
			</Modal>
			<Modal show={deleteDialog}>
				<ConfirmDialog
					title="Delete Location"
					body={
						<>
							{COFIRM_DELETE_LOCATION}
							<br />
							<br />
							{deleteDialog}: {locations.byId[deleteDialog]?.location} ({locations.byId[deleteDialog]?.company})
						</>
					}
					buttonText="Delete"
					buttonColor="danger"
					onCancel={() => setDeleteDialog(null)}
					onAccept={() => dispatch(deleteLocation(deleteDialog))}
				/>
			</Modal>
		</div>
	);
};

export default ManageLocations;
