import React from 'react';

const Dialog = ({ children, onClose, ...props }) => {
	const handleKeyDown = React.useCallback(e => {
		if (onClose && e.key === 'Escape') onClose();
	}, [onClose]);

	return (
		<div className="modal is-active" onKeyDown={handleKeyDown} {...props}>
			<div className="modal-background" />
			<div className="modal-card">{children}</div>
		</div>
	);
};

export default Dialog;
