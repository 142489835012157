import React from 'react';
import { connect } from 'react-redux';
import { getSpreadsheet, getUserProfile } from 'Selectors';
import { Abbreviations } from 'models/factors/abbreviations';
import Mycotoxins from 'models/mycotoxins';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { Tabs } from 'antd';
import { Highlight, Loading } from 'Controls';
import { FactorsList } from 'Components';
import styles from './CarrierGridCert.module.css';

const TabPane = Tabs.TabPane;

class Spreadsheet extends React.Component {
	componentDidUpdate() {
		if (this._table) this._table.recomputeRowHeights();
	}

	notesRenderer = ({ rowData }) => {
		if (rowData.isAverage) return null;
		let type = null;
		if (rowData.rexId && rowData.offId) type = 'RELOAD, REX';
		else if (rowData.rexId) type = 'REX';
		else if (rowData.offId) type = 'RELOAD';
		return (
			<div>
				{type && <div>{type}</div>}
				<FactorsList
					data={
						this.props.user.isClient
							? Object.assign({}, rowData.sampleValuesClient, rowData.specialValuesClient)
							: Object.assign({}, rowData.sampleValues, rowData.specialValues, rowData.otherValues)
					}
				/>
				{this.props.user.isCertificate && rowData.notes && (
					<div>
						{rowData.notes.split('\n').map((item, key) => (
							<React.Fragment key={key}>
								{item}
								<br />
							</React.Fragment>
						))}
					</div>
				)}
			</div>
		);
	};

	weightRenderer = ({ cellData }) => (cellData ? parseInt(cellData, 10).toLocaleString() : '');

	gradeRenderer = ({ cellData, rowData }) => (
		<>
			<div>{cellData}</div>
			<div>{rowData['special']}</div>
		</>
	);

	mycoCellRenderer = ({ cellData, rowData, dataKey }) => {
		if (rowData.isAverage) return;
		const data = cellData.value ? cellData.value + ' ' + cellData.units + ', ' + cellData.inspector : '';

		if (rowData.mycoDiff && rowData.mycoDiff[dataKey]) {
			return (
				<div title={cellData} className={styles.Tag}>
					{data}
				</div>
			);
		} else return data;
	};

	rowHeightGetter = data => ({ index }) => {
		let count = 0,
			count2 = 0,
			count3 = 0;
		const samples = this.props.user.isClient ? data[index].sampleValuesClient : data[index].sampleValues;
		const specials = this.props.user.isClient ? data[index].specialValuesClient : data[index].specialValues;

		if (Array.isArray(data[index].netWeight)) count2 = data[index].netWeight.length;
		if (Array.isArray(data[index].carNumber)) count3 = data[index].netWeight.length;

		if (samples) count += Object.keys(samples).length;
		if (specials) count += Object.keys(specials).length;
		if (data[index].rexId || data[index].offId) count++;
		if (data[index].notes) count++;

		count = Math.max(count, count2, count3);
		return Math.max(4 + count * 24, 52);
	};

	listRenderer = ({ cellData, rowData }) => <FactorsList data={cellData} />;

	generateMycotoxinColumns = Object.keys(Mycotoxins).map(
		key =>
			this.props.spreadsheetDisplay.currentInspection[key] === true && (
				<Column
					label={key.toUpperCase()}
					dataKey={key}
					width={60}
					maxWidth={70}
					flexGrow={1}
					headerClassName={styles.AlignCenter}
					cellDataGetter={({ rowData, dataKey }) => rowData[dataKey] || { value: null, inspector: null }}
					cellRenderer={this.mycoCellRenderer}
				/>
			)
	);

	generateDisplayFactorColumns = (factors, renderer) =>
		factors.map(key => (
			<Column
				key={key}
				headerClassName={styles.AlignRight}
				className={styles.AlignRight}
				label={Abbreviations[key]}
				dataKey={key}
				width={60}
				maxWidth={100}
				flexGrow={1}
				cellRenderer={renderer}
			/>
		));

	animatedRenderer = ({ cellData, rowData, dataKey }) => (
		<Highlight textClass={!rowData.isAverage && rowData.diff && rowData.diff[dataKey] ? styles.Tag : ''} value={cellData} />
	);

	rowClassNameGetter = carriers => ({ index }) => {
		if (index < 0) return styles.HeaderRow;
		else if (carriers[index].isAverage) return styles.AverageRow;
		else return index % 2 === 0 ? styles.EvenRow : styles.OddRow;
	};

	render() {
		const { user, spreadsheetDisplay, isFetching } = this.props;
		const { displayFactors, carrierId, currentInspection } = spreadsheetDisplay;

		if (isFetching) {
			return <Loading message="Fetching Carriers" />;
		}

		const carrierList = spreadsheetDisplay.carriers;
		const rexList = spreadsheetDisplay.rex;
		const offList = spreadsheetDisplay.off;
		const averages = spreadsheetDisplay.averages;

		return (
			<div className={styles.Container}>
				<Tabs className={styles.Container} animated={{ tabPane: false }}>
					<TabPane tab="Carriers" key="1">
						<div className={styles.Container}>
							<AutoSizer>
								{({ height, width }) => (
									<Table
										ref={ref => (this._table = ref)}
										width={width}
										height={height}
										headerHeight={20}
										rowHeight={this.rowHeightGetter(carrierList)}
										rowCount={carrierList.length}
										rowGetter={({ index }) => carrierList[index]}
										scrollToIndex={carrierList.findIndex(i => i.id === carrierId)}
										rowClassName={this.rowClassNameGetter(carrierList)}>
										<Column label="#" dataKey="orderId" width={50} />
										<Column
											label="ID"
											dataKey="carNumber"
											width={60}
											maxWidth={100}
											flexGrow={1}
											cellRenderer={({ cellData }) => (Array.isArray(cellData) ? cellData.map(d => <div>{d}</div>) : cellData)}
										/>
										<Column
											label="N.W."
											dataKey="netWeight"
											width={60}
											maxWidth={100}
											flexGrow={1}
											cellRenderer={({ cellData }) =>
												Array.isArray(cellData) ? cellData.map(d => <div>{d ? parseInt(d, 10).toLocaleString() : ''}</div>) : cellData
											}
										/>
										{currentInspection.lotType === 'Container' && (
											<Column label="Booking #" dataKey="bookingNumber" width={50} maxWidth={100} flexGrow={1} />
										)}
										{currentInspection.lotType === 'Container' && (
											<Column label="Seal #" dataKey="sealNumber" width={50} maxWidth={100} flexGrow={1} />
										)}
										<Column label="Grade" dataKey="grade" width={100} flexGrow={2} cellRenderer={this.gradeRenderer} />
										{this.generateDisplayFactorColumns(displayFactors, this.animatedRenderer)}
										<Column dataKey="spacer" width={10} />
										{this.generateMycotoxinColumns}
										{user.isCertificate && <Column label="Insp." dataKey="inspector" width={60} />}
										<Column label="Notes" dataKey="fakeKey" width={150} maxWidth={300} flexGrow={2} cellRenderer={this.notesRenderer} />
									</Table>
								)}
							</AutoSizer>
						</div>
						<div className={styles.Averages}>
							<AutoSizer disableHeight>
								{({ height, width }) => (
									<Table
										width={width}
										height={40}
										headerHeight={20}
										rowHeight={40}
										disableHeader
										rowCount={1}
										rowGetter={({ index }) => averages}
										rowClassName={styles.AveragesRow}>
										<Column dataKey="orderId" width={50} cellRenderer={() => {}} />
										<Column dataKey="carNumber" width={60} maxWidth={100} flexGrow={1} cellRenderer={() => {}} />
										<Column dataKey="netWeight" width={60} maxWidth={100} flexGrow={1} cellRenderer={() => {}} />
										{currentInspection.lotType === 'Container' && (
											<Column label="Booking #" dataKey="bookingNumber" width={50} maxWidth={100} flexGrow={1} cellRenderer={() => {}} />
										)}
										{currentInspection.lotType === 'Container' && (
											<Column label="Seal #" dataKey="sealNumber" width={50} maxWidth={100} flexGrow={1} cellRenderer={() => {}} />
										)}
										<Column dataKey="grade" width={100} flexGrow={2} cellRenderer={() => {}} />
										{this.generateDisplayFactorColumns(displayFactors)}
										<Column dataKey="spacer" width={10} />
										{this.generateMycotoxinColumns}
										{user.isCertificate && <Column dataKey="inspector" width={60} />}
										<Column dataKey="fakeKey" width={150} maxWidth={300} flexGrow={2} cellRenderer={() => {}} />
									</Table>
								)}
							</AutoSizer>
						</div>
					</TabPane>
					<TabPane tab="Reinspected" key="2">
						<AutoSizer>
							{({ height, width }) => (
								<Table
									width={width}
									height={height}
									headerHeight={20}
									rowHeight={this.rowHeightGetter(rexList)}
									rowCount={rexList.length}
									rowGetter={({ index }) => rexList[index]}
									rowClassName={this.rowClassNameGetter(rexList)}>
									<Column label="#" dataKey="orderId" width={50} />
									<Column
										label="ID"
										dataKey="carNumber"
										width={60}
										maxWidth={100}
										flexGrow={1}
										cellRenderer={({ cellData }) => (Array.isArray(cellData) ? cellData.map(d => <div>{d}</div>) : cellData)}
									/>
									<Column
										label="N.W."
										dataKey="netWeight"
										width={60}
										maxWidth={100}
										flexGrow={1}
										cellRenderer={({ cellData }) =>
											Array.isArray(cellData) ? cellData.map(d => <div>{d ? parseInt(d, 10).toLocaleString() : ''}</div>) : cellData
										}
									/>
									{currentInspection.lotType === 'Container' && (
										<Column label="Booking #" dataKey="bookingNumber" width={50} maxWidth={100} flexGrow={1} />
									)}
									{currentInspection.lotType === 'Container' && (
										<Column label="Seal #" dataKey="sealNumber" width={50} maxWidth={100} flexGrow={1} />
									)}
									<Column label="Grade" dataKey="grade" width={100} flexGrow={2} cellRenderer={this.gradeRenderer} />
									{this.generateDisplayFactorColumns(displayFactors, this.animatedRenderer)}
									<Column dataKey="spacer" width={10} />
									{this.generateMycotoxinColumns}
									{user.isCertificate && <Column label="Insp." dataKey="inspector" width={60} />}
									<Column label="Notes" dataKey="fakeKey" width={150} maxWidth={300} flexGrow={2} cellRenderer={this.notesRenderer} />
								</Table>
							)}
						</AutoSizer>
					</TabPane>
					<TabPane tab="Offloaded" key="3">
						<AutoSizer>
							{({ height, width }) => (
								<Table
									width={width}
									height={height}
									headerHeight={20}
									rowHeight={this.rowHeightGetter(offList)}
									rowCount={offList.length}
									rowGetter={({ index }) => offList[index]}
									rowClassName={this.rowClassNameGetter(offList)}>
									<Column label="#" dataKey="orderId" width={50} />
									<Column
										label="ID"
										dataKey="carNumber"
										width={60}
										maxWidth={100}
										flexGrow={1}
										cellRenderer={({ cellData }) => (Array.isArray(cellData) ? cellData.map(d => <div>{d}</div>) : cellData)}
									/>
									<Column
										label="N.W."
										dataKey="netWeight"
										width={60}
										maxWidth={100}
										flexGrow={1}
										cellRenderer={({ cellData }) =>
											Array.isArray(cellData) ? cellData.map(d => <div>{d ? parseInt(d, 10).toLocaleString() : ''}</div>) : cellData
										}
									/>
									{currentInspection.lotType === 'Container' && (
										<Column label="Booking #" dataKey="bookingNumber" width={50} maxWidth={100} flexGrow={1} />
									)}
									{currentInspection.lotType === 'Container' && (
										<Column label="Seal #" dataKey="sealNumber" width={50} maxWidth={100} flexGrow={1} />
									)}
									<Column label="Grade" dataKey="grade" width={100} flexGrow={2} cellRenderer={this.gradeRenderer} />

									{this.generateDisplayFactorColumns(displayFactors)}

									<Column dataKey="spacer" width={10} />

									{this.generateMycotoxinColumns}

									{user.isCertificate && <Column label="Insp." dataKey="inspector" width={60} />}
									<Column label="Notes" dataKey="fakeKey" width={150} maxWidth={300} flexGrow={2} cellRenderer={this.notesRenderer} />
								</Table>
							)}
						</AutoSizer>
					</TabPane>
				</Tabs>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: getUserProfile(state),
		spreadsheetDisplay: getSpreadsheet(state),
		isFetching: state.currentInspection.carriers.isFetching || state.currentInspection.rex.isFetching
	};
};

export default connect(mapStateToProps, {})(Spreadsheet);
