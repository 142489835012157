export const DisplayNames = {
	// CARRIER DATA
	orderId: 'ID',
	carNumber: 'Car Number',
	inspector: 'Inspector',
	grade: 'Grade',
	// FACTORS
	testWeight: 'Test Weight',
	moisture: 'Moisture',
	damage: 'Damage',
	heat: 'Heat',
	bcfm: 'BCFM',
	fm: 'F.M.',
	fmCoarse: 'Coarse F.M.',
	fmFine: 'Fine F.M.',
	sbk: 'SBK',
	splits: 'Splits',
	sboc: 'SBOC',
	shb: 'SHB',
	wocl: 'WOCL',
	defects: 'Defects',
	cc: 'CC',
	dockage: 'Dockage',
	ocol: 'OCOL',
	// SAMPLE
	cstb: 'Castor Beans',
	crot: 'Crotolaria Seeds',
	htg: 'Heating',
	ston: 'Stones',
	stonCount: 'Stones',
	stonWeight: 'Stones',
	glass: 'Glass',
	fsub: 'Unknown Foreign Substance',
	odor: 'Odor',
	cbur: 'Cockleburs',
	anfl: 'Animal Filth',
	idk: 'Insect Damage',
	fsubTotal: 'Total Substances',
	// SPECIAL
	flint: 'Flint',
	flintDent: 'Flint and Dent',
	waxy: 'Waxy',
	infested: 'Infested',
	lw: 'Live Weevils',
	oli: 'Other Live Insects',
	garlicky: 'Garlicky',
	ggb: 'Green Bulblets',
	dgb: 'Dry Bulblets',
	pms: 'Purple Mottled or Stained',
	tret: 'Treated',
	erg: 'Ergoty',
	smut: 'Smutty',
	lsm: 'Smutty',
	sbal: 'Smut Balls',
	lsmOdor: 'Smutty Odor',
	// OTHER
	stinkBug: 'Stink Bug Damage',
	// INFESTED BUGS
	infested_b1: 'Angoumois Grain Moth',
	infested_b2: 'Cadelle',
	infested_b3: 'Confused Flour Beetle',
	infested_b4: 'Flat Grain Beetle',
	infested_b5: 'Granary Weevil',
	infested_b6: 'Indian Meal Moth',
	infested_b7: 'Lesser Grain Borer',
	infested_b8: 'Maize Weevil',
	infested_b9: 'Red Saw-toothed beetle',
	infested_b10: 'Rice Weevil'
};

export default DisplayNames;
