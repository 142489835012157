import { CHANGE_CARRIER_VIEW, SET_CURRENT_INSPECTION, SET_CURRENT_CARRIER, CONFIRM_CARRIER, CHANGE_INSPECTION_VIEW } from '../../Actions/types';

const carrierWindow = (state = 'carrier', action) => {
	switch (action.type) {
		case CONFIRM_CARRIER:
		case SET_CURRENT_INSPECTION:
		case SET_CURRENT_CARRIER:
			return 'carrier';
		case CHANGE_CARRIER_VIEW:
			return action.view;
		case CHANGE_INSPECTION_VIEW:
			if (state === 'calcFactor' || state === 'multiFactor' || state === 'multiFactor' || state === 'selectFactor') return 'gradeFactors';
			else return state;
		default:
			return state;
	}
};

export default carrierWindow;
