import React from 'react';
import { useComponentDidMount } from 'lib/helpers';

const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Backspace', 'Tab'];

const DecimalInput = ({ autoFocus, onChange, precision, value: defaultValue, ...props }) => {
	const [value, setValue] = React.useState(defaultValue ?? '0.00');
	const ref = React.createRef();

	useComponentDidMount(() => {
		if (autoFocus) {
			ref.current.focus();
			ref.current.selectionStart = ref.current.selectionEnd = ref.current?.value?.length;
		}
	});

	React.useEffect(() => {
		if (defaultValue.toString() !== value) {
			setValue(defaultValue);
		}
	}, [defaultValue]);

	const handleChange = e => {
		const val = (e.target.value.replace('.', '') * Math.pow(10, -precision)).toFixed(precision);
		if (onChange) onChange(val);
		setValue(val);
	};

	const handleKeyDown = e => {
		if (!keys.includes(e.key)) e.preventDefault();
	};

	return <input ref={ref} className="input" type="text" value={value} onChange={handleChange} onKeyDown={handleKeyDown} {...props} />;
};

export default DecimalInput;
