import React from 'react';
import { Button, Dialog, DialogHeader, DialogContent, DialogFooter } from 'Controls';

const ConfirmDialog = ({ title, body, onAccept, onCancel, buttonColor, buttonText, ...props }) => (
	<Dialog onClose={onCancel} {...props}>
		<DialogHeader title={title} onClose={onCancel} />
		<DialogContent>
			<p>{body}</p>
		</DialogContent>
		<DialogFooter>
			<Button
				autoFocus
				color={buttonColor || 'primary'}
				onClick={() => {
					onAccept();
					onCancel();
				}}>
				{buttonText ? buttonText : 'Accept'}
			</Button>
			<Button onClick={onCancel}>Cancel</Button>
		</DialogFooter>
	</Dialog>
);

export default ConfirmDialog;
