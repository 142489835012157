import React from 'react';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import styles from './Search.module.css';

const Search = ({ onChange, className, ...props }) => {
	const handleClick = React.useCallback(() => {
		onChange && onChange({ target: { value: '' } });
	}, [onChange]);

	return (
		<div className={clsx(styles.Root, className)}>
			<input className={clsx('input', styles.Input)} type="text" onChange={onChange} {...props} />
			<span className={styles.ClearIcon} onClick={handleClick} onMouseDown={e => e.preventDefault()}>
				<CloseIcon />
			</span>
		</div>
	);
};

export default Search;
