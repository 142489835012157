import React from 'react';
import { connect } from 'react-redux';
import { changeInspectionView, setCurrentFactor } from 'Actions';
import FactorDisplayNames from '../models/factors/names';
import { getFactors, getFactorResults, getInspection } from 'Selectors';
import { AutoSizer, List } from 'react-virtualized';
import { CarrierHeader, CalcFactor, CountFactor, MultiFactor, SelectFactor } from 'Components';
import { Button } from 'Controls';
import styles from './MarkSample.module.css';

class MarkSample extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentFactor: null
		};

		this.factors = props.commodityFactors.calculation.sample
			.filter(i => props.commodityFactors[i].type !== 'composite')
			.reduce((acc, i) => ({ ...acc, [i]: props.commodityFactors[i] }), {});

		this.specialFactors = props.commodityFactors.calculation.markSpecial
			.filter(i => props.commodityFactors[i].type !== 'composite')
			.reduce((acc, i) => ({ ...acc, [i]: props.commodityFactors[i] }), {});

		if (this.props.commodityFactors.calculation.other) {
			this.otherFactors = props.commodityFactors.calculation.other
				.filter(i => props.commodityFactors[i].type !== 'composite')
				.reduce((acc, i) => ({ ...acc, [i]: props.commodityFactors[i] }), {});
		}
	}

	rowRenderer = (factors, results) => ({ key, index, style }) => {
		const prop = Object.keys(factors)[index];
		let isSelected = results[prop] !== undefined;
		if (factors[prop].type === 'multi') {
			isSelected = factors[prop].factors.reduce((acc, f) => {
				if (results[f] !== undefined) {
					return true;
				}
				return acc;
			}, false);
		}

		return (
			<Button
				key={key}
				style={style}
				fullWidth
				size="large"
				variant={isSelected && 'outlined'}
				color={isSelected && 'info'}
				onClick={() => {
					this.props.setCurrentFactor(prop, factors[prop]);
					this.setState({ currentFactor: factors[prop] });
				}}>
				{FactorDisplayNames[prop]}
			</Button>
		);
	};

	render() {
		const { changeInspectionView } = this.props;

		if (!this.state.currentFactor) {
			return (
				<div className={styles.Container}>
					<CarrierHeader />
					<div className={styles.Columns}>
						<div className={styles.Column}>
							<Button fullWidth size="large" color="info">
								Sample Grade
							</Button>
							<div className={styles.List}>
								<AutoSizer>
									{({ height, width }) => (
										<List
											value={Object.keys(this.factors)}
											width={width}
											height={height}
											rowCount={Object.keys(this.factors).length}
											rowHeight={100}
											rowRenderer={this.rowRenderer(this.factors, this.props.sampleFactorResults)}
										/>
									)}
								</AutoSizer>
							</div>
						</div>
						<div className={styles.Column}>
							<Button fullWidth size="large" color="info">
								Special Grade
							</Button>
							<div className={styles.List}>
								<AutoSizer>
									{({ height, width }) => (
										<List
											value={Object.keys(this.specialFactors)}
											width={width}
											height={height}
											rowCount={Object.keys(this.specialFactors).length}
											rowHeight={100}
											rowRenderer={this.rowRenderer(this.specialFactors, this.props.specialFactorResults)}
										/>
									)}
								</AutoSizer>
							</div>
						</div>
						<div className={styles.Column}>
							{this.otherFactors && (
								<>
									<Button fullWidth size="large" color="info">
										Other
									</Button>
									<div className={styles.List}>
										<AutoSizer>
											{({ height, width }) => (
												<List
													value={Object.keys(this.otherFactors)}
													width={width}
													height={height}
													rowCount={Object.keys(this.otherFactors).length}
													rowHeight={100}
													rowRenderer={this.rowRenderer(this.otherFactors, this.props.otherFactorResults)}
												/>
											)}
										</AutoSizer>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			);
		} else {
			let FactorView = React.Fragment;

			if (this.state.currentFactor.type === 'percent') {
				FactorView = CalcFactor;
			} else if (this.state.currentFactor.type === 'count') {
				FactorView = CountFactor;
			} else if (this.state.currentFactor.type === 'select') {
				FactorView = SelectFactor;
			} else if (this.state.currentFactor.type === 'multi') {
				FactorView = MultiFactor;
			}

			return (
				<div className={styles.Container}>
					<CarrierHeader />
					<FactorView onAccept={() => changeInspectionView('inspection')} onCancel={() => this.setState({ currentFactor: null })} />
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	const sampleFactorResults = getFactorResults(state);
	return {
		currentInspection: getInspection(state),
		sampleFactorResults: sampleFactorResults.sample,
		specialFactorResults: sampleFactorResults.special,
		otherFactorResults: sampleFactorResults.other,
		commodityFactors: getFactors(state)
	};
};

export default connect(mapStateToProps, {
	setCurrentFactor,
	changeInspectionView
})(MarkSample);
