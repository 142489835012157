import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeInspectionView } from 'Actions';
import { getFooter } from 'Selectors';
import { Button } from 'Controls';
import styles from './CarrierFooter.module.css';

const CarrierFooter = () => {
	const dispatch = useDispatch();
	const view = useSelector(state => state.view.inspectionWindow);
	const tabs = useSelector(getFooter);

	return (
		<div className={styles.Footer} aria-label="footer navigation">
			{tabs.map(tab => (
				<Button
					size="large"
					color={tab.complete ? 'primary' : 'info'}
					variant={tab.view !== view && 'outlined'}
					key={tab.name}
					loading={tab.loading}
					disabled={!tab.available}
					className={styles.Button}
					onClick={() => dispatch(changeInspectionView(tab.view))}>
					{tab.name}
				</Button>
			))}
		</div>
	);
};

export default CarrierFooter;
