import { createSelector } from 'reselect';

const inspectionSelector = state => state.inspections.byId[state.currentInspection.id];
const carriersSelector = state => state.currentInspection.carriers;
const currentCarrierSelector = state => state.currentInspection.carrierId;

const getFooterTabs = (inspection, carriers, currentCarrierId) => {
	const mainComplete =
		inspection &&
		inspection.commodityType &&
		inspection.commodityGroup &&
		inspection.lotType &&
		inspection.sampleType &&
		inspection.sampleGrouping &&
		inspection.inspectionType;

	const billingComplete =
		inspection &&
		inspection.arrivalTime &&
		inspection.locationValue &&
		inspection.inspector &&
		inspection.sampler && 
		inspection.sampleMethod &&
		(inspection.sampleMethod !== "Probe" || inspection.sampleProbeDepth);

	return [
		{
			name: 'Main',
			view: 'main',
			complete: mainComplete,
			available: true
		},
		{
			name: 'Billing',
			view: 'billing',
			complete: billingComplete,
			available: true
		},
		{
			name: 'Inspection',
			view: 'inspection',
			complete: false,
			available: !carriers.isFetching && billingComplete && mainComplete,
			loading: carriers.isFetching
		},
		{
			name: 'Mark Sample',
			view: 'markSample',
			complete: false,
			available: currentCarrierId !== null && !carriers.isFetching && billingComplete && mainComplete,
			loading: carriers.isFetching
		},
		{
			name: 'Spreadsheet',
			view: 'spreadsheet',
			complete: false,
			available: !carriers.isFetching && mainComplete,
			loading: carriers.isFetching
		}
	];
};

export default createSelector([inspectionSelector, carriersSelector, currentCarrierSelector], getFooterTabs);
