import { CHANGE_INSPECTION_VIEW, SET_CURRENT_INSPECTION } from "../../Actions/types";

const inspectionWindow = (state = "billing", action) => {
    switch (action.type) {
        case SET_CURRENT_INSPECTION:
            // if(action.id !== null) {
                return "billing";
            // } else {
                // return state;
            // }
        case CHANGE_INSPECTION_VIEW:
            return action.view;
        default:
            return state;
    }
};

export default inspectionWindow;
