import React from 'react';
import { connect } from 'react-redux';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ChatIcon from '@mui/icons-material/Chat';
import { getSpreadsheet } from 'Selectors';
import {
	changeCarrierView,
	changeInspectionView,
	offloadCompositeCarrier,
	newRexCarrierInspection,
	setCurrentCarrier,
	updateCarrierInspection,
	updateOffCarrier,
	updateRexCarrier
} from 'Actions';
import { Abbreviations } from 'models/factors/abbreviations';
import Mycotoxins from 'models/mycotoxins';
import { Popover, Tabs } from 'antd';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { FactorsList } from 'Components';
import { MycotoxinDialog, NotesDialog } from 'Components/Dialogs';
import { Button, Loading, Modal } from 'Controls';
import styles from './CarrierGrid.module.css';

const TabPane = Tabs.TabPane;

class Spreadsheet extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			myco: null,
			confirm: null
		};
	}

	componentDidUpdate() {
		if (this._table) this._table.recomputeRowHeights();
	}

	notesRenderer = (title, updater) => ({ rowData }) => {
		const data = Object.assign({}, rowData.sampleValues, rowData.specialValues, rowData.otherValues);
		const type = [rowData.offId && 'RELOAD', rowData.rexId && 'REX'].filter(i => i).join(', ');
		const button = (
			<Button
				variant="icon"
				color={rowData.notes && 'primary'}
				onClick={() => {
					this.setState({
						notes: {
							notes: rowData.notes,
							title: `Notes: ${title} # ${rowData.orderId}`,
							onAccept: data => updater(rowData.id, { notes: data })
						}
					});
				}}>
				{rowData.notes ? <ChatIcon /> : <ChatBubbleIcon />}
			</Button>
		);

		return (
			<div className={styles.Notes}>
				<div>
					{type && <div>{type}</div>}
					<FactorsList data={data} />
				</div>
				{rowData.notes ? (
					<Popover
						title="Notes"
						placement="leftTop"
						trigger="hover"
						content={rowData.notes.split('\n').map((n, i) => (
							<div key={i}>{n}</div>
						))}>
						{button}
					</Popover>
				) : (
					button
				)}
			</div>
		);
	};

	// rowHeightGetter = data => ({ index }) => {
	// 	let count = 0;
	// 	const samples = data[index].sampleValues;
	// 	const specials = data[index].specialValues;
	// 	const others = data[index].otherValues;

	// 	if (samples) count += Object.keys(samples).length;
	// 	if (specials) count += Object.keys(specials).length;
	// 	if (others) count += Object.keys(others).length;
	// 	if (data[index].rexId || data[index].offId) count++;

	// 	return Math.max(4 + count * 24, 52);
	// };

	rowHeightGetter = data => ({ index }) => {
		let count = 0,
			count2 = 0,
			count3 = 0;
		const samples = data[index].sampleValues;
		const specials = data[index].specialValues;
		const others = data[index].otherValues;

		if (Array.isArray(data[index].netWeight)) count2 = data[index].netWeight.length;
		if (Array.isArray(data[index].carNumber)) count3 = data[index].netWeight.length;

		if (samples) count += Object.keys(samples).length;
		if (specials) count += Object.keys(specials).length;
		if (others) count += Object.keys(others).length;
		if (data[index].rexId || data[index].offId) count++;

		count = Math.max(count, count2, count3);
		return Math.max(4 + count * 24, 52);
	};

	gradeRenderer = ({ cellData, rowData }) => {
		const content = (
			<div>
				<div>{cellData}</div>
				<div>{rowData['special']}</div>
			</div>
		);

		return (
			<Popover content={content} trigger="hover">
				{content}
			</Popover>
		);
	};

	weightRenderer = ({ cellData }) => (cellData ? parseInt(cellData, 10).toLocaleString() : '');

	generateMycotoxinColumns = updater =>
		Object.keys(Mycotoxins).map(key => {
			if (this.props.spreadsheetDisplay.currentInspection[key] !== true) return null;
			return (
				<Column
					key={key}
					label={key.toUpperCase()}
					dataKey={key}
					width={60}
					maxWidth={70}
					flexGrow={1}
					headerClassName={styles.AlignCenter}
					cellDataGetter={({ rowData, dataKey }) => rowData[dataKey] || { value: null, inspector: null }}
					cellRenderer={({ cellData, rowData, dataKey }) => {
						if (rowData.isAverage) return;
						return (
							<Button
								fullWidth
								color={rowData.mycoDiff && rowData.mycoDiff[dataKey] && 'warning'}
								onClick={() =>
									this.setState({
										myco: {
											value: cellData.value,
											inspector: cellData.inspector,
											units: cellData.units || Mycotoxins[dataKey].units,
											type: dataKey,
											orderId: rowData.orderId,
											onAccept: data => updater(rowData.id, { [dataKey]: { ...data, timestamp: Date.now() } })
										}
									})
								}>
								{cellData.value ? cellData.value + ' ' + cellData.units : '?'}
							</Button>
						);
					}}
				/>
			);
		});

	generateDisplayFactorColumns = (factors, renderer) =>
		factors.map(key => (
			<Column
				key={key}
				headerClassName={styles.AlignRight}
				className={styles.AlignRight}
				label={Abbreviations[key]}
				dataKey={key}
				width={60}
				maxWidth={80}
				flexGrow={1}
				cellRenderer={renderer}
			/>
		));

	factorsRenderer = ({ cellData, rowData, dataKey }) => {
		let content = cellData;
		if (rowData.origValues && rowData.origValues[dataKey]) {
			content = (
				<>
					<div>Part: {rowData.origValues[dataKey][0]}</div>
					<div>Whole: {rowData.origValues[dataKey][1]}</div>
				</>
			);
		}
		return (
			<Popover content={content} trigger="hover">
				<span className={rowData.diff && rowData.diff[dataKey] ? styles.Tag : ''}>{cellData}</span>
			</Popover>
		);
	};

	rowClassNameGetter = carriers => ({ index }) => {
		return index < 0 ? styles.HeaderRow : index % 2 === 0 ? styles.EvenRow : styles.OddRow;
	};

	render() {
		const {
			spreadsheetDisplay,
			setCurrentCarrier,
			changeInspectionView,
			changeCarrierView,
			updateCarrierInspection,
			updateOffCarrier,
			updateRexCarrier,
			isFetching,
			newRexCarrierInspection,
			offloadCompositeCarrier
		} = this.props;

		const { displayFactors, currentInspection, carrierId, carriers: carrierList, rex: rexList, off: offList } = spreadsheetDisplay;

		if (isFetching) {
			return <Loading message="Fetching Carriers" />;
		}

		return (
			<div className={styles.Container}>
				<Tabs className={styles.Container} animated={{ tabPane: false }}>
					<TabPane tab="Carriers" key="1">
						<div className={styles.Container}>
							<AutoSizer>
								{({ height, width }) => (
									<Table
										ref={ref => (this._table = ref)}
										width={width}
										height={height}
										headerHeight={20}
										rowHeight={this.rowHeightGetter(carrierList)}
										rowCount={carrierList.length}
										rowGetter={({ index }) => carrierList[index]}
										scrollToIndex={carrierList.findIndex(i => i.id === carrierId)}
										rowClassName={this.rowClassNameGetter(carrierList)}>
										<Column
											label="#"
											dataKey="orderId"
											width={50}
											headerClassName={styles.AlignCenter}
											className={styles.AlignCenter}
											cellRenderer={({ cellData, rowData }) => (
												<Button
													color={carrierId === rowData['id'] && 'primary'}
													onClick={() => {
														setCurrentCarrier(rowData['id']);
														changeInspectionView('inspection');
													}}>
													{cellData}
												</Button>
											)}
										/>
										<Column
											label="ID"
											dataKey="carNumber"
											width={60}
											maxWidth={100}
											flexGrow={1}
											cellRenderer={({ cellData }) => (Array.isArray(cellData) ? cellData.map(d => <div>{d}</div>) : cellData)}
										/>
										<Column
											label="N.W."
											dataKey="netWeight"
											width={60}
											maxWidth={100}
											flexGrow={1}
											cellRenderer={({ cellData }) =>
												Array.isArray(cellData) ? cellData.map(d => <div>{d ? parseInt(d, 10).toLocaleString() : ''}</div>) : cellData
											}
										/>
										{currentInspection.lotType === 'Container' && (
											<Column label="Booking #" dataKey="bookingNumber" width={50} maxWidth={100} flexGrow={1} />
										)}
										{currentInspection.lotType === 'Container' && (
											<Column label="Seal #" dataKey="sealNumber" width={50} maxWidth={100} flexGrow={1} />
										)}
										<Column label="Grade" dataKey="grade" width={100} flexGrow={2} cellRenderer={this.gradeRenderer} />
										{this.generateDisplayFactorColumns(displayFactors, this.factorsRenderer)}
										<Column dataKey="spacer" width={10} />
										{this.generateMycotoxinColumns(updateCarrierInspection)}
										<Column
											label="Notes"
											dataKey="fakeKey"
											width={150}
											maxWidth={300}
											flexGrow={2}
											cellRenderer={this.notesRenderer('Composite', updateCarrierInspection)}
										/>
										<Column
											label="Actions"
											dataKey="id"
											width={100}
											cellRenderer={({ cellData, rowData }) => (
												<div className={styles.Actions}>
													<Button
														disabled={!rowData.isInfoComplete}
														onClick={() => {
															offloadCompositeCarrier(cellData);
															changeCarrierView('carrier');
															changeInspectionView('inspection');
														}}>
														O
													</Button>
													{!rowData.rexId && (
														<Button
															disabled={!rowData.isInfoComplete}
															onClick={() => {
																newRexCarrierInspection(cellData);
																setCurrentCarrier(cellData);
																changeCarrierView('gradeFactors');
																changeInspectionView('inspection');
															}}>
															R
														</Button>
													)}
												</div>
											)}
										/>
									</Table>
								)}
							</AutoSizer>
						</div>
						<div className={styles.Averages}>
							<AutoSizer disableHeight>
								{({ height, width }) => (
									<Table
										width={width}
										height={40}
										headerHeight={20}
										rowHeight={40}
										disableHeader
										rowCount={1}
										rowGetter={({ index }) => spreadsheetDisplay.averages}
										rowClassName={styles.AveragesRow}>
										<Column label="#" dataKey="orderId" width={60} maxWidth={100} flexGrow={1} cellRenderer={() => {}} />
										<Column label="ID" dataKey="carNumber" width={60} maxWidth={100} flexGrow={1} cellRenderer={() => {}} />
										<Column label="N.W." dataKey="netWeight" width={60} maxWidth={100} flexGrow={1} cellRenderer={() => {}} />
										{currentInspection.lotType === 'Container' && (
											<Column label="Booking #" dataKey="bookingNumber" width={50} maxWidth={100} flexGrow={1} cellRenderer={() => {}} />
										)}
										{currentInspection.lotType === 'Container' && (
											<Column label="Seal #" dataKey="sealNumber" width={50} maxWidth={100} flexGrow={1} cellRenderer={() => {}} />
										)}
										<Column label="Grade" dataKey="grade" width={100} flexGrow={2} cellRenderer={() => {}} />
										{this.generateDisplayFactorColumns(displayFactors)}
										<Column dataKey="spacer" width={10} />
										{this.generateMycotoxinColumns()}
										<Column label="Notes" dataKey="fakeKey" width={150} maxWidth={300} flexGrow={2} cellRenderer={() => {}} />
										<Column label="Actions" dataKey="id" width={100} cellRenderer={() => {}} />
									</Table>
								)}
							</AutoSizer>
						</div>
					</TabPane>
					<TabPane tab="Reinspected" key="2">
						<AutoSizer>
							{({ height, width }) => (
								<Table
									width={width}
									height={height}
									headerHeight={20}
									rowHeight={this.rowHeightGetter(rexList)}
									rowCount={rexList.length}
									rowGetter={({ index }) => rexList[index]}
									rowClassName={this.rowClassNameGetter(rexList)}>
									<Column label="#" dataKey="orderId" width={50} />
									<Column
										label="ID"
										dataKey="carNumber"
										width={60}
										maxWidth={100}
										flexGrow={1}
										cellRenderer={({ cellData }) => (Array.isArray(cellData) ? cellData.map(d => <div>{d}</div>) : cellData)}
									/>
									<Column
										label="N.W."
										dataKey="netWeight"
										width={60}
										maxWidth={100}
										flexGrow={1}
										cellRenderer={({ cellData }) =>
											Array.isArray(cellData)
												? cellData.map(d => <div>{d ? parseInt(d, 10).toLocaleString() : ''}</div>)
												: cellData
												? parseInt(cellData, 10).toLocaleString()
												: ''
										}
									/>
									{currentInspection.lotType === 'Container' && (
										<Column label="Booking #" dataKey="bookingNumber" width={50} maxWidth={100} flexGrow={1} />
									)}
									{currentInspection.lotType === 'Container' && (
										<Column label="Seal #" dataKey="sealNumber" width={50} maxWidth={100} flexGrow={1} />
									)}
									<Column label="Grade" dataKey="grade" width={100} flexGrow={2} cellRenderer={this.gradeRenderer} />
									{this.generateDisplayFactorColumns(displayFactors, this.factorsRenderer)}
									<Column dataKey="spacer" width={10} />
									{this.generateMycotoxinColumns(updateRexCarrier)}
									<Column
										label="Notes"
										dataKey="fakeKey"
										width={150}
										maxWidth={300}
										flexGrow={2}
										cellRenderer={this.notesRenderer('Reinspected', updateRexCarrier)}
									/>
								</Table>
							)}
						</AutoSizer>
					</TabPane>
					<TabPane tab="Offloaded" key="3">
						<AutoSizer>
							{({ height, width }) => (
								<Table
									width={width}
									height={height}
									headerHeight={20}
									rowHeight={this.rowHeightGetter(offList)}
									rowCount={offList.length}
									rowGetter={({ index }) => offList[index]}
									rowClassName={this.rowClassNameGetter(offList)}>
									<Column label="#" dataKey="orderId" width={50} />
									<Column
										label="ID"
										dataKey="carNumber"
										width={60}
										maxWidth={100}
										flexGrow={1}
										cellRenderer={({ cellData }) => (Array.isArray(cellData) ? cellData.map(d => <div>{d}</div>) : cellData)}
									/>
									<Column
										label="N.W."
										dataKey="netWeight"
										width={60}
										maxWidth={100}
										flexGrow={1}
										cellRenderer={({ cellData }) =>
											Array.isArray(cellData)
												? cellData.map(d => <div>{d ? parseInt(d, 10).toLocaleString() : ''}</div>)
												: cellData
												? parseInt(cellData, 10).toLocaleString()
												: ''
										}
									/>
									{currentInspection.lotType === 'Container' && (
										<Column label="Booking #" dataKey="bookingNumber" width={50} maxWidth={100} flexGrow={1} />
									)}
									{currentInspection.lotType === 'Container' && (
										<Column label="Seal #" dataKey="sealNumber" width={50} maxWidth={100} flexGrow={1} />
									)}
									<Column label="Grade" dataKey="grade" width={100} flexGrow={2} cellRenderer={this.gradeRenderer} />
									{this.generateDisplayFactorColumns(displayFactors, this.factorsRenderer)}
									<Column dataKey="spacer" width={10} />
									{this.generateMycotoxinColumns(updateOffCarrier)}
									<Column
										label="Notes"
										dataKey="fakeKey"
										width={150}
										maxWidth={300}
										flexGrow={2}
										cellRenderer={this.notesRenderer('Offloaded', updateOffCarrier)}
									/>
								</Table>
							)}
						</AutoSizer>
					</TabPane>
				</Tabs>
				<Modal show={this.state.myco}>
					<MycotoxinDialog
						inspector={this.state.myco?.inspector}
						value={this.state.myco?.value}
						id={this.state.myco?.orderId}
						type={this.state.myco?.type}
						units={this.state.myco?.units}
						onCancel={() => this.setState({ myco: null })}
						onAccept={data => this.state.myco.onAccept(data)}
					/>
				</Modal>
				<Modal show={this.state.notes}>
					<NotesDialog {...this.state.notes} onCancel={() => this.setState({ notes: null })} onAccept={data => this.state.notes.onAccept(data)} />
				</Modal>
			</div>
		);
	}
}

export default connect(
	state => ({
		spreadsheetDisplay: getSpreadsheet(state),
		isFetching: state.currentInspection.carriers.isFetching || state.currentInspection.rex.isFetching
	}),
	{
		setCurrentCarrier,
		updateCarrierInspection,
		updateOffCarrier,
		updateRexCarrier,
		changeInspectionView,
		changeCarrierView,
		newRexCarrierInspection,
		offloadCompositeCarrier
	}
)(Spreadsheet);
