import omit from 'lodash/omit';
import {
	AUTH_DESTROYED,
	ADD_USER,
	USER_ADDED,
	REQUEST_USERS,
	REQUEST_USERS_FINISHED,
	RECEIVE_USER_ADDED,
	RECEIVE_USER_REMOVED,
	RECEIVE_USER_MODIFIED
} from '../Actions/types';

const users = (state = { allIds: [], byId: {}, isFetching: false, isAdding: false }, action) => {
	switch (action.type) {
		case ADD_USER:
			return { ...state, isAdding: true };
		case USER_ADDED:
			return { ...state, isAdding: false };
		case AUTH_DESTROYED:
			return { allIds: [], byId: {}, isFetching: false };
		case REQUEST_USERS:
			return {
				...state,
				isFetching: true
			};
		case RECEIVE_USER_ADDED:
			return {
				...state,
				allIds: [...state.allIds, action.id],
				byId: { ...state.byId, [action.id]: action.added }
			};
		case RECEIVE_USER_REMOVED:
			return {
				...state,
				allIds: state.allIds.filter(id => id !== action.id),
				byId: omit(state.byId, action.id)
			};
		case RECEIVE_USER_MODIFIED:
			return {
				...state,
				byId: { ...state.byId, [action.id]: action.modified }
			};
		case REQUEST_USERS_FINISHED:
			return {
				...state,
				isFetching: false
			};
		default:
			return state;
	}
};

export default users;
