import { createSelector } from 'reselect';

const inspectionsSelector = state => state.inspections;
const currentInspectionSelector = state => state.currentInspection;

const getCurrentInspection = (inspections, currentInspection) => {
	const inspection = inspections.byId[currentInspection.id];
	const currentCarrier = currentInspection.carriers.byId[currentInspection.carrierId];
	return {
		...currentInspection,
		inspection,
		currentCarrier,
		carrierCount: currentInspection.carriers.allIds.length
	};
};

export default createSelector([inspectionsSelector, currentInspectionSelector], getCurrentInspection);
