import { createSelector } from 'reselect';
import { DateTimeFormatter } from '../lib/helpers';
import getLocations from './locations';

const getInspections = state => state.inspections;

export default createSelector([getLocations, getInspections], (locations, inspections) => {
	const listInspections = createRows(inspections, inspections.allIds, locations);
	return { inspections: listInspections, isFetching: inspections.isFetching };
});

const createRows = (inspections, ids, locations) =>
	ids.filter(i => inspections.byId[i].deleted === true).reduce((acc, id) => {
		const inspection = inspections.byId[id];
		const loc = locations.byId[inspection.locationValue];

		const insp = {
			id,
			location: loc ? `${loc.location} (${loc.company})` : '',
			deletedDate: DateTimeFormatter(inspection.deletedDate?.toDate()),
			departureTime: DateTimeFormatter(inspection.departureTime?.toDate()),
			commodity: `${inspection.commodityType} ${inspection.commodityGroup}`,
			inspector: inspection.inspector
		};

		return [...acc, insp];
	}, []);
