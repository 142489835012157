import React from 'react';
import { useSelector } from 'react-redux';
import { getUsers } from 'Selectors';
import { StringComparer } from '../../lib/helpers';
import { CONFIRM_REASSIGN_INSPECTION } from '../../lib/constants';
import { Button, Dialog, DialogHeader, DialogContent, DialogFooter } from 'Controls';

const ReassignInspectionDialog = ({ onAccept, onCancel, ...props }) => {
	const [inspector, setInspector] = React.useState('');
	const usersDisplay = useSelector(getUsers);

	return (
		<Dialog onClose={onCancel} {...props}>
			<DialogHeader title="Reassign Inspection" onClose={onCancel} />
			<DialogContent>
				<p>{CONFIRM_REASSIGN_INSPECTION}</p>
				<div className="field">
					<div className="control">
						<div className="select">
							<select value={inspector} onChange={e => setInspector(e.target.value)}>
								<option key="disabledOption" value={''} hidden disabled>
									Select Inspector...
								</option>
								{usersDisplay.users
									.filter(u => u.role === 'Inspector')
									.sort(StringComparer('displayName'))
									.map(u => (
										<option key={u.email} value={u.email}>{`${u.displayName} (${u.email})`}</option>
									))}
							</select>
						</div>
					</div>
				</div>
			</DialogContent>
			<DialogFooter>
				<Button
					color="warning"
					disabled={!inspector}
					onClick={() => {
						onAccept(inspector);
						onCancel();
					}}>
					Reassign
				</Button>
				<Button onClick={onCancel}>Cancel</Button>
			</DialogFooter>
		</Dialog>
	);
};

export default ReassignInspectionDialog;
