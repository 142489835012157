import { createSelector } from 'reselect';
import { Corn as CornFactors, Soybeans as SoybeanFactors, Wheat as WheatFactors } from '../models/gradeFactors';

const inspectionsSelector = state => state.inspections;
const currentInspectionSelector = state => state.currentInspection;

const getCommodityFactors = (inspections, currentInspection) => {

	if(!inspections.byId[currentInspection.id]) return {};

	const commodityGroup = inspections.byId[currentInspection.id].commodityGroup;

	if (commodityGroup === 'Corn') return CornFactors;
	else if (commodityGroup === 'Soybeans') return SoybeanFactors;
	else if (commodityGroup === 'Wheat') return WheatFactors;
};

export default createSelector([inspectionsSelector, currentInspectionSelector], getCommodityFactors);
