import React from 'react';
import clsx from 'clsx';
import { Dropdown, Menu } from 'antd';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const MenuButton = ({ children, className, disabled, ...props }) => (
	<Dropdown disabled={disabled} placement="bottomRight" trigger={['click']} overlay={<Menu>{children}</Menu>}>
		<button className={clsx('button button-circle is-text', className)} {...props}>
			<MoreHorizIcon />
		</button>
	</Dropdown>
);

export default MenuButton;
