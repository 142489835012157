import React from 'react';
import { Button, Input, Dialog, DialogHeader, DialogContent, DialogFooter } from 'Controls';

const MycotoxinDialog = ({ id, type, onAccept, onCancel, ...props }) => {
	const [value, setValue] = React.useState(props.value ?? '');
	const [inspector, setInspector] = React.useState(props.inspector ?? '');
	const [units, setUnits] = React.useState(props.units ?? '');

	return (
		<Dialog onClose={onCancel} {...props}>
			<DialogHeader title={`Carrier # ${id}: ${type?.toUpperCase()}`} onClose={onCancel} />
			<DialogContent>
				<Input label="Inspector" uppercase autoFocus value={inspector} onChange={val => setInspector(val)} />
				<label className="label">Value</label>
				<div className="field has-addons">
					<div className="control is-expanded">
						<Input value={value} onChange={val => setValue(val)} />
					</div>
					<div className="control">
						<span className="select">
							<select value={units} onChange={e => setUnits(e.target.value)}>
								<option value="ppm">ppm</option>
								<option value="ppb">ppb</option>
							</select>
						</span>
					</div>
				</div>
			</DialogContent>
			<DialogFooter>
				<Button
					color="primary"
					disabled={!value || !inspector || !units}
					onClick={() => {
						onAccept({ value, inspector, units });
						onCancel();
					}}>
					Accept
				</Button>
				<Button onClick={onCancel}>Cancel</Button>
			</DialogFooter>
		</Dialog>
	);
};

export default MycotoxinDialog;
