import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInspections, getUserProfile } from 'Selectors';
import { changeView, deleteInspection, setCurrentInspection, reassignInspection, continueInspection, sendClientEmails, archiveInspection } from 'Actions';
import { CONFIRM_DELETE_INSPECTION, CONFIRM_ARCHIVE_INSPECTION, CONFIRM_SEND_EMAILS, CONFIRM_RESEND_EMAILS } from 'lib/constants';
import { StringComparer, TimeComparer, filterCollection } from 'lib/helpers';
import { Menu, Table } from 'antd';
import { ConfirmDialog, ReassignInspectionDialog, DownloadDocumentDialog } from 'Components/Dialogs';
import { MenuButton, Search, Modal } from 'Controls';
import styles from './InspectionGrid.module.css';

const filterOptions = [
	{ text: 'Corn', value: 'Corn' },
	{ text: 'Soybeans', value: 'Soybeans' },
	{ text: 'Wheat', value: 'Wheat' }
];

const InspectionGrid = () => {
	const dispatch = useDispatch();

	const [documentDialog, setDocumentDialog] = React.useState(null);
	const [emailsDialog, setEmailsDialog] = React.useState(null);
	const [archiveDialog, setArchiveDialog] = React.useState(null);
	const [reassignDialog, setReassignDialog] = React.useState(null);
	const [deleteDialog, setDeleteDialog] = React.useState(null);
	const [sorter, setSorter] = React.useState({ columnKey: 'arrivalTime', order: 'descend' });
	const [filters, setFilters] = React.useState({});
	const [textFilter, setTextFilter] = React.useState('');

	const { isAdmin, isClient, isCertificate, isInsp, email } = useSelector(getUserProfile);
	const inspections = useSelector(getInspections);
	const isFetching = useSelector(state => state.inspections.isFetching);

	const handleChange = (pagination, filters, sorter) => {
		setSorter(sorter);
		setFilters(filters);
	};

	const columnSort = key => sorter.columnKey === key && sorter.order;

	return (
		<>
			<div className={styles.Topbar}>
				<Search placeholder="Search Inspections" value={textFilter} onChange={e => setTextFilter(e.target.value)} />
			</div>
			<div className={styles.Container}>
				<Table
					rowKey="id"
					size="small"
					showSorterTooltip={false}
					loading={isFetching}
					dataSource={filterCollection(textFilter, inspections)}
					scroll={{ scrollToFirstRowOnChange: true }}
					pagination={{ position: ['none', 'bottomCenter'], showSizeChanger: true, size: 'default', defaultPageSize: 20, showTotal: total => `${total} Inspections` }}
					rowClassName={record => (record.complete ? (record.clientEmailsSent ? styles.CompletedRow : styles.UnsentRow) : '')}
					onChange={handleChange}>
					<Table.Column title="Location" key="location" dataIndex="location" sorter={StringComparer('location')} sortOrder={columnSort('location')} />
					<Table.Column
						title="Commodity"
						key="commodity"
						dataIndex="commodity"
						sorter={StringComparer('commodity')}
						sortOrder={columnSort('commodity')}
						filters={filterOptions}
						filteredValue={filters.commodity}
						onFilter={(value, record) => record.commodity.endsWith(value)}
					/>
					<Table.Column
						title="Inspector"
						key="inspector"
						dataIndex="inspector"
						sorter={StringComparer('inspector')}
						sortOrder={columnSort('inspector')}
					/>
					<Table.Column title="Sampler" key="sampler" dataIndex="sampler" sorter={StringComparer('sampler')} sortOrder={columnSort('sampler')} />
					<Table.Column
						title="Arrival"
						key="arrivalTime"
						dataIndex="arrivalTime"
						sorter={TimeComparer('arrivalTime')}
						sortOrder={columnSort('arrivalTime')}
					/>
					<Table.Column
						title="Departure"
						key="departureTime"
						dataIndex="departureTime"
						sorter={TimeComparer('departureTime')}
						sortOrder={columnSort('departureTime')}
					/>
					<Table.Column title="Duration" key="duration" dataIndex="duration" sorter={StringComparer('duration')} sortOrder={columnSort('duration')} />
					<Table.Column
						key="actions"
						dataIndex="id"
						width={1}
						render={(cellData, rowData) => (
							<>
								{isClient && (
									<MenuButton>
										<Menu.Item
											onClick={() => {
												dispatch(setCurrentInspection(cellData));
												dispatch(changeView('spreadsheet'));
											}}>
											View
										</Menu.Item>
										{rowData.docsGenerated && (
											<Menu.Item onClick={() => setDocumentDialog({ id: cellData, type: 'pdf' })}>Document</Menu.Item>
										)}
									</MenuButton>
								)}
								{(isAdmin || isCertificate || isInsp) && (
									<MenuButton>
										{isInsp && <Menu.Item onClick={() => dispatch(continueInspection(cellData, email))}>Continue</Menu.Item>}
										{isCertificate && (
											<Menu.Item
												onClick={() => {
													dispatch(setCurrentInspection(cellData));
													dispatch(changeView('spreadsheet'));
												}}>
												View
											</Menu.Item>
										)}
										{isAdmin && rowData.docsGenerated && (
											<Menu.Item
												className={!rowData.clientEmailsSent ? 'has-text-success' : 'has-text-warning'}
												onClick={() => setEmailsDialog({ id: cellData, resend: rowData.clientEmailsSent })}>
												Send to Client
											</Menu.Item>
										)}
										{rowData.docsGenerated && (
											<Menu.SubMenu key="documents" title="Documents">
												<Menu.Item onClick={() => setDocumentDialog({ id: cellData, type: 'xlsx' })}>Spreadsheet</Menu.Item>
												<Menu.Item onClick={() => setDocumentDialog({ id: cellData, type: 'pdf' })}>PDF</Menu.Item>
											</Menu.SubMenu>
										)}
										{isAdmin && <Menu.Item onClick={() => setReassignDialog(cellData)}>Reassign</Menu.Item>}
										{isAdmin && (
											<Menu.Item
												onClick={() => {
													dispatch(setCurrentInspection(cellData));
													dispatch(changeView('inspection'));
												}}>
												Edit
											</Menu.Item>
										)}
										{isAdmin && rowData.complete && <Menu.Item onClick={() => setArchiveDialog(cellData)}>Archive</Menu.Item>}
										{isAdmin && (
											<Menu.Item className="has-text-danger" onClick={() => setDeleteDialog(cellData)}>
												Delete
											</Menu.Item>
										)}
									</MenuButton>
								)}
							</>
						)}
					/>
				</Table>
			</div>
			<Modal show={deleteDialog}>
				<ConfirmDialog
					title="Delete Inspection"
					body={CONFIRM_DELETE_INSPECTION}
					buttonText="Delete"
					buttonColor="danger"
					onCancel={() => setDeleteDialog(null)}
					onAccept={() => dispatch(deleteInspection(deleteDialog))}
				/>
			</Modal>
			<Modal show={archiveDialog}>
				<ConfirmDialog
					title="Archive Inspection"
					body={CONFIRM_ARCHIVE_INSPECTION}
					buttonText="Archive"
					buttonColor="warning"
					onCancel={() => setArchiveDialog(null)}
					onAccept={() => dispatch(archiveInspection(archiveDialog))}
				/>
			</Modal>
			<Modal show={emailsDialog}>
				<ConfirmDialog
					title={emailsDialog?.resend ? 'Resend Client Emails' : 'Send Client Emails'}
					body={emailsDialog?.resend ? CONFIRM_RESEND_EMAILS : CONFIRM_SEND_EMAILS}
					buttonText={emailsDialog?.resend ? 'Resend' : 'Send'}
					buttonColor={emailsDialog?.resend ? 'warning' : 'success'}
					onCancel={() => setEmailsDialog(null)}
					onAccept={() => dispatch(sendClientEmails(emailsDialog))}
				/>
			</Modal>
			<Modal show={reassignDialog}>
				<ReassignInspectionDialog onCancel={() => setReassignDialog(null)} onAccept={u => dispatch(reassignInspection(reassignDialog, u))} />
			</Modal>
			<Modal show={documentDialog}>
				<DownloadDocumentDialog {...documentDialog} onCancel={() => setDocumentDialog(null)} />
			</Modal>
		</>
	);
};

export default InspectionGrid;
