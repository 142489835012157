import React from 'react';
import { restoreInspection } from 'Actions';
import { INFO_RESTORE_INSP_SUCCESS, ERROR_RESTORE_INSP_INVALID_ID, ERROR_RESTORE_INSP_NOT_ARCHIVED } from '../../lib/constants';
import { Button, Dialog, DialogHeader, DialogContent, DialogFooter, Input } from 'Controls';

const RestoreInspectionDialog = ({ onCancel, ...props }) => {
	const [id, setId] = React.useState('');
	const [working, setWorking] = React.useState(false);
	const [result, setResult] = React.useState(undefined);

	return (
		<Dialog onClose={onCancel}>
			<DialogHeader title="Restore Inspection" disabled={working} onClose={onCancel} />
			<DialogContent>
				{result !== true && <Input label="Inspection ID" autoFocus disabled={working} value={id} onChange={val => setId(val)} />}
				{result === null && <p className="has-text-danger">{ERROR_RESTORE_INSP_INVALID_ID}</p>}
				{result === false && <p className="has-text-danger">{ERROR_RESTORE_INSP_NOT_ARCHIVED}</p>}
				{result === true && <p>{INFO_RESTORE_INSP_SUCCESS}</p>}
			</DialogContent>
			<DialogFooter>
				{result !== true && (
					<Button
						color="primary"
						loading={working}
						disabled={!id || working}
						onClick={() => {
							setWorking(true);
							restoreInspection(id)
								.then(result => setResult(result))
								.catch()
								.finally(() => setWorking(false));
						}}>
						Restore
					</Button>
				)}
				<Button onClick={onCancel} disabled={working}>
					{result === true ? 'Close' : 'Cancel'}
				</Button>
			</DialogFooter>
		</Dialog>
	);
};

export default RestoreInspectionDialog;
