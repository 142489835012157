import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, Menu } from 'antd';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import ListIcon from '@mui/icons-material/List';
import LogoutIcon from '@mui/icons-material/Logout';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { changeView, logout, newInspection, setCurrentInspection, setCurrentUserPassword, getClientSpreadsheetBlobURL } from 'Actions';
import { getUserProfile } from 'Selectors';
import { SetPasswordDialog, NotifyDialog } from 'Components/Dialogs';
import { Modal } from 'Controls';
import { logo as clientLogo } from 'config';
import styles from './NavBar.module.css';

class NavBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			setPassModal: null,
			getCSVModal: null,
			CSVData: null
		};
	}

	render() {
		const {
			initialized,
			userDisplay,
			logout,
			view,
			currentInspectionId,
			newInspection,
			changeView,
			setCurrentInspection,
			setCurrentUserPassword,
			getClientSpreadsheetBlobURL
		} = this.props;

		const { displayName, isSignedIn, isAdmin, isInsp, isClient, isCertificate } = userDisplay;

		const NavItems = () => (
			<div className="navbar-menu">
				<div className="navbar-start">
					{isAdmin && view.window === 'grid' && currentInspectionId === null && (
						<a className="navbar-item" href="/#" onClick={() => newInspection()}>
							<AddIcon />
						</a>
					)}
					{isClient && currentInspectionId !== null && (
						<>
							<a
								className="navbar-item"
								href="/#"
								onClick={() => {
									changeView('grid');
									setCurrentInspection(null);
								}}>
								<CheckIcon />
							</a>
							<div className="navbar-item has-dropdown is-hoverable">
								<a className="navbar-link" href="/#">
									Download
								</a>
								<div className="navbar-dropdown">
									<a className="navbar-item" href="/#" onClick={() => this.setState({ getCSVModal: getClientSpreadsheetBlobURL() })}>
										CSV
									</a>
								</div>
							</div>
						</>
					)}
					{isInsp && currentInspectionId !== null && view.window !== 'grid' && (
						<a title="Close" className="navbar-item" href="/#" onClick={() => setCurrentInspection(null)}>
							<CancelIcon />
						</a>
					)}
					{isInsp && view.window === 'grid' && (
						<a title="Close" className="navbar-item" href="/#" onClick={() => changeView('inspection')}>
							<CancelIcon />

						</a>
					)}
					{isAdmin && currentInspectionId !== null && view.window === 'inspection' && (
						<a
							className="navbar-item"
							href="/#"
							onClick={() => {
								changeView('grid');
								setCurrentInspection(null);
							}}>
							<CheckIcon />
						</a>
					)}
					{isAdmin && currentInspectionId !== null && view.window !== 'inspection' && (
						<a className="navbar-item" href="/#" onClick={() => changeView('inspection')}>
							<VisibilityIcon />
						</a>
					)}
					{!isInsp && currentInspectionId === null && view.window !== 'grid' && (
						<a className="navbar-item" href="/#" onClick={() => changeView('grid')}>
							<ListIcon />
						</a>
					)}
					{isCertificate && currentInspectionId !== null && view.window === 'spreadsheet' && (
						<a
							className="navbar-item"
							href="/#"
							onClick={() => {
								changeView('grid');
								setCurrentInspection(null);
							}}>
							<CheckIcon />
						</a>
					)}
				</div>
				<div className="navbar-end">
					{isAdmin && (
						<a className="navbar-item" href="/#" onClick={() => changeView('admin')}>
							Admin
						</a>
					)}
					<Dropdown placement="bottomRight" trigger={['click']} menu={{ items: [{ key: 1, label: "Change Password" }], onClick: () => this.setState({ setPassModal: true }) }}>
						<a className="navbar-item" onClick={e => e.preventDefault()}>
							{displayName}
						</a>
					</Dropdown>
					<a className="navbar-item" href="/#" onClick={logout}>
						<LogoutIcon />
					</a>
				</div>
			</div>
		);

		return (
			<nav className={`navbar is-info is-fixed-top ${styles.Container}`} aria-label="main navigation">
				<div className="navbar-brand">
					<div className="navbar-item">
						<img src={clientLogo.src} alt="Eastern Iowa Grain Inspection" />
					</div>
					{!isSignedIn && <div className="navbar-item has-text-weight-bold is-size-5">InspectorsLink</div>}
				</div>
				{isSignedIn && initialized && <NavItems />}
				<Modal show={isSignedIn && this.state.setPassModal}>
					<SetPasswordDialog
						onCancel={() => this.setState({ setPassModal: null })}
						onAccept={password => setCurrentUserPassword(password)}
					/>
				</Modal>
				<Modal show={isSignedIn && this.state.getCSVModal}>
					<NotifyDialog
						title="Download CSV"
						body={
							<span className={styles.CenterButton}>
								<a className="button is-large is-success" href={this.state.getCSVModal} download="data.csv">
									Download
								</a>
							</span>
						}
						onClose={() => this.setState({ getCSVModal: null })}
					/>
				</Modal>
			</nav>
		);
	}
}

const mapStateToProps = state => {
	const { view, initialized } = state;
	return {
		currentInspectionId: state.currentInspection.id,
		userDisplay: getUserProfile(state),
		view,
		initialized
	};
};
export default connect(mapStateToProps, {
	logout,
	newInspection,
	changeView,
	setCurrentInspection,
	getClientSpreadsheetBlobURL,
	setCurrentUserPassword
})(NavBar);
