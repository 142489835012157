import React from 'react';
import FlashChange from './FlashChange';
import styles from './Highlight.module.css';

const Highlight = ({ value }) => (
	<div className={styles.Root}>
		<FlashChange
			value={value}
			className="flash"
			flashClassName="flashing"
			flashDuration={1000}
			compare={(prevProps, nextProps) => nextProps.value !== prevProps.value ? 'active' : null}>
			{value}
		</FlashChange>
	</div>
)

export default Highlight;
