import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { getUserProfile } from 'Selectors';
import ArchiveGrid from './ArchiveGrid';
import DeletionsGrid from './DeletionsGrid';
import LocationsGrid from './LocationsGrid';
import Calendar from './Calendar';
import UsersGrid from './UsersGrid';
import styles from './Admin.module.css';

const AdminView = () => {
	const [view, setView] = React.useState('overview');
	const isAdmin = useSelector(state => getUserProfile(state).isAdmin);

	if (!isAdmin) {
		return 'Permission Denied';
	}
	return (
		<div className={styles.Root}>
			<div className={styles.SidebarContainer}>
				<aside className={clsx('menu is-medium', styles.Sidebar)}>
					<p className="menu-label">Administration</p>
					<ul className="menu-list">
						<li onClick={() => setView('overview')}>
							<a className={view === 'overview' ? 'is-active' : ''} href="/#">Overview</a>
						</li>
						<li onClick={() => setView('users')}>
							<a className={view === 'users' ? 'is-active' : ''} href="/#">Users</a>
						</li>
						<li onClick={() => setView('clients')}>
							<a className={view === 'clients' ? 'is-active' : ''} href="/#">Locations</a>
						</li>
						<li onClick={() => setView('deletions')}>
							<a className={view === 'deletions' ? 'is-active' : ''} href="/#">Deletions</a>
						</li>
						<li onClick={() => setView('archives')}>
							<a className={view === 'archives' ? 'is-active' : ''} href="/#">Archives</a>
						</li>
					</ul>
				</aside>
			</div>
			<div className={styles.Window}>
				{view === 'overview' && <Calendar />}
				{view === 'users' && <UsersGrid />}
				{view === 'clients' && <LocationsGrid />}
				{view === 'archives' && <ArchiveGrid />}
				{view === 'deletions' && <DeletionsGrid />}
			</div>
		</div>
	);
};

export default AdminView;
