import React from 'react';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { onEnterKeyDown } from '../lib/helpers';
import { logo as logoConfig } from 'config';
import { auth } from '../lib/firebase';
import './Login.component.css';

class Login extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: '',
			errorEmail: '',
			errorPassword: '',
			recover: false,
			working: false
		};
	}

	onPasswordChanged = e => this.setState({ password: e.target.value, errorPassword: '' });

	onEmailChanged = e => this.setState({ email: e.target.value, errorEmail: '' });

	onRecover = () => {
		if (this.state.working) return;

		if (this.state.email.length === 0) {
			this.setState({ errorEmail: 'Enter your email address' });
			this.emailRef.focus();
		} else {
			this.setState({ working: true });

			sendPasswordResetEmail(auth, this.state.email)
				.then(() => {
					this.setState({ working: false, recoverSent: true });
				})
				.catch(error => {
					if (error.code === 'auth/invalid-email') {
						this.setState({ working: false, errorEmail: "That email address isn't correct" });
					} else if (error.code === 'auth/user-not-found') {
						this.setState({ working: false, errorEmail: 'That email address does not match an existing account' });
					} else {
						this.setState({ working: false });
						console.log(error);
					}
				});
		}
	};

	onSignIn = () => {
		if (this.state.working) return;

		let errorEmail = '';
		let errorPassword = '';

		if (this.state.email.length === 0) {
			errorEmail = 'Enter your email address';
		}

		if (this.state.password.length === 0) {
			errorPassword = 'Enter your password';
		}

		if (errorEmail.length > 0) {
			this.emailRef.focus();
		} else if (errorPassword.length > 0) {
			this.passwordRef.focus();
		}

		if (errorEmail.length === 0) {
			this.setState({ working: true, errorEmail: '' });
			signInWithEmailAndPassword(auth, this.state.email, this.state.password).catch(error => {
				if (error.code === 'auth/invalid-credential') {
					if (this.state.password.length === 0) this.setState({ working: false, errorPassword: 'Enter your password', errorEmail: '' });
					else this.setState({ working: false, errorPassword: "That password isn't correct", errorEmail: '' });
					this.passwordRef.focus();
				} else if (error.code === 'auth/invalid-email') {
					this.setState({ working: false, errorEmail: "That email address isn't correct", errorPassword: errorPassword });
					this.emailRef.focus();
				} else if (error.code === 'auth/user-not-found') {
					this.setState({
						working: false,
						errorEmail: 'That email address does not match an existing account',
						errorPassword: errorPassword
					});
					this.emailRef.focus();
				} else if (error.code === 'auth/user-disabled') {
					this.setState({
						working: false,
						errorEmail: 'User account is currently disabled',
						errorPassword: errorPassword,
					});
				} else {
					this.setState({ working: false });
				}
			});
		} else {
			this.setState({ errorEmail, errorPassword });
		}
	};

	render() {
		let header = 'Sign in';

		let content = (
			<>
				<InputComp
					setRef={ref => (this.emailRef = ref)}
					autoComplete="username"
					type="text"
					label="Email"
					value={this.state.email}
					onChange={this.onEmailChanged}
					onEnterPress={this.onSignIn}
					isInvalid={this.state.errorEmail.length > 0}
				/>
				<div className="firebaseui-error-wrapper">
					<p className={`firebaseui-error ${this.state.errorEmail.length === 0 ? 'firebaseui-hidden' : ''}`}>{this.state.errorEmail}</p>
				</div>
				<InputComp
					setRef={ref => (this.passwordRef = ref)}
					autoComplete="current-password"
					type="password"
					label="Password"
					value={this.state.password}
					onChange={this.onPasswordChanged}
					onEnterPress={this.onSignIn}
					isInvalid={this.state.errorPassword.length > 0}
				/>
				<div className="firebaseui-error-wrapper">
					<p className={`firebaseui-error ${this.state.errorPassword.length === 0 ? 'firebaseui-hidden' : ''}`}>{this.state.errorPassword}</p>
				</div>
			</>
		);

		let actions = (
			<>
				<div className="firebaseui-form-links">
					<a className="firebaseui-link" href="/#" onClick={() => this.setState({ recover: true })}>
						Trouble signing in?
					</a>
				</div>
				<div className="firebaseui-form-actions">
					<button className={`firebaseui-button button is-info ${this.state.working ? 'is-loading' : ''}`} onClick={this.onSignIn}>
						Sign In
					</button>
				</div>
			</>
		);

		if (this.state.recoverSent) {
			header = 'Check your email';

			content = (
				<p className="firebaseui-text">
					Follow the instructions sent to <span className="firebaseui-text-emphasis">{this.state.email}</span> to recover your password
				</p>
			);

			actions = (
				<div className="firebaseui-form-actions">
					<button className="firebaseui-button button is-info" onClick={() => this.setState({ recoverSent: false, recover: false })}>
						Done
					</button>
				</div>
			);
		} else if (this.state.recover) {
			header = 'Recover password';

			content = (
				<>
					<p className="firebaseui-text">Get instructions sent to this email that explain how to reset your password</p>
					<InputComp
						setRef={ref => (this.emailRef = ref)}
						autoComplete="username"
						type="text"
						label="Email"
						value={this.state.email}
						onChange={this.onEmailChanged}
						onEnterPress={this.onRecover}
						isInvalid={this.state.errorEmail.length > 0}
					/>
					<div className="firebaseui-error-wrapper">
						<p className={`firebaseui-error ${this.state.errorEmail.length === 0 ? 'firebaseui-hidden' : ''}`}>{this.state.errorEmail}</p>
					</div>
				</>
			);

			actions = (
				<div className="firebaseui-form-actions">
					<button className="firebaseui-button button" disabled={this.state.working} onClick={() => this.setState({ recover: false })}>
						Cancel
					</button>
					<button className={`firebaseui-button button is-info ${this.state.working ? 'is-loading' : ''}`} onClick={this.onRecover}>
						Send
					</button>
				</div>
			);
		}

		return (
			<div className="firebaseui-container">
				<div className="firebaseui-card-header">
					<img className="firebaseui-header-logo" width={logoConfig.width} height={logoConfig.height} src={logoConfig.src} alt="" />
					<h1 className="firebaseui-title">{header}</h1>
				</div>
				<div className="firebaseui-card-content">{content}</div>
				<div className="firebaseui-card-actions">{actions}</div>
			</div>
		);
	}
}

export default Login;

class InputComp extends React.Component {
	constructor(props) {
		super(props);

		this.state = { focus: false };
	}

	render() {
		let className = 'firebaseui-textfield mdl-textfield';
		if (this.state.focus === true) className += ' is-focused';
		if (this.props.isInvalid) className += ' firebaseui-textfield-invalid';

		return (
			<div className={className}>
				<label className="mdl-textfield__label firebaseui-label">{this.props.label}</label>
				<input
					autoFocus={this.props.autoFocus || false}
					ref={this.props.setRef || null}
					className={`mdl-textfield__input firebaseui-input${this.props.isInvalid ? '-invalid' : ''}`}
					type={this.props.type || 'text'}
					autoComplete={this.props.autoComplete || ''}
					onBlur={() => this.setState({ focus: false })}
					onFocus={() => this.setState({ focus: true })}
					onChange={this.props.onChange}
					defaultValue={this.props.value}
					onKeyPress={onEnterKeyDown(this.props.onEnterPress)}
				/>
			</div>
		);
	}
}
