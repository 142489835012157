import React from 'react';
import { Button, Dialog, DialogHeader, DialogContent, DialogFooter, FieldLabel, Password } from 'Controls';

const SetPasswordDialog = ({ onAccept, onCancel, user, ...props }) => {
	const [password1, setPassword1] = React.useState('');
	const [password2, setPassword2] = React.useState('');

	return (
		<Dialog onClose={onCancel}>
			<DialogHeader title="Set Password" onClose={onCancel} />
			<DialogContent>
				{user && (
					<p>
						Set password for: <span className="has-text-weight-bold">{user}</span>
					</p>
				)}
				<FieldLabel label="Password">
					<Password autoFocus autoComplete="new-password" value={password1} onChange={e => setPassword1(e.target.value)} />
				</FieldLabel>
				<FieldLabel label="Verify Password">
					<Password autoComplete="new-password" value={password2} onChange={e => setPassword2(e.target.value)} />
				</FieldLabel>
			</DialogContent>
			<DialogFooter>
				<Button
					color="primary"
					disabled={!(password1?.length >= 8 && password1 === password2)}
					onClick={() => {
						onAccept(password1);
						onCancel();
					}}>
					Set Password
				</Button>
				<Button onClick={onCancel}>Cancel</Button>
			</DialogFooter>
		</Dialog>
	);
};

export default SetPasswordDialog;
