import React from 'react';
import { Checkbox } from 'antd';
import { Button, Dialog, DialogHeader, DialogContent, DialogFooter } from 'Controls';

const CompleteInspectionDialog = ({ onAccept, onCancel, open, ...props }) => {
	const [sendEmails, setSendEmails] = React.useState(true);

	return (
		<Dialog onClose={onCancel}>
			<DialogHeader title="Finish Inspection" onClose={onCancel} />
			<DialogContent>
				<p>Are you certain you want to finish this inspection?</p>
				<Checkbox checked={sendEmails} onChange={e => setSendEmails(e.target.checked)}>
					Send Client Emails
				</Checkbox>
			</DialogContent>
			<DialogFooter>
				<Button color="danger" onClick={() => onAccept(sendEmails)}>
					Finish
				</Button>
				<Button autoFocus onClick={onCancel}>
					Cancel
				</Button>
			</DialogFooter>
		</Dialog>
	);
};

export default CompleteInspectionDialog;
