const Mycotoxins = {
	afla: {
		units: 'ppb',
		comparer: '>=',
		value: 5
	},
	don: {
		units: 'ppm',
		comparer: '>=',
		value: 0.5
	},
	fumn: {
		units: 'ppm',
		comparer: '>=',
		value: 0.5
	},
	zear: {
		units: 'ppb',
		comparer: '>=',
		value: 50
	}
};

export default Mycotoxins;
