import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { changeView, newInspection } from 'Actions';
import { getInspection } from 'Selectors';
import { CarrierView, Billing, Main, CarrierFooter, MarkSample } from 'Components';
import { Button, Loading } from 'Controls';
import Spreadsheet from './spreadsheet-main';
import styles from './InspectionView.module.css';
import CompositeCarrierGrid from './spreadsheet-main/CompositeCarrierGrid';

const InspectionView = ({ view, id, isFetching, isAdding, currentInspection }) => {
	const dispatch = useDispatch();

	if (isFetching) {
		return <Loading message="Fetching Inspections" />;
	}

	if (id === null || !currentInspection.inspection) {
		if (isAdding) {
			return <Loading message="Creating Inspection" />;
		}

		return (
			<div className={styles.Centered}>
				<div className="buttons">
					<Button size="large" color="primary" onClick={() => dispatch(newInspection())}>
						Start New Inspection
					</Button>
					<Button size="large" color="info" onClick={() => dispatch(changeView('grid'))}>
						Continue Existing Inspection
					</Button>
				</div>
			</div>
		);
	}

	return (
		<div className={styles.Container}>
			{view !== 'spreadsheet' && (
				<div className={styles.Main}>
					{view === 'main' && <Main />}
					{view === 'billing' && <Billing />}
					{view === 'inspection' && <CarrierView />}
					{view === 'markSample' && <MarkSample />}
				</div>
			)}
			{view === 'spreadsheet' && currentInspection?.inspection?.sampleGrouping !== '5-Car Composite' && <Spreadsheet />}
			{view === 'spreadsheet' && currentInspection?.inspection?.sampleGrouping === '5-Car Composite' && <CompositeCarrierGrid />}
			<CarrierFooter />
		</div>
	);
};

export default connect(state => ({
	view: state.view.inspectionWindow,
	isFetching: state.inspections.isFetching,
	isAdding: state.inspections.isAdding,
	id: state.currentInspection.id,
	currentInspection: getInspection(state)
}))(InspectionView);
