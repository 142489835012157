import omit from 'lodash/omit';
import {
	AUTH_DESTROYED,
	ADD_INSPECTION,
	INSPECTION_ADDED,
	REQUEST_INSPECTIONS,
	REQUEST_INSPECTIONS_FINISHED,
	RECEIVE_INSPECTION_ADDED,
	RECEIVE_INSPECTION_REMOVED,
	RECEIVE_INSPECTION_MODIFIED
} from '../Actions/types';

const inspections = (state = { allIds: [], byId: {}, isFetching: false, isAdding: false }, action) => {
	switch (action.type) {
		case ADD_INSPECTION:
			return { ...state, isAdding: true };

		case INSPECTION_ADDED:
			return { ...state, isAdding: false };

		case AUTH_DESTROYED:
			return { allIds: [], byId: {}, isFetching: false };

		case REQUEST_INSPECTIONS:
			return {
				...state,
				isFetching: true
			};

		case RECEIVE_INSPECTION_ADDED:
			return {
				...state,
				allIds: [...state.allIds, action.id],
				byId: { ...state.byId, [action.id]: action.added }
			};

		case RECEIVE_INSPECTION_REMOVED:
			return {
				...state,
				allIds: state.allIds.filter(id => id !== action.id),
				byId: omit(state.byId, action.id)
			};

		case RECEIVE_INSPECTION_MODIFIED:
			return {
				...state,
				byId: { ...state.byId, [action.id]: action.modified }
			};

		case REQUEST_INSPECTIONS_FINISHED:
			return {
				...state,
				isFetching: false
			};

		default:
			return state;
	}
};

export default inspections;
