import React from 'react';
import clsx from 'clsx';
import { useComponentDidMount } from 'lib/helpers';

const Input = ({
	type,
	horizontal,
	size,
	focus,
	className,
	uppercase,
	value: defaultValue,
	minValue,
	label,
	onChange,
	onBlur,
	onFocus,
	onEnter,
	onTabForward,
	onTabBack,
	...props
}) => {
	const [value, setValue] = React.useState(defaultValue ?? '');
	const [prevValue, setPrevValue] = React.useState(defaultValue ?? '');
	const ref = React.createRef();

	React.useEffect(() => setValue(defaultValue), [defaultValue]);

	useComponentDidMount(() => {
		if (focus && ref.current) {
			ref.current.focus();
			ref.current.selectionStart = ref.current.selectionEnd = ref.current.value?.length;
		}
	});

	const handleChange = e => {
		const val = uppercase ? e.target.value.toUpperCase() : e.target.value;
		if (onChange) onChange(val);
		setValue(val);
	};

	const handleBlur = e => {
		if (prevValue !== value) {
			onBlur(e);
		}
	};

	const handleFocus = e => {
		setPrevValue(value);
	};

	const handleKeyDown = e => {
		if (onEnter && e.key === 'Enter') onEnter(e);
		if (onTabForward && e.key === 'Tab' && !e.shiftKey) onTabForward(e);
		if (onTabBack && e.key === 'Tab' && e.shiftKey) onTabBack(e);
	};

	return (
		<div className="field">
			{label && <label className="label">{label}</label>}
			<div className="control">
				<input
					className={clsx('input', className, {
						'is-large': size === 'large',
						'is-medium': size === 'medium'
					})}
					type={type ?? 'text'}
					value={value}
					min={minValue}
					ref={ref}
					onChange={handleChange}
					onBlur={onBlur && handleBlur}
					onFocus={handleFocus}
					onKeyDown={handleKeyDown}
					{...props}
				/>
			</div>
		</div>
	);
};

export default Input;
