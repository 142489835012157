import React from 'react';
import clsx from 'clsx';
import { AutoSizer, List } from 'react-virtualized';
import { Button } from 'Controls';
import styles from './Spinner.module.css';

const Spinner = ({ scrollValue, value, values, displayValues, onChange, className }) => (
	<div className={clsx(styles.Column, className)}>
		<AutoSizer>
			{({ height, width }) => (
				<List
					value={value}
					width={width}
					height={height}
					rowCount={values.length}
					rowHeight={90}
					scrollToAlignment="center"
					scrollToIndex={values.indexOf(value || scrollValue)}
					rowRenderer={({ key, index, style }) => (
						<Button
							key={key}
							fullWidth
							size="large"
							color={values[index] === value && 'primary'}
							style={style}
							className={styles.Button}
							tabIndex={values[index] === value ? 1 : -1}
							onClick={() => onChange(values[index])}>
							{displayValues ? displayValues[index] : values[index]}
						</Button>
					)}
				/>
			)}
		</AutoSizer>
	</div>
);

export default Spinner;
