import React from 'react';
import { Spin } from 'antd';
import styles from './Loading.module.css';

const Loading = ({ message }) => (
	<div className={styles.Container}>
		{message && <div className={styles.Message}>{message}</div>}
		<Spin tip={message} />
	</div>
);

export default Loading;
