import React from 'react';
import clsx from 'clsx';

const Button = ({ loading, fullWidth, variant, size, light, color, className, ...props }) => {
	const classNames = React.useMemo(
		() =>
			clsx('button', className, {
				'is-fullwidth': fullWidth,
				'is-loading': loading,
				'is-large': size === 'large',
				'is-medium': size === 'medium',
				'is-small': size === 'small',
				'is-light': light,
				'is-outlined': variant === 'outlined',
				'is-text button-circle': variant === 'icon',
				'is-primary': variant !== 'icon' && color === 'primary',
				'is-success': variant !== 'icon' && color === 'success',
				'is-info': variant !== 'icon' && color === 'info',
				'is-warning': variant !== 'icon' && color === 'warning',
				'is-danger': variant !== 'icon' && color === 'danger',
				'has-text-primary': variant === 'icon' && color === 'primary',
				'has-text-success': variant === 'icon' && color === 'success',
				'has-text-info': variant === 'icon' && color === 'info',
				'has-text-warning': variant === 'icon' && color === 'warning',
				'has-text-danger': variant === 'icon' && color === 'danger'
			}),
		[fullWidth, variant, loading, size, color, light, className]
	);
	return <button className={classNames} {...props} />;
};

export default Button;
