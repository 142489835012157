import React from 'react';
import { useSelector } from 'react-redux';
import { getCarrierInspection, getInspection } from 'Selectors';
import styles from './CarrierHeader.module.css';

const CarrierHeader = ({ children }) => {
	const carrier = useSelector(state => getCarrierInspection(state).carrier);
	const sampleGrouping = useSelector(state => getInspection(state)?.inspection?.sampleGrouping);
	const title = sampleGrouping === '5-Car Composite' ? 'Composite' : 'Carrier';

	return (
		<div className={styles.Root}>
			<div>
				<h2 className="title">
					{title} # {carrier.orderId}
				</h2>
				<h3 className="subtitle">
					{carrier.offId ? ' (RELOAD)' : ''}
					{carrier.rexId ? ' (REX)' : ''}
				</h3>
			</div>
			{children}
		</div>
	);
};

export default CarrierHeader;
