import React from 'react';
import clsx from 'clsx';
import { Button } from 'Controls';
import styles from './NumPad.module.css';

const NumPad = ({ onClick, onDelete, className, ...props }) =>  (
		<div className={clsx(styles.Root, className)} {...props}>
			<div className={styles.Row}>
				<Button size="large" className={styles.Button} tabIndex={-1} onClick={() => onClick(1) }>1</Button>
				<Button size="large" className={styles.Button} tabIndex={-1} onClick={() => onClick(2) }>2</Button>
				<Button size="large" className={styles.Button} tabIndex={-1} onClick={() => onClick(3) }>3</Button>
			</div>
			<div className={styles.Row}>
				<Button size="large" className={styles.Button} tabIndex={-1} onClick={() => onClick(4) }>4</Button>
				<Button size="large" className={styles.Button} tabIndex={-1} onClick={() => onClick(5) }>5</Button>
				<Button size="large" className={styles.Button} tabIndex={-1} onClick={() => onClick(6) }>6</Button>
			</div>
			<div className={styles.Row}>
				<Button size="large" className={styles.Button} tabIndex={-1} onClick={() => onClick(7) }>7</Button>
				<Button size="large" className={styles.Button} tabIndex={-1} onClick={() => onClick(8) }>8</Button>
				<Button size="large" className={styles.Button} tabIndex={-1} onClick={() => onClick(9) }>9</Button>
			</div>
			<div className={styles.Row}>
				<Button size="large" className={styles.Button} tabIndex={-1} disabled/>
				<Button size="large" className={styles.Button} tabIndex={-1} onClick={() => onClick(0) }>0</Button>
				<Button size="large" className={styles.Button} tabIndex={-1} onClick={() => onDelete() }>DEL</Button>
			</div>
		</div>
	);

export default NumPad;
