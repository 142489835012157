import omit from 'lodash/omit';
import {
	AUTH_DESTROYED,
	RECEIVE_INSPECTION_REMOVED,
	SET_CURRENT_INSPECTION,
	SET_CURRENT_CARRIER,
	REQUEST_CARRIERS,
	REQUEST_CARRIERS_FINISHED,
	RECEIVE_CARRIER_ADDED,
	RECEIVE_CARRIER_REMOVED,
	RECEIVE_CARRIER_MODIFIED,
	REQUEST_REX,
	REQUEST_REX_FINISHED,
	RECEIVE_REX_ADDED,
	RECEIVE_REX_REMOVED,
	RECEIVE_REX_MODIFIED,
	REQUEST_OFF,
	REQUEST_OFF_FINISHED,
	RECEIVE_OFF_ADDED,
	RECEIVE_OFF_REMOVED,
	RECEIVE_OFF_MODIFIED,
	ADD_CARRIER,
	ADD_REX_CARRIER,
	ADD_OFF_CARRIER,
	DELETE_INSPECTION,
	CARRIER_ADDED,
	CARRIERS_IMPORTED,
	INSPECTION_COMPLETE,
	COMPLETING_INSPECTION,
	COMPLETING_INSPECTION_FAILED
} from '../Actions/types';

const defaultState = {
	id: null,
	carrierId: null,
	carriers: {
		allIds: [],
		byId: {},
		isFetching: false,
		isAdding: false
	},
	rex: {
		allIds: [],
		byId: {},
		isFetching: false,
		isAdding: false
	},
	off: {
		allIds: [],
		byId: {},
		isFetching: false,
		isAdding: false
	},
	completing: false,
	complete: false,
	error: null
};

const currentInspection = (state = defaultState, action) => {
	switch (action.type) {
		case AUTH_DESTROYED:
			return defaultState;

		case RECEIVE_INSPECTION_REMOVED:
			if (action.id === state.id) return defaultState;
			else return state;

		case SET_CURRENT_INSPECTION:
			if (action.id === null) {
				return defaultState;
			} else {
				return {
					...state,
					id: action.id
				};
			}
		case DELETE_INSPECTION:
			return action.id === state.id ? defaultState : state;

		case REQUEST_CARRIERS:
			return {
				...state,
				carriers: {
					allIds: [],
					byId: {},
					isAdding: false,
					isFetching: true,
				},
			};

		case RECEIVE_CARRIER_ADDED:
			return {
				...state,
				carrierId: state.carriers.isAdding ? action.id : state.carrierId,
				carriers: {
					...state.carriers,
					allIds: [...state.carriers.allIds, action.id],
					byId: {
						...state.carriers.byId,
						[action.id]: action.added
					}
				}
			};

		case RECEIVE_CARRIER_REMOVED:
			return {
				...state,
				carrierId: action.id === state.carrierId ? null : state.carrierId,
				carriers: {
					...state.carriers,
					allIds: state.carriers.allIds.filter(id => id !== action.id),
					byId: omit(state.carriers.byId, action.id)
				}
			};

		case RECEIVE_CARRIER_MODIFIED:
			return {
				...state,
				carriers: {
					...state.carriers,
					byId: {
						...state.carriers.byId,
						[action.id]: action.modified
					}
				}
			};

		case REQUEST_CARRIERS_FINISHED: {
			let incompletes = state.carriers.allIds
				.filter(n => state.carriers.byId[n].isComplete !== true)
				.sort((a, b) => state.carriers.byId[a].orderId - state.carriers.byId[b].orderId);

			return {
				...state,
				carrierId: incompletes[0] || null,
				carriers: {
					...state.carriers,
					isFetching: false
				}
			};
		}

		case CARRIERS_IMPORTED: {
			let incompletes = state.carriers.allIds
				.filter(n => state.carriers.byId[n].isComplete !== true)
				.sort((a, b) => state.carriers.byId[a].orderId - state.carriers.byId[b].orderId);

			return {
				...state,
				carrierId: incompletes[0] || state.carrierId
			};
		}

		case ADD_CARRIER:
			return {
				...state,
				carriers: {
					...state.carriers,
					isAdding: true
				}
			};

		case CARRIER_ADDED:
			return {
				...state,
				carriers: {
					...state.carriers,
					isAdding: false
				}
			};

		case SET_CURRENT_CARRIER:
			if (!action.id) {
				return state;
			} else {
				return {
					...state,
					carrierId: action.id
				};
			}

		case REQUEST_REX:
			return {
				...state,
				rex: {
					allIds: [],
					byId: {},
					isAdding: false,
					isFetching: true,
				},
			};

		case RECEIVE_REX_ADDED:
			return {
				...state,
				rex: {
					...state.rex,
					allIds: [...state.rex.allIds, action.id],
					byId: {
						...state.rex.byId,
						[action.id]: action.added
					}
				}
			};

		case RECEIVE_REX_REMOVED:
			return {
				...state,
				rex: {
					...state.rex,
					allIds: state.rex.allIds.filter(id => id !== action.id),
					byId: omit(state.rex.byId, action.id)
				}
			};

		case RECEIVE_REX_MODIFIED:
			return {
				...state,
				rex: {
					...state.rex,
					byId: {
						...state.rex.byId,
						[action.id]: action.modified
					}
				}
			};

		case REQUEST_REX_FINISHED:
			return {
				...state,
				rex: {
					...state.rex,
					isFetching: false
				}
			};

		case ADD_REX_CARRIER:
			return {
				...state,
				rex: {
					...state.rex,
					isAdding: true
				}
			};

		case REQUEST_OFF:
			return {
				...state,
				off: {
					allIds: [],
					byId: {},
					isAdding: false,
					isFetching: true,
				},
			};

		case RECEIVE_OFF_ADDED:
			return {
				...state,
				off: {
					...state.off,
					allIds: [...state.off.allIds, action.id],
					byId: {
						...state.off.byId,
						[action.id]: action.added
					}
				}
			};

		case RECEIVE_OFF_REMOVED:
			return {
				...state,
				off: {
					...state.off,
					allIds: state.off.allIds.filter(id => id !== action.id),
					byId: omit(state.off.byId, action.id)
				}
			};

		case RECEIVE_OFF_MODIFIED:
			return {
				...state,
				off: {
					...state.off,
					byId: {
						...state.off.byId,
						[action.id]: action.modified
					}
				}
			};

		case REQUEST_OFF_FINISHED:
			return {
				...state,
				off: {
					...state.off,
					isFetching: false
				}
			};

		case ADD_OFF_CARRIER:
			return {
				...state,
				off: {
					...state.off,
					isAdding: true
				}
			};

		case COMPLETING_INSPECTION:
			return { ...state, completing: true, complete: false, error: null };
		case INSPECTION_COMPLETE:
			return { ...state, completing: false, complete: true, error: null };
		case COMPLETING_INSPECTION_FAILED:
			return { ...state, completing: false, complete: false, error: action.error };
		// case CONFIRM_CARRIER:
		// 	return {
		// 		...state,
		// 		carrierId: action.id ? action.id : null
		// 	};

		default:
			return state;
	}
};

export default currentInspection;
