import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { changeCarrierView, updateCarrierInspection, offloadSingleCompositeCarrier } from 'Actions';
import { getInspection, getCarrierInspection } from 'Selectors';
import { NumericFormat } from 'react-number-format';
import { CarrierHeader } from 'Components';
import { Button, Input } from 'Controls';
import styles from './Carrier.module.css';

class CompositeCarrier extends React.Component {
	// const CompositeCarrier = () => {
	// const [carNumbers, setCarNumbers] = React.useState([]);
	// const [netWeights, setNetWeights] = React.useState([]);
	// const [inspector, setInspector] = React.useState(props.inspector || '');

	constructor(props) {
		super(props);

		this.state = {
			carNumber: props.carNumber || '',
			netWeight: props.netWeight || '',
			inspector: props.inspector || '',
			bookingNumber: props.bookingNumber || '',
			sealNumber: props.sealNumber || '',
			composite: props.composite || {}
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			carNumber: nextProps.carNumber || '',
			netWeight: nextProps.netWeight || '',
			inspector: nextProps.inspector || '',
			bookingNumber: nextProps.bookingNumber || '',
			sealNumber: nextProps.sealNumber || '',
			composite: nextProps.composite || {}
		});
	}

	isValid() {
		return (
			this.state.inspector !== '' &&
			this.state.composite[0]?.netWeight &&
			this.state.composite[0]?.carNumber &&
			!Object.keys(this.state.composite).some(i => {
				const d = this.state.composite[i];
				return (d?.carNumber && !d?.netWeight) || (!d?.carNumber && d?.netWeight);
			})
		);
	}

	validate() {
		if (this.isValid()) {
			this.props.changeCarrierView('gradeFactors');
		}
	}

	render() {
		const {
			updateCarrierInspection,
			changeCarrierView,
			offloadSingleCompositeCarrier,
			currentInspection: { carrierId }
		} = this.props;

		return (
			<div className={styles.Container}>
				<CarrierHeader>
					<Button color="success" size="large" disabled={!this.isValid()} tabIndex={12} onClick={() => this.validate()}>
						Grade Factors
					</Button>
				</CarrierHeader>
				<div className={styles.Body}>
					<div className={styles.Row}>
						{[0, 1, 2, 3, 4].map(v => (
							<div key={v} className={styles.RowItem}>
								<div className={styles.Row}>
									<label className={clsx('label', styles.Grow)}>Carrier # {v + 1}</label>
									<Button
										disabled={!this.state.composite[v]?.carNumber || !this.state.composite[v]?.netWeight}
										onClick={() => {
											offloadSingleCompositeCarrier(carrierId, v);
											this.setState();
										}}>
										O
									</Button>
								</div>
								<Input
									uppercase
									placeholder="ID"
									tabIndex={1 + v * 2}
									value={this.state.composite[v]?.carNumber ?? ''}
									onChange={val =>
										this.setState({ composite: { ...this.state.composite, [v]: { ...this.state.composite[v], carNumber: val } } })
									}
									onBlur={() => updateCarrierInspection(carrierId, { composite: this.state.composite })}
								/>
								<NumericFormat
									thousandSeparator
									placeholder="Net Weight"
									className="input"
									tabIndex={2 + v * 2}
									value={this.state.composite[v]?.netWeight ?? ''}
									onValueChange={({ floatValue }) =>
										this.setState({
											composite: { ...this.state.composite, [v]: { ...this.state.composite[v], netWeight: floatValue ?? '' } }
										})
									}
									onBlur={() => updateCarrierInspection(carrierId, { composite: this.state.composite })}
								/>
							</div>
						))}
					</div>
					{/* {inspection.lotType === 'Container' && (
						<>
							<Input
								uppercase
								autoFocus={this.state.netWeight}
								label="Booking #"
								tabIndex={3}
								value={this.state.bookingNumber}
								onChange={val => this.setState({ bookingNumber: val })}
								onBlur={() => updateCarrierInspection(carrierId, { bookingNumber: this.state.bookingNumber })}
							/>
							<Input
								uppercase
								autoFocus={this.state.bookingNumber}
								label="Seal #"
								tabIndex={4}
								value={this.state.sealNumber}
								onChange={val => this.setState({ sealNumber: val })}
								onBlur={() => updateCarrierInspection(carrierId, { sealNumber: this.state.sealNumber })}
							/>
						</>
					)} */}
					<Input
						uppercase
						autoFocus={this.state.carNumber && this.state.netWeight}
						label="Inspector"
						tabIndex={11}
						value={this.state.inspector}
						onChange={val => this.setState({ inspector: val })}
						onTabForward={e => {
							e.preventDefault();
							if (this.isValid()) {
								updateCarrierInspection(carrierId, { inspector: this.state.inspector });
								changeCarrierView('gradeFactors');
							}
						}}
						onBlur={() => updateCarrierInspection(carrierId, { inspector: this.state.inspector })}
						onEnter={() => this.validate()}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	const currCarrier = getCarrierInspection(state).carrier;

	return {
		currentInspection: getInspection(state),
		inspector: currCarrier.inspector,
		bookingNumber: currCarrier.bookingNumber,
		sealNumber: currCarrier.sealNumber,
		composite: currCarrier.composite
	};
};

export default connect(mapStateToProps, {
	updateCarrierInspection,
	changeCarrierView,
	offloadSingleCompositeCarrier
})(CompositeCarrier);
