import { CalculateSingleCarrier } from '../models/grading';

import { truncator } from '../lib/helpers';
import Mycotoxins from '../models/mycotoxins';
import getCommodityFactors from './factors';
import { createSelector } from 'reselect';

const getInspection = state => state.inspections.byId[state.currentInspection.id];
const getCarriers = state => state.currentInspection.carriers;
const getCarrierId = state => state.currentInspection.carrierId;
const rexCarriersSelector = state => state.currentInspection.rex;
const offCarriersSelector = state => state.currentInspection.off;

export default createSelector(
	[getInspection, getCarriers, getCommodityFactors, rexCarriersSelector, offCarriersSelector, getCarrierId],
	(inspection, carriers, factors, rex, off, carrierId) => {
		if (!inspection) return null;

		const calculatedInspections = calculateCarriers(carriers, rex, inspection, factors);
		const calculatedRex = calculateCarriers(rex, null, inspection, factors);
		const calculatedOff = calculateCarriers(off, null, inspection, factors);
		const diffedCarriers = diffInspections(calculatedInspections, calculatedRex, factors);

		let reducedCarriers = inspectionsReducer(diffedCarriers);
		let reducedRex = inspectionsReducer(calculatedRex);
		let reducedOff = inspectionsReducer(calculatedOff);

		reducedRex.sort((a, b) => a.orderId - b.orderId || a.creationDate?.toDate() - b.creationDate?.toDate());
		reducedOff.sort((a, b) => a.orderId - b.orderId || a.creationDate?.toDate() - b.creationDate?.toDate());

		reducedCarriers.sort((a, b) => a.orderId - b.orderId);

		let averages = [];

		if (inspection.sampleGrouping === '5-Car Average') {
			reducedCarriers = calculateXCarAverages(5, reducedCarriers, factors);
			averages = calculateAverages(
				factors,
				reducedCarriers.filter(i => i.isAverage === true)
			);
		} else if (inspection.sampleGrouping === '5-Car Composite') {
			reducedCarriers = reducedCarriers.map(i => {
				if (i.composite && !i.netWeight && !i.carNumber) {
					return {
						...i,
						netWeight: Object.keys(i.composite).map(d => i.composite[d]?.netWeight),
						carNumber: Object.keys(i.composite).map(d => i.composite[d]?.carNumber),
						isInfoComplete: isValidComposite(i.composite)
					};
				} else return { ...i };
			});
			averages = calculateAverages(factors, diffedCarriers);
			reducedOff = mapCompositeValues(reducedOff);
			reducedRex = mapCompositeValues(reducedRex);
		} else {
			averages = calculateAverages(factors, diffedCarriers);
		}

		return {
			carriers: reducedCarriers,
			rex: reducedRex,
			off: reducedOff,
			averages,
			currentInspection: inspection,
			carrierId,
			displayFactors: factors ? factors.calculation.display : []
		};
	}
);

const diffInspections = (carriers, rex, factors) => {
	return Object.keys(carriers).reduce((acc, id) => {
		let carrier = Object.assign({}, carriers[id]);
		if (carrier.rexId && rex[carrier.rexId]) {
			const diff = Object.keys(factors).reduce((acc2, id2) => {
				const origVal = carrier[id2];
				const newVal = rex[carrier.rexId][id2] || carrier[id2];
				return (origVal != newVal) ? { ...acc2, [id2]: true } : acc2;
			}, {});
			const mycoDiff = Object.keys(Mycotoxins).reduce((acc2, id2) => {
				if (carrier[id2] && rex[carrier.rexId][id2] && carrier[id2].timestamp !== rex[carrier.rexId][id2].timestamp) return { ...acc2, [id2]: true };
				else return acc2;
			}, {});
			Object.assign(carrier, { diff, mycoDiff });
			Object.assign(rex[carrier.rexId], { diff, mycoDiff });
		} else Object.assign(carrier, { diff: {}, mycoDiff: {} });
		return { ...acc, [id]: carrier };
	}, {});
};

const calculateAverages = (factors, inspections) => {
	if (!factors) return {};
	return factors.calculation.averages.reduce(
		(acc, key) => {
			let count = 0;
			const sum = Object.keys(inspections).reduce((a, b) => {
				let val = inspections[b][key];
				if (val === undefined) return a;

				val = parseFloat(val);
				if (isNaN(val)) return a;

				count++;
				return a + val;
			}, 0);

			if (count === 0) return acc;

			return { ...acc, [key]: truncator(sum / count, 2).toFixed(1) };
		},
		{ isAverage: true }
	);
};

const calculateXCarAverages = (x, carriers, factors) => {
	const nodes = [];
	const results = [];

	carriers.forEach((val, i) => {
		const pos = parseInt(i / x, 10);

		if (!nodes[pos]) {
			nodes[pos] = [];
		}

		nodes[pos].push(val);
	});

	nodes.forEach((val, i) => {
		if (val.length === 1) {
			results.push(...val);
		} else {
			results.push(...val, { ...calculateAverages(factors, val), orderId: `${i * x + 1} - ${i * x + val.length}` });
		}
	});

	return results;
};

const inspectionsReducer = inspections => Object.keys(inspections).map(id => ({ ...inspections[id], id: id }));

const calculateCarriers = (carriers, rex, inspection, factors) => {
	return carriers.allIds.reduce((acc, id) => {
		const carrier = carriers.byId[id];
		return { ...acc, [id]: CalculateSingleCarrier(carrier, carrier.rexId ? rex.byId[carrier.rexId] : null, inspection, factors) }
	}, {});
};

const isValidComposite = composite => {
	return !Object.keys(composite).some(i => {
		const d = composite[i];
		return (d?.carNumber && !d?.netWeight) || (!d?.carNumber && d?.netWeight);
	});
};

const mapCompositeValues = array =>
	array.map(i => {
		if (i.composite && !i.netWeight && !i.carNumber) {
			return {
				...i,
				netWeight: Object.keys(i.composite).map(d => i.composite[d]?.netWeight),
				carNumber: Object.keys(i.composite).map(d => i.composite[d]?.carNumber)
			};
		} else return { ...i };
	});
