import { createSelector } from 'reselect';

const userSelector = state => state.user;

const displayUser = ({ auth, data, isFetching }) => {
	let displayName = null;
	let isSignedIn = false;
	let isAdmin = false;
	let isClient = false;
	let isInsp = false;
	let isCertificate = false;
	let email = null;

	if (auth) {
		email = auth.email;
		displayName = (data ? data.displayName : auth.displayName) || auth.email;
		isSignedIn = true;
		isAdmin = data && data.role === 'Admin';
		isClient = data && data.role === 'Client';
		isCertificate = data && data.role === 'Certificate';
		isInsp = data && data.role === 'Inspector';
	}
	return {
		email,
		isSignedIn,
		isAdmin,
		isInsp,
		isClient,
		isCertificate,
		displayName,
		isFetching,
	};
};

export default createSelector(userSelector, displayUser);
