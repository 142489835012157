import React from 'react';
import FactorDisplayNames from '../models/factors/names';

const FactorsList = ({ data }) => {
	return Object.keys(data).map(val => (
		<div key={val}>
			<b>{FactorDisplayNames[val]}</b>: {data[val]}
		</div>
	));
};

export default FactorsList;
