import { UPDATE_AUTH, APP_INITIALIZED, AUTH_DESTROYED } from '../Actions/types';

const initialized = (state = false, action) => {
	switch (action.type) {
		case APP_INITIALIZED:
			return true;
		case UPDATE_AUTH:
		case AUTH_DESTROYED:
			return false;
		default:
			return state;
	}
};

export default initialized;
