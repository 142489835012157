import React from 'react';
import { connect } from 'react-redux';
import { changeCarrierView, updateCarrierInspection } from 'Actions';
import { getInspection, getCarrierInspection } from 'Selectors';
import { NumericFormat } from 'react-number-format';
import { CarrierHeader } from 'Components';
import { Button, FieldLabel, Input } from 'Controls';
import styles from './Carrier.module.css';

class Carrier extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			carNumber: props.carNumber || '',
			netWeight: props.netWeight || '',
			inspector: props.inspector || '',
			bookingNumber: props.bookingNumber || '',
			sealNumber: props.sealNumber || ''
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			carNumber: nextProps.carNumber || '',
			netWeight: nextProps.netWeight || '',
			inspector: nextProps.inspector || '',
			bookingNumber: nextProps.bookingNumber || '',
			sealNumber: nextProps.sealNumber || ''
		});
	}

	render() {
		const {
			updateCarrierInspection,
			changeCarrierView,
			currentInspection: { inspection, carrierId }
		} = this.props;

		const isValid = () => this.state.carNumber !== '' && this.state.netWeight !== '' && this.state.inspector !== '';

		const validate = () => {
			if (isValid()) {
				changeCarrierView('gradeFactors');
			}
		};

		const handleBlur = () => {
			if (this.props.netWeight !== this.state.netWeight) updateCarrierInspection(carrierId, { netWeight: this.state.netWeight });
		};

		return (
			<div className={styles.Container}>
				<CarrierHeader>
					<Button
						color="success"
						size="large"
						disabled={this.state.carNumber === '' || this.state.netWeight === '' || this.state.inspector === ''}
						tabIndex={6}
						onClick={() => validate()}>
						Grade Factors
					</Button>
				</CarrierHeader>
				<div className={styles.Body}>
					<Input
						autoFocus
						uppercase
						label="ID"
						tabIndex={1}
						value={this.state.carNumber}
						onChange={val => this.setState({ carNumber: val })}
						onBlur={() => updateCarrierInspection(carrierId, { carNumber: this.state.carNumber })}
					/>
					<FieldLabel label="Net Weight">
						<NumericFormat
							thousandSeparator
							autoFocus={this.state.carNumber}
							className="input"
							tabIndex={2}
							value={this.state.netWeight}
							onValueChange={({ floatValue }) => this.setState({ netWeight: floatValue || '' })}
							onBlur={handleBlur}
						/>
					</FieldLabel>
					{inspection.lotType === 'Container' && (
						<>
							<Input
								uppercase
								autoFocus={this.state.netWeight}
								label="Booking #"
								tabIndex={3}
								value={this.state.bookingNumber}
								onChange={val => this.setState({ bookingNumber: val })}
								onBlur={() => updateCarrierInspection(carrierId, { bookingNumber: this.state.bookingNumber })}
							/>
							<Input
								uppercase
								autoFocus={this.state.bookingNumber}
								label="Seal #"
								tabIndex={4}
								value={this.state.sealNumber}
								onChange={val => this.setState({ sealNumber: val })}
								onBlur={() => updateCarrierInspection(carrierId, { sealNumber: this.state.sealNumber })}
							/>
						</>
					)}
					<Input
						uppercase
						autoFocus={this.state.carNumber && this.state.netWeight}
						label="Inspector"
						tabIndex={5}
						value={this.state.inspector}
						onChange={val => this.setState({ inspector: val })}
						onTabForward={e => {
							e.preventDefault();
							if (isValid()) {
								updateCarrierInspection(carrierId, { inspector: this.state.inspector });
								changeCarrierView('gradeFactors');
							}
						}}
						onBlur={() => updateCarrierInspection(carrierId, { inspector: this.state.inspector })}
						onEnter={() => validate()}
					/>
				</div>
			</div>
		);
	}
}
const mapStateToProps = state => {
	const currCarrier = getCarrierInspection(state).carrier;

	return {
		currentInspection: getInspection(state),
		carNumber: currCarrier.carNumber,
		netWeight: currCarrier.netWeight,
		inspector: currCarrier.inspector,
		bookingNumber: currCarrier.bookingNumber,
		sealNumber: currCarrier.sealNumber
	};
};

export default connect(mapStateToProps, {
	updateCarrierInspection,
	changeCarrierView
})(Carrier);
