import { createSelector } from 'reselect';

const getUsers = state => state.users;

export default createSelector(getUsers, users => {
	return {
		users: users.allIds.map(id => ({ ...users.byId[id], lastLogin: users.byId[id].lastLogin?.toDate(), email: id })),
		isFetching: users.isFetching
	};
});
