import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCarrierInspection } from 'Actions';
import FactorDisplayNames from 'models/factors/names';
import { isNumeric } from 'lib/helpers';
import { getInspection } from 'Selectors';
import { Button, NumPad, Input } from 'Controls';
import styles from './CountFactor.module.css';

const CountFactor = ({ onAccept, onCancel, ...props }) => {
	const dispatch = useDispatch();
	const inspection = useSelector(getInspection);
	const currentFactor = useSelector(state => state.currentFactor);

	const [value, setValue] = React.useState(inspection.currentCarrier[currentFactor.name] ?? '');

	return (
		<div className={styles.Root}>
			<div className="container">
				<div className={styles.Columns}>
					<div className={styles.Column}>
						<h1 className="title">{FactorDisplayNames[currentFactor.name]}</h1>
						<Input label="Count" autoFocus type="number" value={value} minValue={0} onChange={setValue} />
						<div className="buttons">
							<Button
								color="primary"
								size="large"
								disabled={!isNumeric(value)}
								onClick={() => {
									dispatch(updateCarrierInspection(inspection.carrierId, { [currentFactor.name]: value }));
									onAccept(value);
								}}>
								Confirm
							</Button>
							<Button size="large" onClick={onCancel}>
								Cancel
							</Button>
						</div>
					</div>
					<NumPad className={styles.Numpad} onClick={num => setValue(value + num)} onDelete={() => setValue(value.substring(0, value.length - 1))} />
				</div>
			</div>
		</div>
	);
};

export default CountFactor;
