import { DisplayNames } from '../models/factors/names';
import { Corn as CornFactors, Soybeans as SoybeanFactors, Wheat as WheatFactors } from '../models/gradeFactors';
import { CalculateSingleCarrier } from '../models/grading';
import Mycotoxins from '../models/mycotoxins';
import { truncator } from './helpers';
import moment from 'moment';

export const GetInspectionExportData = state => {
	const inspectionId = state.currentInspection.id;
	const inspection = state.inspections.byId[inspectionId];

	const filteredCarrierIds = state.currentInspection.carriers.allIds.filter((id) => state.currentInspection.carriers.byId[id]?.inspectionId === inspectionId);
	const filteredRexIds = state.currentInspection.rex.allIds.filter((id) => state.currentInspection.rex.byId[id]?.inspectionId === inspectionId);
	const filteredOffIds = state.currentInspection.off.allIds.filter((id) => state.currentInspection.off.byId[id]?.inspectionId === inspectionId);

	const carriers = { allIds: filteredCarrierIds, byId: filteredCarrierIds.reduce((a, b) => ({ ...a, [b]: state.currentInspection.carriers.byId[b] }), {}) };
	const rex = { allIds: filteredRexIds, byId: filteredRexIds.reduce((a, b) => ({ ...a, [b]: state.currentInspection.rex.byId[b] }), {}) };
	const off = { allIds: filteredOffIds, byId: filteredOffIds.reduce((a, b) => ({ ...a, [b]: state.currentInspection.off.byId[b] }), {}) };

	const location = state.locations.byId[inspection.locationValue];

	const factors = getCommodityFactors(inspection.commodityGroup);
	const calculatedCarriers = calculateCarriers(carriers, rex, inspection, factors);
	const calculatedRex = calculateCarriers(rex, null, inspection, factors);
	const calculatedOff = calculateCarriers(off, rex, inspection, factors);
	const diffedCarriers = diffInspections(calculatedCarriers, calculatedRex, factors);
	const averages = calculateAverages(factors, diffedCarriers);

	let reducedCarriers = inspectionsReducer(diffedCarriers);
	let reducedRex = inspectionsReducer(calculatedRex);
	let reducedOff = inspectionsReducer(calculatedOff);

	reducedRex.sort((a, b) => a.orderId - b.orderId || a.creationDate?.toDate() - b.creationDate?.toDate());
	reducedOff.sort((a, b) => a.orderId - b.orderId || a.creationDate?.toDate() - b.creationDate?.toDate());
	reducedCarriers.sort((a, b) => a.orderId - b.orderId);

	if (inspection.sampleGrouping === '5-Car Average') {
		reducedCarriers = calculateXCarAverages(5, reducedCarriers, factors);
	} else if (inspection.sampleGrouping === '5-Car Composite') {
		reducedCarriers = mapCompositeValues(reducedCarriers);
		reducedOff = mapCompositeValues(reducedOff);
		reducedRex = mapCompositeValues(reducedRex);
	}

	const mycotoxins = ['afla', 'don', 'zear', 'fumn'].reduce((acc, myco) => {
		return inspection[myco] === true ? [...acc, myco] : acc;
	}, []);

	const time = moment(inspection.departureTime).format('YYYY_M_D');
	const fileName = `${location.location}_(${location.company})_${time}_${inspection.inspector}`
		.replace(',', '')
		.replace(' ', '_')
		.toUpperCase();

	return {
		carriers: reducedCarriers.filter(c => c.isComplete || c.isAverage),
		rex: reducedRex,
		off: reducedOff,
		averages,
		mycotoxins,
		inspectionId: state.currentInspection.id,
		...inspection,
		company: location.company,
		location: location.location,
		arrivalTime: moment(inspection.arrivalTime?.toDate()).format('M/D/YYYY h:mm a'),
		departureTime: moment(inspection.departureTime?.toDate()).format('M/D/YYYY h:mm a'),
		completionDate: moment(inspection.departureTime?.toDate()).format('MM/DD/YYYY'),
		email: location.email || [],
		displayFactors: factors ? factors.calculation.display : [],
		fileName
	};
};

export const GetClientEmailData = (id, state) => {
	const inspection = state.inspections.byId[id];
	const location = state.locations.byId[inspection.locationValue];

	const time = moment(inspection.departureTime).format('YYYY_M_D');
	const fileName = `${location.location}_(${location.company})_${time}_${inspection.inspector}`
		.replace(',', '')
		.replace(' ', '_')
		.toUpperCase();

	return {
		inspectionId: id,
		company: location.company,
		location: location.location,
		completionDate: moment(inspection.departureTime).format('MM/DD/YYYY'),
		email: location.email,
		fileName
	};
};

export const getCommodityFactors = commodityGroup => {
	if (commodityGroup === 'Corn') return CornFactors;
	else if (commodityGroup === 'Soybeans') return SoybeanFactors;
	else if (commodityGroup === 'Wheat') return WheatFactors;
	else return null;
};

const diffInspections = (carriers, rex) => {
	return Object.keys(carriers).reduce((acc, id) => {
		let carrier = Object.assign({}, carriers[id]);
		if (carrier.rexId && rex[carrier.rexId]) {
			// TODO: Clean this up?
			const diff = carrier.changed || {};
			const mycoDiff = Object.keys(Mycotoxins).reduce((acc2, id2) => {
				if (carrier[id2] && rex[carrier.rexId][id2] && carrier[id2].timestamp !== rex[carrier.rexId][id2].timestamp) return { ...acc2, [id2]: true };
				else return acc2;
			}, {});
			Object.assign(carrier, { diff, mycoDiff });
			Object.assign(rex[carrier.rexId], { diff, mycoDiff });
		} else Object.assign(carrier, { diff: {}, mycoDiff: {} });
		return { ...acc, [id]: carrier };
	}, {});
};

const calculateAverages = (factors, inspections) => {
	if (!factors) return {};
	return factors.calculation.averages.reduce(
		(acc, key) => {
			let count = 0;
			const sum = Object.keys(inspections).reduce(function (a, b) {
				let val = inspections[b][key];
				if (val === undefined) return a;

				val = parseFloat(val);
				if (isNaN(val)) return a;

				count++;
				return a + val;
			}, 0);

			if (count === 0) return acc;

			return { ...acc, [key]: truncator(sum / count, 2).toFixed(1) };
		},
		{ isAverage: true }
	);
};

const inspectionsReducer = inspections => {
	return Object.keys(inspections).map(id => ({ ...inspections[id], id: id }));
};

const calculateCarriers = (carriers, rex, inspection, factors) => {
	return carriers.allIds.reduce((acc, id) => {
		const result = CalculateSingleCarrier(carriers.byId[id], carriers.byId[id].rexId ? rex.byId[carriers.byId[id].rexId] : null, inspection, factors);
		if (result.special) result.grade = result.grade + ', ' + result.special;

		let notes = [];
		if (result.rexId && result.offId) notes.push('RELOAD & REX');
		else if (result.offId) notes.push('RELOAD');
		else if (result.rexId) notes.push('REX');

		const clientNotes = Object.keys({ ...result.sampleValuesClient, ...result.specialValuesClient }).reduce((acc, f) => {
			if (factors[f].type === 'select') return [...acc, `${DisplayNames[f]}: ${result[f].join('/')}`];
			else if (factors[f].type === 'percent') return [...acc, `${DisplayNames[f]}: ${result[f]} %`];
			else return [...acc, `${DisplayNames[f]}: ${result[f]}`];
		}, []);

		const allNotes = Object.keys({ ...result.sampleValues, ...result.specialValues }).reduce((acc, f) => {
			if (factors[f].type === 'select') return [...acc, `${DisplayNames[f]}: ${result[f].join('/')}`];
			else if (factors[f].type === 'percent') return [...acc, `${DisplayNames[f]}: ${result[f]} %`];
			else return [...acc, `${DisplayNames[f]}: ${result[f]}`];
		}, []);
		if (result.notes) allNotes.push(result.notes);

		return { ...acc, [id]: { ...result, notes: [...notes, ...allNotes].join(', '), clientNotes: [...notes, ...clientNotes].join(', ') } };
	}, {});
};

const calculateXCarAverages = (x, carriers, factors) => {
	const nodes = [];
	const results = [];

	carriers.forEach((val, i) => {
		const pos = parseInt(i / x, 10);

		if (!nodes[pos]) {
			nodes[pos] = [];
		}

		nodes[pos].push(val);
	});

	nodes.forEach((val, i) => {
		const avg = calculateAverages(factors, val);
		const row = i * x;
		results.push(...val, CalculateAverageCarrier(avg, factors, `${row + 1}-${row + val.length}`));
	});

	return results;
};

const CalculateAverageCarrier = (carrier, factors, row) => ({
	...carrier,
	orderId: row,
	isInfoComplete: carrier.carNumber !== '' && carrier.netWeight !== '' && carrier.inspector !== '', //TODO: Do I need this?
	isGraded: !factors.calculation.grade.some(f => !carrier[f])
});

const mapCompositeValues = array =>
	array.map(i => {
		if (i.composite && !i.netWeight && !i.carNumber) {
			return {
				...i,
				netWeight: Object.keys(i.composite)
					.map(d => parseInt(i.composite[d]?.netWeight).toLocaleString())
					.join('\n'),
				carNumber: Object.keys(i.composite)
					.map(d => i.composite[d]?.carNumber)
					.join('\n')
			};
		} else return i;
	});
