import React from 'react';
import { connect } from 'react-redux';
import { setFactorScroll, updateCarrierFactors } from 'Actions';
import FactorDisplayNames from '../models/factors/names';
import { getInspection, getCarrierInspection, getFactors } from 'Selectors';
import { calculatePercentMulti } from '../lib/helpers';
import without from 'lodash/without';
import { Button, DecimalInput, Input } from 'Controls';
import styles from './MultiFactor.module.css';

class MultiFactor extends React.Component {
	constructor(props) {
		super(props);

		const carrier = props.currentCarrier.carrier;
		const currentFactor = props.currentFactor.factor;
		const isRex = carrier.rexId != null;

		const changed = props.currentCarrier.calculatedCarrier.changed ?? {};
		const changedKeys = Object.keys(changed).filter(k => changed[k] === true);

		const factors = currentFactor.factors.reduce((acc, f) => {
			const factor = props.factors[f];
			let value = carrier[f];

			if (isRex && !changedKeys.includes(f)) {
				value = null;
			}

			if (factor.type === 'percent') {
				const fWeight = 'w' + factor.sampleSize;
				let total = carrier[fWeight];

				if (isRex && !changedKeys.includes(fWeight)) {
					total = null;
				}

				return {
					...acc,
					[f]: value || '0.00',
					[fWeight]: total || ''
				};
			} else if (factor.type === 'select') {
				return { ...acc, [f]: value || [] };
			} else {
				return { ...acc, [f]: value || '' };
			}
		}, {});

		const extras = currentFactor.extras?.reduce((acc, f) => ({ ...acc, [f]: carrier[f] || '' }), {});

		this.state = {
			factors,
			extras,
			isRex
		};
	}

	render() {
		const factorData = this.props.factors;

		return (
			<div className={styles.Root}>
				<div className="container">
					<h1 className="title">{FactorDisplayNames[this.props.currentFactor.name]}</h1>
					<div className="columns">
						<div className="column">
							{this.props.currentFactor.factor.factors.map((f, index) => {
								if (factorData[f].type === 'percent') {
									const percent = calculatePercentMulti(
										this.state.factors[f],
										this.state.factors['w' + this.props.factors[f].sampleSize],
										factorData[f].truncate || 2
									);
									return (
										<React.Fragment key={index}>
											<div className="field">
												<label className="label">Total Grams</label>
												<div className="control">
													<input
														className="input"
														type="number"
														value={this.state.factors['w' + factorData[f].sampleSize]}
														onFocus={e => this.setState({ input: f + '_total' })}
														onChange={e =>
															this.setState({
																factors: {
																	...this.state.factors,
																	['w' + factorData[f].sampleSize]: e.target.value
																}
															})
														}
													/>
												</div>
											</div>
											<div className="field">
												<label className="label">{FactorDisplayNames[f]} Grams</label>
												<div className="control">
													<DecimalInput
														precision={2}
														onFocus={e => this.setState({ input: f + '_part' })}
														value={this.state.factors[f]}
														onChange={e => this.setState({ factors: { ...this.state.factors, [f]: e } })}
													/>
												</div>
											</div>
											<h1 className="title">{percent} %</h1>
										</React.Fragment>
									);
								}
								if (factorData[f].type === 'select') {
									return (
										<React.Fragment key={index}>
											<div className="field">
												<label className="label">{FactorDisplayNames[f]}</label>
												<div className={styles.Buttons}>
													{factorData[f].options.map(opt => (
														<Button
															key={opt}
															size="large"
															color={this.state.factors[f].includes(opt) && 'primary'}
															onClick={() => {
																if (this.state.factors[f].includes(opt))
																	this.setState({ factors: { ...this.state.factors, [f]: without(this.state.factors[f], opt) } });
																else this.setState({ factors: { ...this.state.factors, [f]: [...this.state.factors[f], opt] } });
															}}>
															{opt}
														</Button>
													))}
												</div>
											</div>
										</React.Fragment>
									);
								}
								return (
									<Input
										key={index}
										label={FactorDisplayNames[f]}
										autoFocus={index === 0}
										type="number"
										minValue={0}
										value={this.state.factors[f]}
										onChange={value => this.setState({ factors: { ...this.state.factors, [f]: value } })} />
								);
							})}
							<div className="buttons">
								<Button
									size="large"
									color="primary"
									disabled={Object.keys(this.state.factors).filter(f => !this.state.factors[f] || this.state.factors[f] === '').length > 0}
									onClick={() => {
										const data = { ...this.state.extras, ...this.state.factors };
										this.props.updateCarrierFactors(this.props.currentInspection.carrierId, data);
										this.props.onAccept && this.props.onAccept(data);
									}}>
									Confirm
								</Button>
								<Button size="large" onClick={() => this.props.onCancel()}>
									Cancel
								</Button>
							</div>
						</div>
						{this.props.currentFactor.factor.extras && (
							<div className="column">
								{this.props.currentFactor.factor.extras?.map((f,index) => (
									<div key={index} className={styles.Horizontal}>
										<label className="label">{FactorDisplayNames[f]}</label>
										<input
											className="input"
											type="number"
											value={this.state.extras[f]}
											onChange={e => this.setState({ extras: { ...this.state.extras, [f]: e.target.value } })}
										/>
									</div>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		currentInspection: getInspection(state),
		currentCarrier: getCarrierInspection(state),
		factors: getFactors(state),
		currentFactor: state.currentFactor
	};
};

export default connect(mapStateToProps, {
	updateCarrierFactors,
	setFactorScroll
})(MultiFactor);
