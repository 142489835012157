import React from 'react';
import { connect, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { updateCarrierInspection, changeCarrierView, newCarrierInspection, setCurrentFactor, setCurrentCarrier, changeInspectionView } from 'Actions';
import { getInspection, getFactorResults, getCarrierInspection } from 'Selectors';
import GradeFactorModels from 'models/gradeFactorDisplay';
import FactorDisplayNames from '../models/factors/names';
import { FactorsList, CarrierHeader } from 'Components';
import { Button } from 'Controls';
import styles from './ConfirmCarrier.module.css';

const ConfirmCarrier = ({ currentInspection, currentCarrier, specialValues, sampleValues }) => {
	const dispatch = useDispatch();
	const { inspection, id, carrierCount, carrierId, carriers } = currentInspection;
	const { calculatedCarrier, carrier } = currentCarrier;
	const factors = GradeFactorModels[inspection.commodityGroup];

	return (
		<div className={styles.Root}>
			<CarrierHeader>
				<Button size="large" onClick={() => dispatch(changeCarrierView('gradeFactors'))}>
					Back
				</Button>
			</CarrierHeader>
			<div className={styles.Info}>
				<table className={clsx('table', styles.Table)}>
					<thead>
						<tr>
							<th>ID</th>
							{inspection.lotType === 'Container' && (
								<>
									<th>Booking #</th>
									<th>Seal #</th>
								</>
							)}
							<th>Weight</th>
							<th>Grade</th>
							<th>Sample Factors</th>
							<th>Special Factors</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								{inspection.sampleGrouping !== '5-Car Composite' && carrier.carNumber}
								{inspection.sampleGrouping === '5-Car Composite' &&
									carrier.composite &&
									Object.keys(carrier.composite).map(i => <div>{carrier.composite[i]?.carNumber}</div>)}
							</td>
							{inspection.lotType === 'Container' && (
								<>
									<td>{carrier.bookingNumber}</td>
									<td>{carrier.sealNumber}</td>
								</>
							)}
							<td>
								{inspection.sampleGrouping !== '5-Car Composite' && carrier.netWeight}
								{inspection.sampleGrouping === '5-Car Composite' &&
									carrier.composite &&
									Object.keys(carrier.composite).map(i => <div>{carrier.composite[i]?.netWeight}</div>)}
							</td>
							<td>
								<div>{calculatedCarrier['grade']}</div>
								<div>{calculatedCarrier['special']}</div>
							</td>
							<td>
								<FactorsList data={sampleValues} />
							</td>
							<td>
								<FactorsList data={specialValues} />
							</td>
						</tr>
					</tbody>
				</table>
				<div className={styles.Columns}>
					{Object.keys(factors).map(key => (
						<div key={key} className={styles.Column}>
							<Button
								fullWidth
								size="large"
								color={factors[key].isCalculated ? 'warning' : 'info'}
								onClick={() => {
									if (factors[key].isCalculated) {
										dispatch(setCurrentFactor(key, factors[key]));
										dispatch(changeCarrierView('calcFactor'));
									}
								}}>
								{FactorDisplayNames[key]}
							</Button>
							<Button fullWidth size="large" className={styles.TallButton}>
								{calculatedCarrier[key]}
							</Button>
						</div>
					))}
				</div>
			</div>

			<div className={styles.ConfirmButton}>
				<Button
					fullWidth
					autoFocus
					color="success"
					size="large"
					className={styles.TallButton}
					onClick={() => {
						dispatch(updateCarrierInspection(carrierId, { isComplete: true, inspectionDate: new Date() }));

						if (carrierCount === calculatedCarrier['orderId']) {
							dispatch(newCarrierInspection(id, carrierCount + 1, carrier['inspector']));
						} else {
							const nextId = carriers.allIds.find(el => carriers.byId[el].orderId === calculatedCarrier['orderId'] + 1);
							if (nextId && !carriers.byId[nextId].isComplete) {
								if (!carriers.byId[nextId].inspector) {
									dispatch(updateCarrierInspection(nextId, { inspector: carrier['inspector'] }));
								}
								dispatch(setCurrentCarrier(nextId));
							} else {
								dispatch(changeInspectionView('spreadsheet'));
							}
						}
					}}>
					Confirm
				</Button>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	const factorResults = getFactorResults(state);
	return {
		currentInspection: getInspection(state),
		currentCarrier: getCarrierInspection(state),
		sampleValues: factorResults.sample,
		specialValues: factorResults.special
	};
};

export default connect(mapStateToProps)(ConfirmCarrier);
