import React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Transfer } from 'antd';
import { getLocations } from 'Selectors';
import { Button, Dialog, DialogHeader, DialogContent, DialogFooter, FieldLabel, Input, Password } from 'Controls';
import styles from './Admin.module.css';

const EditUserDialog = ({ onAccept, onCancel, isSelf, isEditing, ...props }) => {
	const [email, setEmail] = React.useState(props.email ?? '');
	const [name, setName] = React.useState(props.displayName ?? '');
	const [role, setRole] = React.useState(props.role ?? 'Inspector');
	const [locations, setLocations] = React.useState(props.locations ?? []);
	const [password, setPassword] = React.useState('');
	const [autoPassword, setAutoPassword] = React.useState(false);

	const locationList = useSelector(state => getLocations(state).list);

	return (
		<Dialog>
			<DialogHeader title={`${isEditing ? 'Edit' : 'Add'} User`} onClose={onCancel} />
			<DialogContent>
				<Input label="Email Address" autoFocus disabled={isEditing} value={email} onChange={val => setEmail(val)} />
				{!isEditing && (
					<FieldLabel label="Password">
						<Password disabled={autoPassword} value={password} onChange={e => setPassword(e.target.value)} />
						<Checkbox
							checked={autoPassword}
							onChange={e => {
								setAutoPassword(!autoPassword);
								setPassword('');
							}}>
							Auto Generate
						</Checkbox>
					</FieldLabel>
				)}
				<Input label="Display Name" value={name} onChange={val => setName(val)} />
				<FieldLabel label="Role">
					<div className="select">
						<select disabled={isSelf} name="role" value={role} onChange={e => setRole(e.target.value)}>
							<option key="admin" value="Admin">
								Admin
							</option>
							<option key="cert" value="Certificate">
								Certificate
							</option>
							<option key="client" value="Client">
								Client
							</option>
							<option key="insp" value="Inspector">
								Inspector
							</option>
						</select>
					</div>
				</FieldLabel>
				{role === 'Client' && (
					<FieldLabel label="Locations">
						<Transfer
							showSearch
							className={styles.Transfer}
							rowKey={i => i.locationId}
							dataSource={locationList}
							targetKeys={locations}
							onChange={items => setLocations(items)}
							render={i => i.displayName}
							filterOption={(val, opt) =>
								opt.location.toLowerCase().indexOf(val.toLowerCase()) > -1 || opt.company.toLowerCase().indexOf(val.toLowerCase()) > -1
							}
						/>
					</FieldLabel>
				)}
			</DialogContent>
			<DialogFooter>
				<Button
					color="primary"
					disabled={!email || !name || (!isEditing && !autoPassword && (!password || password?.length < 8))}
					onClick={() => {
						if (isEditing) {
							onAccept({
								displayName: name,
								locations: locations.filter(k => k !== ''),
								role: role
							});
						} else {
							onAccept({
								displayName: name,
								locations: locations.filter(k => k !== ''),
								email: email,
								role: role,
								password: !autoPassword ? password : null
							});
						}
						onCancel();
					}}>
					Accept
				</Button>
				<Button onClick={onCancel}>Cancel</Button>
			</DialogFooter>
		</Dialog>
	);
};

export default EditUserDialog;
