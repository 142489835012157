import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { getAuth, browserSessionPersistence } from 'firebase/auth';
import {  initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from 'firebase/firestore';
import { config } from 'config';

const firebaseApp = initializeApp(config);

const functions = getFunctions(firebaseApp);
const storage = getStorage(firebaseApp);

const auth = getAuth(firebaseApp);
auth.setPersistence(browserSessionPersistence);

const firestore = initializeFirestore(firebaseApp, { localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }) });

export { firebaseApp as firebase, firestore, auth, storage, functions };
