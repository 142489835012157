import React from 'react';
import { Button, Dialog, DialogHeader, DialogContent, DialogFooter } from 'Controls';

const NotesDialog = ({ title, notes, onAccept, onCancel, ...props }) => {
	const [value, setValue] = React.useState(notes ?? '');
	const ref = React.createRef();

	React.useEffect(() => {
		if (ref.current) {
			ref.current.focus();
			ref.current.selectionStart = ref.current.selectionEnd = ref.current.value?.length;
		}
	}, [ref]);

	return (
		<Dialog onClose={onCancel} {...props}>
			<DialogHeader title={title} onClose={onCancel} />
			<DialogContent>
				<textarea autoFocus ref={ref} className="textarea" placeholder="Enter Notes..." value={value} onChange={e => setValue(e.target.value)} />
			</DialogContent>
			<DialogFooter>
				<Button
					color="primary"
					onClick={() => {
						onAccept(value);
						onCancel();
					}}>
					Accept
				</Button>
				<Button onClick={onCancel}>Cancel</Button>
			</DialogFooter>
		</Dialog>
	);
};

export default NotesDialog;
