import React from 'react';
import { Button, Dialog, DialogHeader, DialogContent, DialogFooter } from 'Controls';

const NotifyDialog = ({ title, body, onClose, buttonText, ...props }) => {
	return (
		<Dialog onClose={onClose} {...props}>
			<DialogHeader title={title} onClose={onClose} />
			<DialogContent>
				<p>{body}</p>
			</DialogContent>
			<DialogFooter>
				<Button autoFocus onClick={onClose}>
					{buttonText ?? 'Close'}
				</Button>
			</DialogFooter>
		</Dialog>
	);
};

export default NotifyDialog;
