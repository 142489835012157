import { SET_CURRENT_FACTOR } from '../Actions/types';

const defaultState = { name: null, factor: {}};

const currentFactor = (state = defaultState, action) => {
	switch (action.type) {
		case SET_CURRENT_FACTOR:
			return { name: action.name, factor: action.factor };
		default:
			return state;
	}
};

export default currentFactor;
