import { INIT_AUTH, UPDATE_AUTH, SET_CURRENT_USER } from '../Actions/types';

const defaultState = { data: null, auth: null, isFetching: false };

const user = (state = defaultState, action) => {
	switch (action.type) {
		case INIT_AUTH:
			return state;
		case UPDATE_AUTH:
			if (action.auth) return Object.assign({}, state, { auth: action.auth, isFetching: true });
			else return Object.assign({}, state, { auth: null, data: null });
		case SET_CURRENT_USER:
			return Object.assign({}, state, { data: action.user, isFetching: false });
		default:
			return state;
	}
};

export default user;
