import { createSelector } from 'reselect';
import { DateTimeFormatter } from '../lib/helpers';
import getLocations from './locations';

const getInspections = state => state.archiveInspections;

export default createSelector([getLocations, getInspections], (locations, inspections) => {
	const listInspections = createRows(inspections, inspections.allIds, locations);
	return { inspections: listInspections, isFetching: inspections.isFetching, isLoaded: inspections.isLoaded };
});

const createRows = (inspections, ids, locations) =>
	ids.reduce((acc, id) => {
		const inspection = inspections.byId[id];
		const loc = locations.byId[inspection.locationValue];

		const insp = {
			id,
			location: loc ? loc.displayName : '',
			archivedDate: DateTimeFormatter(inspection.archivedDate),
			departureTime: DateTimeFormatter(inspection.departureTime),
			commodity: `${inspection.commodityType} ${inspection.commodityGroup}`,
			inspector: inspection.inspector,
			docsGenerated: inspection.docsGenerated
		};

		return [...acc, insp];
	}, []);
