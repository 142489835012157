import React from 'react';
import { Select } from 'antd';
import { IsValidEmail } from '../../lib/helpers';
import { Button, Dialog, DialogHeader, DialogContent, DialogFooter, FieldLabel, Input } from 'Controls';

const EditLocationDialog = ({ onAccept, onCancel, isEditing, ...props }) => {
	const [id, setId] = React.useState(props.locationId ?? '');
	const [location, setLocation] = React.useState(props.location ?? '');
	const [company, setCompany] = React.useState(props.company ?? '');
	const [email, setEmail] = React.useState(props.email ?? []);

	return (
		<Dialog>
			<DialogHeader title={`${isEditing ? 'Edit' : 'Add'} Location`} onClose={onCancel} />
			<DialogContent>
				<Input label="Location ID" autoFocus disabled={isEditing} value={id} onChange={val => setId(val)} />
				<Input label="Location" value={location} onChange={val => setLocation(val)} />
				<Input label="Company Name" value={company} onChange={val => setCompany(val)} />
				<FieldLabel label="Email Addresses">
					<Select mode="tags" size="large" value={email} style={{ width: '100%' }} onChange={value => setEmail(value.filter(i => IsValidEmail(i)))} />
				</FieldLabel>
			</DialogContent>
			<DialogFooter>
				<Button
					color="primary"
					disabled={!id || !company || !location}
					onClick={() => {
						onAccept(id, { company, location, email });
						onCancel();
					}}>
					Accept
				</Button>
				<Button onClick={onCancel}>Cancel</Button>
			</DialogFooter>
		</Dialog>
	);
};

export default EditLocationDialog;
