import { createSelector } from 'reselect';
import moment from 'moment';
import getLocations from './locations';
import { DateTimeFormatter } from '../lib/helpers';

const getUserType = state => state.user.data.role;
const getInspections = state => state.inspections;

export default createSelector([getUserType, getLocations, getInspections], (userType, locations, inspections) => {
	if (userType === 'Client') {
		return createRows(inspections, inspections.allIds, locations);
	} else {
		const undeletedInspections = inspections.allIds.filter(i => inspections.byId[i].deleted !== true);
		return createRows(inspections, undeletedInspections, locations);
	}
});

const createRows = (inspections, ids, locations) => {
	return ids.map(id => {
		const inspection = inspections.byId[id];
		const loc = locations.byId[inspection.locationValue];

		const arrivalTime = inspection.arrivalTime?.toDate();
		const departureTime = inspection.departureTime?.toDate();

		const duration = moment(departureTime ? departureTime : Date.now())
			.diff(moment(arrivalTime), 'hours', true)
			.toFixed(1);

		return {
			...inspections.byId[id],
			id: id,
			location: loc ? `${loc.location} (${loc.company})` : '',
			commodity: `${inspection.commodityType} ${inspection.commodityGroup}`,
			duration,
			arrivalTime: DateTimeFormatter(arrivalTime),
			departureTime: DateTimeFormatter(departureTime),
			arrivalDate: moment(arrivalTime).format('MM/DD/YYYY')
		};
	});
};
