import { combineReducers } from 'redux';
import window from './window';
import carrierWindow from './carrierWindow';
import inspectionWindow from './inspectionWindow';

const view = combineReducers({
	window,
	inspectionWindow,
	carrierWindow
});

export default view;
