import { createSelector } from 'reselect';
import getCommodityFactors from './factors';
import { CalculateSingleCarrier } from '../models/grading';

const currentInspectionSelector = state => state.inspections.byId[state.currentInspection.id];
const currentCarrierSelector = state => state.currentInspection.carriers.byId[state.currentInspection.carrierId];
const currentRexCarrierSelector = state => {
	const carrier = state.currentInspection.carriers.byId[state.currentInspection.carrierId];
	return carrier?.rexId != null ? state.currentInspection.rex.byId[carrier.rexId] : null;
};

const getCurrentCarrierInspection = (inspection, carrier, rex, factors) => {
	return {
		carrier: carrier,
		calculatedCarrier: CalculateSingleCarrier(carrier, rex, inspection, factors)
	};
};

export default createSelector([currentInspectionSelector, currentCarrierSelector, currentRexCarrierSelector, getCommodityFactors], getCurrentCarrierInspection);
