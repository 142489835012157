import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { getInspection } from 'Selectors';
import { newCarrierInspection, changeCarrierView, setFactorScroll } from 'Actions';
import { Carrier, CalcFactor, ConfirmCarrier, GradeFactors, CompositeCarrier } from 'Components';
import { Loading, Button } from 'Controls';
import styles from './CarrierView.module.css';

const CarrierWindow = ({ carrierWindow, currentFactor, currentInspection }) => {
	const dispatch = useDispatch();
	const { currentCarrier, id, carrierCount, inspection, carriers, carrierId } = currentInspection;

	if (carriers.isAdding) {
		return <Loading message="Creating Carrier" />;
	}

	if (carrierId && currentCarrier === undefined) {
		return <Loading />;
	}
	
	if (currentCarrier === undefined) {
		const inspector = carrierCount === 0 ? inspection['inspector'] : undefined;

		return (
			<div className={styles.Container}>
				<div className={styles.Header}>
					<h2 className="title">{inspection.sampleGrouping === '5-Car Composite' ? 'Composite' : 'Carrier'} # {carrierCount + 1}</h2>
				</div>
				<div className={styles.Centered}>
					<Button size="large" color="primary" onClick={() => dispatch(newCarrierInspection(id, carrierCount + 1, inspector))}>
						Start New Carrier
					</Button>
				</div>
			</div>
		);
	}

	return (
		<>
			{carrierWindow === 'carrier' && inspection.sampleGrouping === '5-Car Composite' && <CompositeCarrier />}
			{carrierWindow === 'carrier' && inspection.sampleGrouping !== '5-Car Composite' && <Carrier />}
			{carrierWindow === 'gradeFactors' && <GradeFactors />}
			{carrierWindow === 'confirm' && <ConfirmCarrier />}
			{carrierWindow === 'calcFactor' && (
				<CalcFactor
					onCancel={() => dispatch(changeCarrierView('gradeFactors'))}
					onAccept={value => {
						dispatch(setFactorScroll(currentFactor.name, value));
						dispatch(changeCarrierView('gradeFactors'));
					}}
				/>
			)}
		</>
	);
};

export default connect(state => ({
	carrierWindow: state.view.carrierWindow,
	currentFactor: state.currentFactor,
	currentInspection: getInspection(state)
}))(CarrierWindow);
