import { createSelector } from 'reselect';

const locationsSelector = state => state.locations;

const getLocations = locations => {
	const byId = locations.allIds.reduce(
		(acc, loc) => ({
			...acc,
			[loc]: {
				...locations.byId[loc],
				locationId: loc.toString(),
				displayName: `${locations.byId[loc].location} (${locations.byId[loc].company})`
			}
		}),
		{}
	);

	const list = Object.keys(byId)
		.map(o => byId[o])
		.filter(i => i.deleted !== true);

	const deleted = Object.keys(byId)
		.map(o => byId[o])
		.filter(i => i.deleted === true);

	return {
		isFetching: locations.isFetching,
		byId,
		list,
		deleted,
		all: [...list, ...deleted]
	};
};

export default createSelector([locationsSelector], getLocations);
