import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { undeleteInspection } from 'Actions';
import { CONFIRM_RESTORE_DELETED_INSP } from 'lib/constants';
import { StringComparer, TimeComparer, filterCollection } from 'lib/helpers';
import { getDeletedInspections } from 'Selectors';
import { ConfirmDialog } from 'Components/Dialogs';
import { Menu, Table } from 'antd';
import { MenuButton, Search, Modal } from 'Controls';
import styles from './Admin.module.css';

const { Column } = Table;

const filterOptions = [
	{ text: 'Corn', value: 'Corn' },
	{ text: 'Soybeans', value: 'Soybeans' },
	{ text: 'Wheat', value: 'Wheat' }
];

const ManageDeletions = () => {
	const dispatch = useDispatch();

	const [restore, setRestore] = React.useState(null);
	const [sorter, setSorter] = React.useState({ columnKey: 'deletedDate', order: 'descend' });
	const [filters, setFilters] = React.useState({});
	const [textFilter, setTextFilter] = React.useState('');

	const inspectionsDisplay = useSelector(getDeletedInspections);

	const handleChange = (pagination, filters, sorter) => {
		setSorter(sorter);
		setFilters(filters);
	};

	const columnSort = key => sorter.columnKey === key && sorter.order;

	return (
		<div className={styles.Container}>
			<div className={styles.Topbar}>
				<Search placeholder="Search Deletions" value={textFilter} onChange={e => setTextFilter(e.target.value)} />
			</div>
			<Table
				rowKey="id"
				size="small"
				showSorterTooltip={false}
				loading={inspectionsDisplay.isFetching}
				dataSource={filterCollection(textFilter, inspectionsDisplay.inspections)}
				scroll={{ scrollToFirstRowOnChange: true }}
				pagination={{ position: ['none', 'bottomCenter'], showSizeChanger: true, size: 'default', defaultPageSize: 20, showTotal: total => `${total} Inspections` }}
				onChange={handleChange}>
				<Column title="Deleted" key="deletedDate" dataIndex="deletedDate" sorter={TimeComparer('deletedDate')} sortOrder={columnSort('deletedDate')} />
				<Column title="Location" key="location" dataIndex="location" sorter={StringComparer('location')} sortOrder={columnSort('location')} />
				<Column
					title="Commodity"
					key="commodity"
					dataIndex="commodity"
					sorter={StringComparer('commodity')}
					sortOrder={columnSort('commodity')}
					filters={filterOptions}
					filteredValue={filters.commodity}
					onFilter={(value, record) => record.commodity.endsWith(value)}
				/>
				<Column title="Inspector" key="inspector" dataIndex="inspector" sorter={StringComparer('inspector')} sortOrder={columnSort('inspector')} />
				<Column
					title="Departure"
					key="departureTime"
					dataIndex="departureTime"
					sorter={TimeComparer('departureTime')}
					sortOrder={columnSort('departureTime')}
				/>
				<Column
					key="actions"
					dataIndex="id"
					width={1}
					render={cellData => (
						<MenuButton>
							<Menu.Item onClick={() => setRestore(cellData)}>Restore</Menu.Item>
						</MenuButton>
					)}
				/>
			</Table>
			<Modal show={restore}>
				<ConfirmDialog
					title="Restore Inspection"
					body={CONFIRM_RESTORE_DELETED_INSP}
					buttonText="Restore"
					onCancel={() => setRestore(false)}
					onAccept={() => dispatch(undeleteInspection(restore))}
				/>
			</Modal>
		</div>
	);
};

export default ManageDeletions;
