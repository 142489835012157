import eigi from 'logos/eigi.png';
import test from 'logos/test.png';

const logoConfigs = {
	test: {
		src: test,
		height: 250,
		width: 250
	},
	prod: {
		src: eigi,
		height: 250,
		width: 250
	}
};

const firebaseConfigs = {
	test: {
		apiKey: "AIzaSyCmujgDIh_uoIn7muM5fJEleHr2iRG8dGw",
		authDomain: "inspectors-link.firebaseapp.com",
		databaseURL: "https://inspectors-link.firebaseio.com",
		projectId: "inspectors-link",
		storageBucket: "inspectors-link.appspot.com",
		messagingSenderId: "505136904135",
		appId: "1:505136904135:web:41288c395c7e896671cbe2"
	},
	prod: {
		apiKey: 'AIzaSyBtt4sVrEh9YuM90Mnk3t6jlD5Uh2jcL9g',
		authDomain: 'inspectorslink-prod.firebaseapp.com',
		databaseURL: 'https://inspectorslink-prod.firebaseio.com',
		projectId: 'inspectorslink-prod',
		storageBucket: 'inspectorslink-prod.appspot.com',
		messagingSenderId: '537198912992'
	}
};

export const config = firebaseConfigs[process.env.REACT_APP_ENV_NAME] || firebaseConfigs['test'];
export const logo = logoConfigs[process.env.REACT_APP_ENV_NAME] || logoConfigs['test'];
