export const Corn = {
	grade: {},
	commodity: {},
	calculation: {
		grade: ['testWeight', 'damage', 'bcfm', 'heat'],
		averages: ['testWeight', 'moisture', 'damage', 'heat', 'bcfm', 'ocol'],
		mixed: ['ocol'],
		display: ['testWeight', 'moisture', 'damage', 'heat', 'bcfm', 'ocol'],
		sample: ['ston', 'glass', 'crot', 'cstb', 'fsub', 'cbur', 'anfl', 'odor', 'htg'],
		sampleDisplay: ['ston', 'glass', 'crot', 'cstb', 'fsub', 'cbur', 'anfl', 'odor', 'htg'],
		special: [
			'flint',
			'flintDent',
			'infested',
			'lw',
			'oli',
			'infested_b1',
			'infested_b2',
			'infested_b3',
			'infested_b4',
			'infested_b5',
			'infested_b6',
			'infested_b7',
			'infested_b8',
			'infested_b9',
			'infested_b10',
			'waxy'
		],
		markSpecial: ['flint', 'flintDent', 'infested', 'waxy'],
		specialDisplay: ['flint', 'flintDent', 'infested', 'waxy']
	},
	testWeight: {
		comparer: '>=',
		value: [56, 54, 52, 49, 46]
	},
	moisture: {},
	damage: {
		type: 'percent',
		sampleSize: '250',
		truncate: 2,
		comparer: '<=',
		value: [3, 5, 7, 10, 15]
	},
	bcfm: {
		type: 'percent',
		sampleSize: '1050',
		truncate: 2,
		comparer: '<=',
		value: [2, 3, 4, 5, 7]
	},
	heat: {
		type: 'percent',
		sampleSize: '250',
		truncate: 2,
		comparer: '<=',
		value: [0.1, 0.2, 0.5, 1, 3]
	},
	ocol: {
		allowEmpty: true,
		type: 'percent',
		sampleSize: '250',
		truncate: 2,
		mixed: {
			comparer: '>',
			group: ['Yellow', 'White'],
			value: [5.0, 2.0]
		}
	},
	// SAMPLE FACTORS
	ston: {
		type: 'percent',
		sampleSize: '1050',
		truncate: 2,
		value: 0.15,
		comparer: '>='
	},
	glass: {
		type: 'count',
		value: 2,
		comparer: '>='
	},

	crot: {
		type: 'count',
		value: 3,
		comparer: '>='
	},
	cstb: {
		type: 'count',
		value: 2,
		comparer: '>='
	},
	fsub: {
		type: 'count',
		value: 4,
		comparer: '>='
	},
	cbur: {
		type: 'count',
		value: 8,
		comparer: '>='
	},
	anfl: {
		type: 'percent',
		sampleSize: '1050',
		truncate: 3,
		value: 0.2,
		comparer: '>'
	},
	odor: {
		type: 'select',
		options: ['Musty', 'Sour', 'COFO']
	},
	htg: {
		type: 'select',
		options: ['Present']
	},
	// SPECIAL FACTORS
	flint: {
		type: 'percent',
		sampleSize: '1050',
		value: 95.0,
		comparer: '>=',
		truncate: 1,
		ignoreWith: ['flintDent']
	},
	flintDent: {
		type: 'percent',
		sampleSize: '1050',
		truncate: 1,
		value: [5, 95],
		comparer: '><',
		display: { prefix: 'Flint and Dent, Flint Corn ', suffix: ' percent' }
	},
	infested: {
		type: 'multi',
		require: 'any',
		factors: ['lw', 'oli'],
		values: [
			[2, null],
			[1, 5],
			[null, 10]
		],
		comparers: [
			['>=', null],
			['>=', '>='],
			[null, '>=']
		],
		extras: [
			'infested_b1',
			'infested_b2',
			'infested_b3',
			'infested_b4',
			'infested_b5',
			'infested_b6',
			'infested_b7',
			'infested_b8',
			'infested_b9',
			'infested_b10'
		]
	},
	lw: { type: 'count' },
	oli: { type: 'count' },
	waxy: {
		type: 'count',
		value: 95,
		comparer: '>='
	},
	//EXTRA
	infested_b1: { type: 'count' },
	infested_b2: { type: 'count' },
	infested_b3: { type: 'count' },
	infested_b4: { type: 'count' },
	infested_b5: { type: 'count' },
	infested_b6: { type: 'count' },
	infested_b7: { type: 'count' },
	infested_b8: { type: 'count' },
	infested_b9: { type: 'count' },
	infested_b10: { type: 'count' }
};

export const Wheat = {
	calculation: {
		grade: ['testWeight', 'heat', 'damage', 'fm', 'sbk', 'defects', 'cc', 'wocl'],
		averages: ['testWeight', 'moisture', 'damage', 'heat', 'fm', 'sbk', 'defects', 'wocl', 'cc', 'dockage'],
		mixed: ['wocl', 'cc'],
		display: ['dockage', 'testWeight', 'moisture', 'damage', 'heat', 'fm', 'sbk', 'defects', 'wocl', 'cc'],
		sample: ['ston', 'glass', 'crot', 'cstb', 'fsub', 'anfl', 'odor', 'htg', 'idk', 'fsubTotal'],
		sampleDisplay: ['stonWeight', 'stonCount', 'glass', 'crot', 'cstb', 'fsub', 'anfl', 'odor', 'htg', 'idk'],
		special: [
			'tret',
			'ggb',
			'dgb',
			'garlicky',
			'infested',
			'lw',
			'oli',
			'infested_b1',
			'infested_b2',
			'infested_b3',
			'infested_b4',
			'infested_b5',
			'infested_b6',
			'infested_b7',
			'infested_b8',
			'infested_b9',
			'infested_b10',
			'erg',
			'lsmOdor',
			'sbal',
			'lsm',
			'smut'
		],
		markSpecial: ['tret', 'garlicky', 'infested', 'erg', 'lsm'],
		specialDisplay: ['tret', 'garlicky', 'infested', 'erg', 'lsm', 'smut']
	},
	testWeight: {
		comparer: '>=',
		value: [60, 58, 56, 54, 51]
	},
	moisture: {},
	dockage: {
		type: 'percent',
		sampleSize: '1050',
		truncate: 2
	},
	sbk: {
		type: 'percent',
		sampleSize: '250',
		truncate: 2,
		comparer: '<=',
		value: [3, 5, 8, 12, 20]
	},
	fm: {
		type: 'percent',
		sampleSize: '60',
		truncate: 2,
		comparer: '<=',
		value: [0.4, 0.7, 1.3, 3, 5]
	},
	heat: {
		type: 'percent',
		sampleSize: '60',
		truncate: 2,
		comparer: '<=',
		value: [0.2, 0.2, 0.5, 1, 3]
	},
	damage: {
		type: 'percent',
		sampleSize: '15a',
		truncate: 2,
		comparer: '<=',
		value: [2, 4, 7, 10, 15]
	},
	defects: {
		type: 'composite',
		require: 'all',
		factors: ['damage', 'sbk', 'fm'],
		truncate: 2,
		comparer: '<=',
		value: [3, 5, 8, 12, 20]
	},
	wocl: {
		type: 'percent',
		sampleSize: '15b',
		truncate: 2,
		comparer: '<=',
		value: [3, 5, 10, 10, 10],
		mixed: {
			truncate: 2,
			comparer: '>=',
			group: ['Unclassed'],
			value: [10],
			default: 10
		}
	},
	cc: {
		type: 'percent',
		sampleSize: '15b',
		truncate: 2,
		comparer: '<=',
		value: [1, 2, 3, 10, 10],
		mixed: {
			truncate: 2,
			comparer: '>=',
			group: ['Unclassed', 'Durum'],
			value: [10, 10],
			default: 10
		}
	},
	// SAMPLE FACTORS
	ston: {
		type: 'multi',
		require: 'any',
		factors: ['stonCount', 'stonWeight'],
		values: [
			[3, null],
			[null, 0.1]
		],
		comparers: [
			['>', null],
			[null, '>']
		]
	},
	stonCount: {
		type: 'count'
	},
	stonWeight: {
		type: 'percent',
		truncate: 2,
		sampleSize: '1050'
	},
	glass: {
		type: 'count',
		value: 1,
		comparer: '>='
	},
	crot: {
		type: 'count',
		value: 3,
		comparer: '>='
	},
	cstb: {
		type: 'count',
		value: 2,
		comparer: '>='
	},
	fsub: {
		type: 'count',
		value: 4,
		comparer: '>='
	},
	anfl: {
		type: 'count',
		value: 2,
		comparer: '>='
	},
	odor: {
		type: 'select',
		options: ['Musty', 'Sour', 'COFO']
	},
	htg: {
		type: 'select',
		options: ['Present']
	},
	idk: {
		type: 'count',
		value: 31,
		comparer: '>'
	},
	fsubTotal: {
		type: 'composite',
		truncate: 0,
		value: 5,
		comparer: '>=',
		factors: ['anfl', 'cstb', 'crot', 'glass', 'ston', 'fsub']
	},
	// SPECIAL
	garlicky: {
		type: 'multi',
		require: 'any',
		factors: ['ggb', 'dgb'],
		values: [
			[2, null],
			[null, 2]
		],
		comparers: [
			['>', null],
			[null, '>']
		]
	},
	ggb: { type: 'count' },
	dgb: { type: 'count' },
	infested: {
		type: 'multi',
		require: 'any',
		factors: ['lw', 'oli'],
		values: [
			[0, null],
			[null, 0]
		],
		comparers: [
			['>', null],
			[null, '>']
		],
		extras: [
			'infested_b1',
			'infested_b2',
			'infested_b3',
			'infested_b4',
			'infested_b5',
			'infested_b6',
			'infested_b7',
			'infested_b8',
			'infested_b9',
			'infested_b10'
		]
	},
	oli: { type: 'count' },
	lw: { type: 'count' },
	tret: {
		type: 'select',
		options: ['Scoured', 'Limed', 'Washed', 'Sulfured', 'Other'],
		display: {
			prefix: 'Treated (',
			suffix: ')'
		}
	},
	erg: {
		type: 'percent',
		truncate: 3,
		sampleSize: '1050',
		value: 0.05,
		comparer: '>='
	},
	smut: {
		type: 'multi',
		require: 'any',
		factors: ['sbal'],
		values: [[30]],
		comparers: [['>']]
	},
	lsm: {
		type: 'multi',
		require: 'any',
		factors: ['lsmOdor', 'sbal'],
		values: [[true, [5, 30]]],
		comparers: [['[]', '><=']],
		ignoreWith: ['smut'],
		display: { prefix: 'Light ', suffix: '', name: true }
	},
	lsmOdor: {
		type: 'select',
		options: ['Present']
	},
	sbal: { type: 'count' },
	//EXTRA
	infested_b1: { type: 'count' },
	infested_b2: { type: 'count' },
	infested_b3: { type: 'count' },
	infested_b4: { type: 'count' },
	infested_b5: { type: 'count' },
	infested_b6: { type: 'count' },
	infested_b7: { type: 'count' },
	infested_b8: { type: 'count' },
	infested_b9: { type: 'count' },
	infested_b10: { type: 'count' }
};

export const Soybeans = {
	calculation: {
		grade: ['heat', 'damage', 'fm', 'splits'],
		averages: ['testWeight', 'moisture', 'damage', 'heat', 'fm', 'splits', 'sboc'],
		mixed: ['sboc'],
		display: ['testWeight', 'moisture', 'damage', 'heat', 'fm', 'splits', 'sboc'],
		sample: ['ston', 'glass', 'crot', 'cstb', 'fsub', 'anfl', 'odor', 'htg', 'fsubTotal'],
		sampleDisplay: ['stonCount', 'stonWeight', 'glass', 'crot', 'cstb', 'fsub', 'anfl', 'odor', 'htg'],
		special: [
			'ggb',
			'dgb',
			'garlicky',
			'infested',
			'lw',
			'oli',
			'infested_b1',
			'infested_b2',
			'infested_b3',
			'infested_b4',
			'infested_b5',
			'infested_b6',
			'infested_b7',
			'infested_b8',
			'infested_b9',
			'infested_b10',
			'pms'
		],
		markSpecial: ['garlicky', 'infested', 'pms'],
		specialDisplay: ['garlicky', 'infested', 'pms']
		// other: ['stinkBug']
	},
	testWeight: {
		comparer: '>=',
		value: [56, 54, 52, 49]
	},
	moisture: {},
	'w125-fines': {
		type: 'mod',
		base: 'w125',
		operator: '-',
		factor: 'fmFine',
		truncate: 1
	},
	fmFine: {
		type: 'percent',
		sampleSize: '125',
		truncate: 2,
		round: false
	},
	fmCoarse: {
		type: 'percent',
		sampleSize: '1050',
		truncate: 2,
		round: false
	},
	fm: {
		type: 'composite',
		require: 'all',
		truncate: 2,
		comparer: '<=',
		value: [1, 2, 3, 5],
		factors: ['fmCoarse', 'fmFine']
	},
	damage: {
		type: 'percent',
		sampleSize: '125-fines', // NOT 250 ?
		truncate: 2,
		comparer: '<=',
		value: [2, 3, 5, 8]
	},
	heat: {
		type: 'percent',
		sampleSize: '125-fines',
		truncate: 2,
		comparer: '<=',
		value: [0.2, 0.5, 1, 3]
	},
	splits: {
		type: 'percent',
		sampleSize: '125-fines',
		truncate: 2,
		comparer: '<=',
		value: [10, 20, 30, 40]
	},
	sboc: {
		allowEmpty: true,
		type: 'percent',
		sampleSize: '125-fines',
		truncate: 2,
		comparer: '<=',
		value: [1, 2, 5, 10],
		mixed: {
			truncate: 2,
			comparer: '>=',
			group: ['Yellow'],
			value: [10]
		}
	},
	// SAMPLE FACTORS
	anfl: {
		type: 'count',
		value: 10,
		comparer: '>='
	},
	cstb: {
		type: 'count',
		value: 2,
		comparer: '>='
	},
	crot: {
		type: 'count',
		value: 3,
		comparer: '>='
	},
	glass: {
		type: 'count',
		value: 1,
		comparer: '>='
	},
	ston: {
		type: 'multi',
		require: 'any',
		factors: ['stonCount', 'stonWeight'],
		values: [[4, 0.1]],
		comparers: [['>=', '>']]
	},
	stonCount: {
		type: 'count'
	},
	stonWeight: {
		type: 'percent',
		truncate: 2,
		sampleSize: '1050'
	},
	fsub: {
		type: 'count',
		value: 4,
		comparer: '>='
	},
	odor: {
		type: 'select',
		options: ['Musty', 'Sour', 'COFO']
	},
	htg: {
		type: 'select',
		options: ['Present']
	},
	fsubTotal: {
		type: 'composite',
		truncate: 0,
		value: 11,
		comparer: '>=',
		factors: ['anfl', 'cstb', 'crot', 'glass', 'stonCount', 'fsub']
	},
	// SPECIAL
	garlicky: {
		type: 'multi',
		require: 'any',
		factors: ['ggb', 'dgb'],
		values: [
			[5, null],
			[null, 5]
		],
		comparers: [
			['>=', null],
			[null, '>=']
		]
	},
	ggb: { type: 'count' },
	dgb: { type: 'count' },
	infested: {
		type: 'multi',
		require: 'any',
		factors: ['lw', 'oli'],
		values: [
			[0, null],
			[null, 0]
		],
		comparers: [
			['>', null],
			[null, '>']
		],
		extras: [
			'infested_b1',
			'infested_b2',
			'infested_b3',
			'infested_b4',
			'infested_b5',
			'infested_b6',
			'infested_b7',
			'infested_b8',
			'infested_b9',
			'infested_b10'
		]
	},
	oli: { type: 'count' },
	lw: { type: 'count' },
	pms: {
		type: 'select',
		options: ['Purple/Pink', 'Dirt', 'Pokeberry']
	},
	//EXTRA
	infested_b1: { type: 'count' },
	infested_b2: { type: 'count' },
	infested_b3: { type: 'count' },
	infested_b4: { type: 'count' },
	infested_b5: { type: 'count' },
	infested_b6: { type: 'count' },
	infested_b7: { type: 'count' },
	infested_b8: { type: 'count' },
	infested_b9: { type: 'count' },
	infested_b10: { type: 'count' }
	// OTHER
	// stinkBug: {
	// 	type: 'select',
	// 	options: ['Present']
	// }
};
