import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCarrierInspection } from 'Actions';
import FactorDisplayNames from '../models/factors/names';
import { getInspection } from 'Selectors';
import without from 'lodash/without';
import { Button } from 'Controls';
import styles from './SelectFactor.module.css';

const SelectFactor = ({ onAccept, onCancel, ...props }) => {
	const dispatch = useDispatch();
	const currentInspection = useSelector(getInspection);
	const currentFactor = useSelector(state => state.currentFactor);

	const [value, setValue] = React.useState(currentInspection.currentCarrier[currentFactor.name] ?? []);

	return (
		<div className={styles.Root}>
			<div className="container">
				<h1 className="title">{FactorDisplayNames[currentFactor.name]}</h1>
				<div className={styles.Buttons}>
					{currentFactor.factor.options.map(opt => (
						<Button
							key={opt}
							size="large"
							className={styles.Button}
							color={value.includes(opt) && 'info'}
							onClick={() => {
								if (value.includes(opt)) setValue(without(value, opt));
								else setValue([...value, opt]);
							}}>
							{opt}
						</Button>
					))}
				</div>
				<div className="buttons">
					<Button
						size="large"
						color="primary"
						onClick={() => {
							dispatch(updateCarrierInspection(currentInspection.carrierId, { [currentFactor.name]: value }));
							onAccept();
						}}>
						Confirm
					</Button>
					<Button size="large" onClick={onCancel}>
						Cancel
					</Button>
				</div>
			</div>
		</div>
	);
};

export default SelectFactor;
