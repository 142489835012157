import { round, truncator } from '../../lib/helpers';
import FactorDisplayNames from '../factors/names';

export const CalculateGrade = (inspection, factors) => {
	if (CheckSampleGrade(inspection, factors)) {
		return 'U.S. Sample Grade';
	}

	let minGrade = 0;
	outer: for (let f of factors.calculation.grade) {
		const factor = factors[f];
		for (let i = minGrade; i < factor.value.length; i++) {
			if (compare[factor.comparer](inspection[f], factor.value[i])) {
				minGrade = i;
				continue outer;
			}
		}
		return 'U.S. Sample Grade';
	}
	return 'U.S. No. ' + (minGrade + 1);
};

export const CalculateSpecialGrade = (inspection, factors) => {
	let specialFactors = GetPositiveSpecialGradeFactors(inspection, factors);

	specialFactors = specialFactors.filter(f => !(factors[f].ignoreWith && specialFactors.some(v => factors[f].ignoreWith.includes(v))));

	const displayFactors = specialFactors.reduce((acc, f) => {
		let display = FactorDisplayNames[f];
		if (factors[f].display) {
			if (factors[f].type === 'select') {
				display = factors[f].display.prefix + inspection[f].join(', ').toLowerCase() + factors[f].display.suffix;
			} else display = factors[f].display.prefix + (factors[f].display.name === true ? display : inspection[f]) + factors[f].display.suffix;
		}
		acc.push(display);
		return acc;
	}, []);

	return displayFactors.join(', ');
};

const GetPositiveSpecialGradeFactors = (inspection, factors) => {
	return factors.calculation.specialDisplay.reduce((acc, f) => {
		if ((factors[f].type === 'multi' && CheckMultiFactor(inspection, factors[f])) || CheckSingleFactor(inspection[f], factors[f])) {
			acc.push(f);
		}
		return acc;
	}, []);
};

export const CheckSampleGrade = (inspection, factors) => {
	for (let f of factors.calculation.sample) {
		if (factors[f].type === 'multi') {
			if (CheckMultiFactor(inspection, factors[f])) {
				return true;
			}
		} else if (CheckSingleFactor(inspection[f], factors[f])) {
			return true;
		}
	}
	return false;
};

export const GetSampleFactorsClient = (inspection, factors) => {
	const results = [];
	for (let f of factors.calculation.sample) {
		if (factors[f].type === 'multi') {
			if (CheckMultiFactor(inspection, factors[f])) {
				results.push(f);
			}
		} else if (CheckSingleFactor(inspection[f], factors[f])) {
			results.push(f);
		}
	}
	return results.reduce((acc, f) => {
		if (factors[f].type === 'select') {
			return { ...acc, [f]: inspection[f].join(', ') };
		} else {
			const val = factors[f].type === 'percent' ? inspection[f] + ' %' : inspection[f];
			return { ...acc, [f]: val };
		}
	}, {});
};

export const GetSpecialFactorsClient = (inspection, factors) => {
	const results = [];
	for (let f of factors.calculation.special) {
		if (factors[f].type === 'multi') {
			if (CheckMultiFactor(inspection, factors[f])) {
				results.push(...factors[f].factors);
			}
		} else if (CheckSingleFactor(inspection[f], factors[f])) {
			results.push(f);
		}
	}
	return results.reduce((acc, f) => {
		if (factors[f].type === 'select') {
			return { ...acc, [f]: inspection[f].join(', ') };
		} else {
			const val = factors[f].type === 'percent' ? inspection[f] + ' %' : inspection[f];
			return { ...acc, [f]: val };
		}
	}, {});
};

export const GetSampleGradeResults = (inspection, factors) => {
	return GetFactorResults(factors.calculation.sampleDisplay, inspection, factors);
};
export const GetSpecialGradeResults = (inspection, factors) => {
	return GetFactorResults(factors.calculation.special, inspection, factors);
};
export const GetOtherGradeResults = (inspection, factors) => {
	return GetFactorResults(factors.calculation.other, inspection, factors);
};
export const GetFactorResults = (list, inspection, factors) => {
	if (!list) return {};
	return list.reduce((acc, f) => {
		if (factors[f].type === 'select' && inspection[f] && inspection[f].length > 0) {
			return { ...acc, [f]: inspection[f].join(', ') };
		} else if (inspection[f] > 0) {
			const val = factors[f].type === 'percent' ? inspection[f] + ' %' : inspection[f];
			return { ...acc, [f]: val };
		} else return acc;
	}, {});
};

export const CalculateCommodity = (inspection, commodityType, factors) => {
	for (let f of factors.calculation.mixed) {
		const factor = factors[f].mixed;
		const index = factor.group.indexOf(commodityType);
		if (index === -1) {
			if (factor.default && factor.default != null) {
				if (compare[factor.comparer](inspection[f], factor.default)) {
					return 'Mixed';
				}
			} else continue;
		} else {
			if (compare[factor.comparer](inspection[f], factor.value[index])) {
				return 'Mixed';
			}
		}
	}

	return commodityType;
};

const CheckSingleFactor = (value, factor) => {
	if (factor.type === 'count' || factor.type === 'percent' || factor.type === 'composite') {
		return value && factor.comparer && compare[factor.comparer](value, factor.value);
	} else if (factor.type === 'select') {
		return value && value.length > 0;
	} else {
		return false;
	}
};

const CheckMultiFactor = (inspection, factor) => {
	outer: for (let i = 0; i < factor.values.length; i++) {
		for (let j = 0; j < factor.factors.length; j++) {
			const target = factor.values[i][j];
			if (target === null) continue;

			const val = inspection[factor.factors[j]];

			if (factor.factors[j].type === 'select') {
				if (val && val.length > 0) continue;
			} else {
				const comparer = factor.comparers[i][j];
				if (val && compare[comparer](val, target)) continue;
			}
			continue outer;
		}
		return true;
	}
	return false;
};

export const CalculateModFactor = (baseValue, value, operator, truncate) => {
	let v = value;
	if (truncate) {
		v = round(truncator(value, truncate), truncate - 1).toFixed(truncate - 1);
	}
	return calculate[operator](baseValue, v);
}

export const CalculateSingleCarrier = (carrier, rexCarrier, inspection, factors) => {
	let result = Object.assign({}, carrier);
	let origValues = {};

	const isRex = rexCarrier != null;
	const changedKeys = Object.keys(carrier.changed ?? {}).filter(k => carrier.changed[k] === true);

	Object.keys(factors).forEach(prop => {
		const props = factors[prop];
		const useRex = isRex && !changedKeys.includes(prop);
		let value = useRex ? rexCarrier[prop] : result[prop];

		if ((!props.allowEmpty && value !== undefined) || (props.allowEmpty && value != null)) {
			if (props.type === 'percent') {
				const sampleSizeName = 'w' + props.sampleSize;
				const sampleSize = useRex ? rexCarrier[sampleSizeName] : result[sampleSizeName];
				value = (value / (sampleSize || 1)) * 100;
				Object.assign(origValues, { [prop]: [useRex ? rexCarrier[prop] : result[prop], sampleSize] });
			} else if (props.type === 'mod') {
				if (isRex && changedKeys.includes(prop)) {
					value = result[prop];
				}
				else {
					const v = isRex && !changedKeys.includes(props.factor) ? rexCarrier[props.factor] : result[props.factor];
					const b = isRex && !changedKeys.includes(props.base) ? rexCarrier[props.base] : result[props.base]
					const t = factors[props.factor].truncate;
					value = CalculateModFactor(b, v, props.operator, t);
				}
			}

			if (props.truncate) {
				value = truncator(value, props.truncate);
				if (props.round !== false) {
					value = round(value, props.truncate - 1).toFixed(props.truncate - 1);
				} else {
					value = value.toFixed(props.truncate);
				}
			}
		}

		if (value) {
			Object.assign(result, { [prop]: value });
		}
	});

	Object.assign(result, { origValues });

	const composite = Object.keys(factors)
		.filter(f => factors[f].type === 'composite')
		.reduce((acc, prop) => {
			if (factors[prop].require === 'all') {
				for (let f of factors[prop].factors) {
					if (!result[f]) return { ...acc, [prop]: null };
				}
			}

			let value = factors[prop].factors.reduce((a, b) => {
				if (result[b]) return a + parseFloat(result[b]);
				else return a;
			}, 0);

			if (factors[prop].truncate) {
				value = truncator(value, factors[prop].truncate);
				if (factors[prop].round !== false) {
					value = round(value, factors[prop].truncate - 1).toFixed(factors[prop].truncate - 1);
				} else {
					value = value.toFixed(factors[prop].truncate);
				}
			}

			return { ...acc, [prop]: value };
		}, {});

	Object.assign(result, composite);

	const isGraded = !factors.calculation.grade.some(f => !result[f]);

	const grade = isGraded ? CalculateGrade(result, factors) : '';

	const commodity = CalculateCommodity(result, inspection['commodityType'], factors);
	const sampleValues = GetSampleGradeResults(result, factors);
	const sampleValuesClient = GetSampleFactorsClient(result, factors);
	const specialValues = GetSpecialGradeResults(result, factors);
	const specialValuesClient = GetSpecialFactorsClient(result, factors);
	const otherValues = GetOtherGradeResults(result, factors);
	const specialGrade = CalculateSpecialGrade(result, factors);

	const gradeCommodity = isGraded ? `${grade} ${commodity} ${inspection['commodityGroup']}` : '';

	return Object.assign(result, {
		commodity: `${commodity} ${inspection['commodityGroup']}`,
		sampleValues,
		sampleValuesClient,
		specialValues,
		specialValuesClient,
		otherValues,
		special: specialGrade,
		grade: gradeCommodity,
		isInfoComplete: result.carNumber !== '' && result.netWeight !== '' && result.inspector !== '',
		isGraded
	});
};

/*eslint-disable*/
export const compare = {
	'==': (a, b) => a == b,
	'!=': (a, b) => a != b,
	'>=': (a, b) => a >= b,
	'>': (a, b) => a > b,
	'<': (a, b) => a < b,
	'<=': (a, b) => a <= b,
	'><': (a, b) => a > b[0] && a < b[1],
	'><=': (a, b) => a > b[0] && a <= b[1],
	'[]': (a, b) => a && a.length > 0
};

export const calculate = {
	'-': (a, b) => a - b
};
/*eslint-enable*/
