import omit from 'lodash/omit';
import {
	AUTH_DESTROYED,
	REQUEST_LOCATIONS,
	REQUEST_LOCATIONS_FINISHED,
	RECEIVE_LOCATION_ADDED,
	RECEIVE_LOCATION_REMOVED,
	RECEIVE_LOCATION_MODIFIED
} from '../Actions/types';

const locations = (state = { allIds: [], byId: {}, isFetching: false }, action) => {
	switch (action.type) {
		case AUTH_DESTROYED:
			return { allIds: [], byId: {}, isFetching: false };

		case REQUEST_LOCATIONS:
			return {
				...state,
				isFetching: true
			};

		case RECEIVE_LOCATION_ADDED:
			return {
				...state,
				allIds: [...state.allIds, action.id],
				byId: { ...state.byId, [action.id]: action.added }
			};

		case RECEIVE_LOCATION_REMOVED:
			return {
				...state,
				allIds: state.allIds.filter(id => id !== action.id),
				byId: omit(state.byId, action.id)
			};

		case RECEIVE_LOCATION_MODIFIED:
			return {
				...state,
				byId: { ...state.byId, [action.id]: action.modified }
			};

		case REQUEST_LOCATIONS_FINISHED:
			return {
				...state,
				isFetching: false
			};

		default:
			return state;
	}
};

export default locations;
