import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchArchives, restoreInspection } from 'Actions';
import { CONFIRM_LOAD_ARCHIVE, CONFIRM_RESTORE_ARCHIVED_INSP } from 'lib/constants';
import { StringComparer, TimeComparer, filterCollection } from 'lib/helpers';
import { getArchivedInspections } from 'Selectors';
import { Menu, Table } from 'antd';
import { Button, MenuButton, Search, Modal } from 'Controls';
import { ConfirmDialog, DownloadDocumentDialog } from 'Components/Dialogs';
import RestoreInspectionDialog from './RestoreInspectionDialog';
import styles from './Admin.module.css';

const filterOptions = [
	{ text: 'Corn', value: 'Corn' },
	{ text: 'Soybeans', value: 'Soybeans' },
	{ text: 'Wheat', value: 'Wheat' }
];

const ManageArchive = () => {
	const dispatch = useDispatch();

	const [restore, setRestore] = React.useState(false);
	const [restoreById, setRestoreById] = React.useState(null);
	const [document, setDocument] = React.useState(null);
	const [load, setLoad] = React.useState(false);
	const [sorter, setSorter] = React.useState({ columnKey: 'archivedDate', order: 'descend' });
	const [filters, setFilters] = React.useState({});
	const [textFilter, setTextFilter] = React.useState('');

	const inspectionsDisplay = useSelector(getArchivedInspections);

	const handleChange = (pagination, filters, sorter) => {
		setSorter(sorter);
		setFilters(filters);
	};

	const columnSort = key => sorter.columnKey === key && sorter.order;

	return (
		<div className={styles.Container}>
			<div className={styles.Topbar}>
				<Button color="primary" onClick={() => setRestoreById(true)}>
					Restore...
				</Button>
				{inspectionsDisplay.isLoaded && <Search placeholder="Search Archive" value={textFilter} onChange={e => setTextFilter(e.target.value)} />}
			</div>
			{!inspectionsDisplay.isLoaded && (
				<div className={styles.FlexCenter}>
					<Button color="warning" size="medium" onClick={() => setLoad(true)}>
						Load Archives
					</Button>
				</div>
			)}
			{inspectionsDisplay.isLoaded && (
				<div className={styles.Container}>
					<Table
						rowKey="id"
						size="small"
						showSorterTooltip={false}
						loading={inspectionsDisplay.isFetching}
						dataSource={filterCollection(textFilter, inspectionsDisplay.inspections)}
						scroll={{ scrollToFirstRowOnChange: true }}
						pagination={{ showSizeChanger: true, size: 'default', defaultPageSize: 20, showTotal: total => `${total} Inspections` }}
						onChange={handleChange}>
						<Table.Column title="Archived" key="archivedDate" dataIndex="archivedDate" sorter={TimeComparer('archivedDate')} sortOrder={columnSort('archivedDate')} />
						<Table.Column title="Location" key="location" dataIndex="location" sorter={StringComparer('location')} sortOrder={columnSort('location')} />
						<Table.Column
							title="Commodity"
							key="commodity"
							dataIndex="commodity"
							sorter={StringComparer('commodity')}
							sortOrder={columnSort('commodity')}
							filters={filterOptions}
							filteredValue={filters.commodity}
							onFilter={(value, record) => record.commodity.endsWith(value)}
						/>
						<Table.Column
							title="Inspector"
							key="inspector"
							dataIndex="inspector"
							sorter={StringComparer('inspector')}
							sortOrder={columnSort('inspector')}
						/>
						<Table.Column
							title="Departure"
							key="departureTime"
							dataIndex="departureTime"
							sorter={TimeComparer('departureTime')}
							sortOrder={columnSort('departureTime')}
						/>
						<Table.Column
							key="actions"
							dataIndex="id"
							width={1}
							render={(cellData, rowData) => (
								<MenuButton>
									{rowData.docsGenerated && (
										<Menu.SubMenu title="Documents">
											<Menu.Item onClick={() => setDocument({ id: cellData, type: 'xlsx' })}>Spreadsheet</Menu.Item>
											<Menu.Item onClick={() => setDocument({ id: cellData, type: 'pdf' })}>PDF</Menu.Item>
										</Menu.SubMenu>
									)}
									<Menu.Item onClick={() => setRestore(cellData)}>Restore</Menu.Item>
								</MenuButton>
							)}
						/>
					</Table>
				</div>
			)}
			<Modal show={restoreById}>
				<RestoreInspectionDialog onCancel={() => setRestoreById(false)} />
			</Modal>
			<Modal show={load}>
				<ConfirmDialog
					title="Load Archives"
					body={CONFIRM_LOAD_ARCHIVE}
					buttonText="Continue"
					buttonColor="warning"
					onCancel={() => setLoad(false)}
					onAccept={() => dispatch(fetchArchives())}
				/>
			</Modal>
			<Modal show={restore}>
				<ConfirmDialog
					title="Restore Inspection"
					body={CONFIRM_RESTORE_ARCHIVED_INSP}
					buttonText="Restore"
					onCancel={() => setRestore(null)}
					onAccept={() => restoreInspection(restore)}
				/>
			</Modal>
			<Modal show={document}>
				<DownloadDocumentDialog {...document} onCancel={() => setDocument(null)} />
			</Modal>
		</div>
	);
};

export default ManageArchive;
