export const CONFIRM_DELETE_INSPECTION = 'Are you certain you want to delete this inspection? You will have approximately 48 hours to restore it if neccessary.';
export const CONFIRM_ARCHIVE_INSPECTION = 'Are you certain you want to archive this inspection? You can retrieve it later if necessary.';

export const CONFIRM_SEND_EMAILS ='Are you ready to send client emails regarding this inspection?';
export const CONFIRM_RESEND_EMAILS ='Emails have already been sent to clients regarding this inspection. Do you want to resend the emails?';

export const ERROR_DOCUMENT_FETCH_FAIL = 'An error occurred while attempting to fetch the document. It may no longer exist in storage.';

export const CONFIRM_DELETE_CARRIER = 'Are you certain you want to delete this carrier?';

export const CONFIRM_ENABLE_USER ='Are you certain you want to enable this user account?';
export const CONFIRM_DISABLE_USER = 'Are you certain you want to disable this user? They will not be able to login until their account is enabled again.';
export const CONFIRM_DELETE_USER = 'Are you certain you want to delete this user? This action cannot be undone.';

export const CONFIRM_SEND_PASSWORD_RESET = 'This will send the user %s an email allowing them to reset their password. Continue?';

export const ERROR_RESTORE_INSP_INVALID_ID = 'Error: The given ID does not exist. The ID may be incorrect or has been purged from the archive.';
export const ERROR_RESTORE_INSP_NOT_ARCHIVED = 'Error: The given ID belongs to an inspection that has not been archived.';

export const INFO_RESTORE_INSP_SUCCESS = 'The inspection has been successfully restored.';

export const CONFIRM_REASSIGN_INSPECTION = 'Select the inspector you want to reassign this inspection to. Please note that their current inspection will no longer be assigned to them.';

export const CONFIRM_LOAD_ARCHIVE = 'Loading all archived inspections may require a lot of time and data. You can restore an archived inspection without loading all of them if you have the correct inspection ID. It is better to avoid loading the archives if possible. Continue anyways?';

export const CONFIRM_RESTORE_ARCHIVED_INSP = 'Are you certain you want to restore this inspection?';
export const CONFIRM_RESTORE_DELETED_INSP = 'Are you certain you want to restore this inspection?';

export const COFIRM_DELETE_LOCATION = 'Are you certain you want to delete this location? This action cannot be undone.';