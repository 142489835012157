import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import range from 'lodash/range';
import { changeCarrierView, setCurrentFactor, setFactorScroll, updateCarrierFactors } from 'Actions';
import FactorDisplayNames from 'models/factors/names';
import GradeFactorModels from 'models/gradeFactorDisplay';
import { getInspection, getCarrierInspection } from 'Selectors';
import { NumericFormat } from 'react-number-format';
import { CarrierHeader } from 'Components';
import { Spinner, Button } from 'Controls';
import styles from './GradeFactors.module.css';

const numbers = range(0, 100.1, 0.1).map(n => n.toFixed(1));

const checkAllFactorsSet = (inspection, f) => {
	for (const key of Object.keys(f)) {
		if (inspection[key] === undefined && !f[key].allowEmpty) {
			return false;
		}
	}
	return true;
};

const GradeFactors = () => {
	const dispatch = useDispatch();

	const { inspection, carrierId } = useSelector(getInspection);
	const factorScroll = useSelector(state => state.factorScroll);
	const carrier = useSelector(state => getCarrierInspection(state).calculatedCarrier);

	const [sampleWeight, setSampleWeight] = React.useState(carrier.w1050 ?? '');

	const factors = GradeFactorModels[inspection.commodityGroup];
	const complete = checkAllFactorsSet(carrier, factors);

	React.useEffect(() => {
		setSampleWeight(carrier.w1050);
	}, [carrier.w1050]);

	const handleBlur = e => {
		if (carrier.w1050 !== sampleWeight) {
			dispatch(updateCarrierFactors(carrierId, { w1050: sampleWeight ?? '' }));
		}
	};

	return (
		<div className={styles.Root}>
			<CarrierHeader>
				<div className={styles.SampleWeight}>
					<label className={`label ${styles.Label}`}>Sample Weight</label>
					<NumericFormat
						autoFocus
						value={sampleWeight}
						className="input"
						onBlur={handleBlur}
						onValueChange={({ floatValue }) => setSampleWeight(floatValue ?? '')}
					/>
				</div>
				<div className="buttons">
					<Button size="large" onClick={() => dispatch(changeCarrierView('carrier'))}>
						Back
					</Button>
					<Button size="large" color="success" disabled={!complete} onClick={() => dispatch(changeCarrierView('confirm'))}>
						Confirm
					</Button>
				</div>
			</CarrierHeader>
			<div className={styles.Columns}>
				{Object.keys(factors).map(key => {
					const factor = factors[key];
					const value = factor.allowEmpty ? carrier[key] : isNaN(carrier[key]) ? factor.default : carrier[key];
					return (
						<div key={key} className={styles.Column}>
							<Button
								fullWidth
								size="large"
								color={factors[key].isCalculated ? 'warning' : 'info'}
								onClick={() => {
									if (factors[key].isCalculated) {
										dispatch(setCurrentFactor(key, factors[key]));
										dispatch(changeCarrierView('calcFactor'));
									}
								}}>
								{FactorDisplayNames[key]}
							</Button>
							{factor.spinner !== false && (
								<Spinner
									key={key}
									values={numbers}
									value={value}
									scrollValue={factorScroll[key] ?? factor.scroll}
									onChange={value => {
										if (!factor.isCalculated) {
											dispatch(updateCarrierFactors(carrierId, { [key]: value }));
											dispatch(setFactorScroll(key, value));
										}
									}}
								/>
							)}
							{factor.spinner === false && factor.allowEmpty && carrier[key] && (
								<Button
									fullWidth
									size="large"
									color={carrier[key] && 'primary'}
									className={styles.Button}
									onClick={() => {
										if (!carrier[key])
											dispatch(
												updateCarrierFactors(carrierId, {
													[key]: factor.default,
													['w' + factor.sampleSize]: carrier['w' + factor.sampleSize] ? carrier['w' + factor.sampleSize] : null
												})
											);
									}}>
									{value}
								</Button>
							)}
							{factor.spinner === false && !factor.allowEmpty && (
								<Button
									fullWidth
									size="large"
									color={carrier[key] && 'primary'}
									className={styles.Button}
									onClick={() => {
										if (!carrier[key])
											dispatch(
												updateCarrierFactors(carrierId, {
													[key]: factor.default,
													['w' + factor.sampleSize]: carrier['w' + factor.sampleSize] ? carrier['w' + factor.sampleSize] : null
												})
											);
									}}>
									{value}
								</Button>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default GradeFactors;
