import React from 'react';
import { connect } from 'react-redux';
import { setFactorScroll, updateCarrierFactors } from 'Actions';
import { round, truncator, isNumeric } from 'lib/helpers';
import FactorDisplayNames from '../models/factors/names';
import { CalculateModFactor } from '../models/grading';
import { getFactors, getInspection, getCarrierInspection } from 'Selectors';
import { Button, NumPad, FieldLabel, DecimalInput } from 'Controls';
import styles from './CalcFactor.module.css';

class CalcFactor extends React.Component {
	constructor(props) {
		super(props);

		const isRex = props.currentCarrier.carrier.rexId != null;

		this.state = {
			input: null,
			factors: {},
			isRex
		};

		const changed = props.currentCarrier.calculatedCarrier.changed ?? {};
		const changedKeys = Object.keys(changed).filter(k => changed[k] === true);

		if (props.currentFactor.factor.type === 'multi') {
			props.currentFactor.factor.factors.forEach(f => {
				const fWeight = 'w' + props.factors[f].sampleSize;
				let mValue = props.currentCarrier.carrier[f];
				let mTotal = props.currentCarrier.carrier[fWeight];

				const clearPart = isRex && !changedKeys.includes(f);
				const clearTotal = isRex && !changedKeys.includes(fWeight);

				if (clearPart) {
					mValue = null;
				}
				if (clearTotal) {
					mTotal = null;
				}

				this.state.factors[f] = mValue || '0.00';
				this.state.factors[fWeight] = mTotal || '';
			});
		} else {
			this.state.total = props.currentCarrier.carrier['w' + props.currentFactor.factor.sampleSize] || '';
			this.state.part = props.currentCarrier.carrier[props.currentFactor.name] || '0.00';
			this.state.input = null;

			if (props.currentFactor.factor.sampleSize === '125-fines' && !isRex) {
				const fines = round(props.currentCarrier.carrier['fmFine'], 1).toFixed(1);
				const total = props.currentCarrier.carrier['w125'] - fines;
				this.state.total = round(total, 0);
				this.state.disableTotal = true;
			}

			const clearPart = isRex && !changedKeys.includes(props.currentFactor.name);
			const clearTotal = isRex && !changedKeys.includes('w' + props.currentFactor.factor.sampleSize);

			if (clearTotal) {
				this.state.total = '';
			}
			if (clearPart) {
				this.state.part = '0.00';
			}
		}
	}

	calculatePercent(precision) {
		const percent = parseFloat((this.state.part / this.state.total) * 100);
		return !isNaN(percent) && percent !== Infinity ? round(truncator(percent, precision), precision).toFixed(precision) : '0.00';
	}

	calculatePercentMulti(f, precision) {
		const percent = parseFloat((this.state.factors[f] / this.state.factors['w' + this.props.factors[f].sampleSize]) * 100);
		return !isNaN(percent) && percent !== Infinity ? round(truncator(percent, precision), precision).toFixed(precision) : '0.00';
	}

	render() {
		if (this.props.currentFactor.factor.type !== 'multi') {
			return (
				<div className={styles.Root}>
					<div className="container">
						<div className={styles.Columns}>
							<div className={styles.Column}>
								<h1 className="title">{FactorDisplayNames[this.props.currentFactor.name]}</h1>
								<FieldLabel label="Total Grams">
									<input
										disabled={this.state.disableTotal}
										autoFocus
										className="input"
										type="number"
										value={this.state.total}
										onFocus={e => this.setState({ input: 'total' })}
										onChange={e => this.setState({ total: e.target.value })}
									/>
								</FieldLabel>
								<FieldLabel label={`${FactorDisplayNames[this.props.currentFactor.name]} Grams`}>
									<DecimalInput
										autoFocus={this.state.total}
										precision={2}
										onFocus={e => this.setState({ input: 'part' })}
										value={this.state.part}
										onChange={e => this.setState({ part: e })}
									/>
								</FieldLabel>
								<h1 className="title">{this.calculatePercent(this.props.currentFactor.factor.truncate || 2)} %</h1>
								<div className="buttons">
									<Button
										color="primary"
										size="large"
										disabled={!isNumeric(this.state.total) || !isNumeric(this.state.part)}
										onClick={() => {
											this.props.updateCarrierFactors(this.props.currentInspection.carrierId, {
												[this.props.currentFactor.name]: this.state.part,
												[`w${this.props.currentFactor.factor.sampleSize}`]: this.state.total
											});
											const value = ((this.state.part / this.state.total) * 100).toFixed(this.props.currentFactor.factor.truncate || 2);
											this.props.onAccept && this.props.onAccept(value);
										}}>
										Confirm
									</Button>
									<Button size="large" onClick={() => this.props.onCancel && this.props.onCancel()}>
										Cancel
									</Button>
									<div className={styles.Flex} />
									{this.props.currentFactor.factor.allowEmpty && !this.state.isRex &&
										<Button color="danger" size="large" variant="outlined" onClick={() => {
											this.props.updateCarrierFactors(this.props.currentInspection.carrierId, {
												[this.props.currentFactor.name]: null
											});
											this.props.onCancel && this.props.onCancel();
										}}>
											Clear
										</Button>
									}
								</div>
							</div>
							<NumPad
								className={styles.Numpad}
								onClick={num => {
									if (this.state.input === 'part') {
										let sanitize = this.state.part.toString().replace('.', '') + num.toString();
										let value = sanitize * Math.pow(10, -this.props.currentFactor.factor.truncate || -2);
										this.setState({ [this.state.input]: value.toFixed(this.props.currentFactor.factor.truncate || 2) });
									} else {
										let val = this.state[this.state.input];
										this.setState({ [this.state.input]: val + num.toString() });
									}
								}}
								onDelete={() => {
									if (this.state.input === 'part') {
										let sanitize = this.state.part.toString().replace('.', '');
										sanitize = sanitize.substring(0, sanitize.length - 1);
										let value = sanitize * Math.pow(10, -this.props.currentFactor.factor.truncate || -2);
										this.setState({ [this.state.input]: value.toFixed(this.props.currentFactor.factor.truncate || 2) });
									} else {
										let val = this.state[this.state.input];
										this.setState({ [this.state.input]: val.substring(0, val.length - 1) });
									}
								}}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			const buttonOk = this.props.currentFactor.factor.factors.reduce((acc, v) => {
				if (!this.state.factors[v] || !this.state.factors['w' + this.props.factors[v].sampleSize]) return false;
				else return acc;
			}, true);

			return (
				<div className={styles.Root}>
					<div className="container">
						<div className={styles.Columns}>
							<div className={styles.Column}>
								{this.props.currentFactor.factor.factors.map((f, index) => {
									return (
										<React.Fragment key={index}>
											<h1 className="title">{FactorDisplayNames[f]}</h1>
											<FieldLabel label="Total Grams">
												<input
													className="input"
													type="number"
													value={this.state.factors['w' + this.props.factors[f].sampleSize]}
													onFocus={e => this.setState({ input: f + '_total' })}
													onChange={e =>
														this.setState({
															factors: { ...this.state.factors, ['w' + this.props.factors[f].sampleSize]: e.target.value }
														})
													}
												/>
											</FieldLabel>
											<FieldLabel label={`${FactorDisplayNames[f]} Grams`}>
												<DecimalInput
													precision={2}
													onFocus={e => this.setState({ input: f + '_part' })}
													value={this.state.factors[f]}
													onChange={e => this.setState({ factors: { ...this.state.factors, [f]: e } })}
												/>
											</FieldLabel>
											<div className="field">
												<h1 className="title">{this.calculatePercentMulti(f, this.props.factors[f].truncate || 2)} %</h1>
											</div>
										</React.Fragment>
									);
								})}
								<div className="buttons">
									<Button
										color="primary"
										size="large"
										disabled={!buttonOk}
										onClick={() => {
											const factors = this.state.factors;

											if (this.props.currentFactor.factor.mod) {
												const modFactor = this.props.factors[this.props.currentFactor.factor.mod];
												const b = this.state.factors[modFactor.base];
												const v = this.state.factors[modFactor.factor];
												const t = this.props.factors[modFactor.factor].truncate;
												let value = CalculateModFactor(b, v, modFactor.operator, t);
												const t2 = modFactor.truncate;
												if (t2) {
													value = truncator(value, t2);
													if (modFactor.round !== false) {
														value = round(value, t2 - 1).toFixed(t2 - 1);
													} else {
														value = value.toFixed(t2);
													}
												}
												factors[this.props.currentFactor.factor.mod] = value;
											}

											this.props.updateCarrierFactors(this.props.currentInspection.carrierId, { ...factors });
											this.props.onAccept && this.props.onAccept(null);
										}}>
										Confirm
									</Button>
									<Button size="large" onClick={() => this.props.onCancel && this.props.onCancel()}>
										Cancel
									</Button>
								</div>
							</div>
							<NumPad
								className={styles.Numpad}
								onClick={num => {
									if (!this.state.input) return;
									const t = this.state.input.split('_');
									const f = t[0];
									if (t[1] === 'part') {
										let sanitize = this.state.factors[f].toFixed(this.props.factors[f].truncate || 2).replace('.', '') + num.toString();
										let value = sanitize * Math.pow(10, -this.props.factors[f].truncate || -2);
										this.setState({ factors: { ...this.state.factors, [f]: value.toFixed(this.props.factors[f].truncate || 2) } });
									} else {
										let val = this.state.factors['w' + this.props.factors[f].sampleSize];
										this.setState({
											factors: {
												...this.state.factors,
												['w' + this.props.factors[f].sampleSize]: val + num.toString()
											}
										});
									}
								}}
								onDelete={() => {
									if (!this.state.input) return;
									const t = this.state.input.split('_');
									const f = t[0];
									if (t[1] === 'part') {
										let sanitize = this.state.factors[f].toFixed(this.props.factors[f].truncate || 2).replace('.', '');
										sanitize = sanitize.substring(0, sanitize.length - 1);
										let value = sanitize * Math.pow(10, -this.props.factors[f].truncate || -2);
										this.setState({ factors: { ...this.state.factors, [f]: value.toFixed(this.props.factors[f].truncate || 2) } });
									} else {
										let val = this.state.factors['w' + this.props.factors[f].sampleSize];
										this.setState({
											factors: {
												...this.state.factors,
												['w' + this.props.factors[f].sampleSize]: val.substring(0, val.length - 1)
											}
										});
									}
								}}
							/>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		currentInspection: getInspection(state),
		currentFactor: state.currentFactor,
		currentCarrier: getCarrierInspection(state),
		factors: getFactors(state)
	};
};

export default connect(mapStateToProps, {
	updateCarrierFactors,
	setFactorScroll
})(CalcFactor);
