import React from 'react';
import { connect } from 'react-redux';
import { addUser, deleteUser, updateUser, sendPasswordReset, setUserPassword, setUserDisabled } from 'Actions';
import { StringComparer, TimeComparer } from '../../lib/helpers';
import { filterCollection } from '../../lib/helpers';
import { getLocations, getUserProfile, getUsers } from 'Selectors';
import { ConfirmDialog, SetPasswordDialog } from 'Components/Dialogs';
import EditUserDialog from './EditUserDialog';
import { Menu, Table } from 'antd';
import moment from 'moment';
import { CONFIRM_DISABLE_USER, CONFIRM_ENABLE_USER, CONFIRM_DELETE_USER, CONFIRM_SEND_PASSWORD_RESET } from '../../lib/constants';
import format from 'format-util';
import { Button, MenuButton, Search, Modal } from 'Controls';
import styles from './Admin.module.css';

const { Column } = Table;

class UsersGrid extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			setPassModal: null,
			disableModal: null,
			sendResetModal: null,
			addEditModal: null,
			deleteModal: null,
			sorter: {
				columnKey: 'email',
				order: 'ascend'
			},
			filterText: ''
		};
	}

	handleChange = (pagination, filters, sorter) => {
		this.setState({ sorter });
	};

	columns = [
		{ title: 'Email', key: 'email' },
		{ title: 'Display Name', key: 'displayName' },
		{ title: 'Role', key: 'role' },
		{ title: 'Last Login', key: 'lastLogin', render: date => (date ? moment(date).fromNow() : ''), sorter: TimeComparer('lastLogin') }
	];

	render() {
		const { usersDisplay, user, addUser, updateUser, deleteUser, sendPasswordReset, setUserPassword, setUserDisabled } = this.props;
		const { users, isFetching } = usersDisplay;

		return (
			<div className={styles.Container}>
				<div className={styles.Topbar}>
					<Button color="primary" onClick={() => this.setState({ addEditModal: { onAccept: data => addUser(data) } })}>
						Add
					</Button>
					<Search placeholder="Search Users" value={this.state.filterText} onChange={e => this.setState({ filterText: e.target.value })} />
				</div>

				<div className={styles.Container}>
					<Table
						loading={isFetching}
						rowKey="uid"
						size="small"
						showSorterTooltip={false}
						rowClassName={row => (row.disabled ? styles.DisabledRow : '')}
						dataSource={filterCollection(this.state.filterText, users)}
						scroll={{ scrollToFirstRowOnChange: true }}
						pagination={{ position: ['none', 'bottomCenter'], showSizeChanger: true, size: 'default', defaultPageSize: 20, showTotal: total => `${total} Users` }}
						onChange={this.handleChange}>
						{this.columns.map(col => (
							<Column
								title={col.title}
								dataIndex={col.key}
								render={col.render || null}
								key={col.key}
								sorter={col.sorter || StringComparer(col.key)}
								sortOrder={this.state.sorter.columnKey === col.key && this.state.sorter.order}
							/>
						))}
						<Table.Column
							key="actions"
							dataIndex="email"
							width={1}
							render={(cellData, rowData) => (
								<MenuButton>
									<Menu.Item
										onClick={() =>
											this.setState({
												addEditModal: {
													...rowData,
													isEditing: true,
													isSelf: cellData === this.props.currentUser.email,
													onAccept: data => updateUser(cellData, data)
												}
											})
										}>
										Edit
									</Menu.Item>
									<Menu.SubMenu key="pass" title="Password">
										<Menu.Item onClick={() => this.setState({ setPassModal: cellData })}>Manual Set</Menu.Item>
										<Menu.Item onClick={() => this.setState({ sendResetModal: cellData })}>Reset Email</Menu.Item>
									</Menu.SubMenu>
									{user.email !== cellData && rowData.disabled === true && (
										<Menu.Item
											className="has-text-warning"
											onClick={() => this.setState({ disableModal: { disable: false, email: cellData } })}>
											Enable
										</Menu.Item>
									)}
									{user.email !== cellData && !rowData.disabled && (
										<Menu.Item
											className="has-text-warning"
											onClick={() => this.setState({ disableModal: { disable: true, email: cellData } })}>
											Disable
										</Menu.Item>
									)}
									{user.email !== cellData && (
										<Menu.Item className="has-text-danger" onClick={() => this.setState({ deleteModal: cellData })}>
											Delete
										</Menu.Item>
									)}
								</MenuButton>
							)}
						/>
					</Table>
				</div>

				<Modal show={this.state.addEditModal}>
					<EditUserDialog
						{...this.state.addEditModal}
						list={this.props.locations}
						onCancel={() => this.setState({ addEditModal: null })}
						onAccept={data => this.state.addEditModal.onAccept(data)}
					/>
				</Modal>
				<Modal show={this.state.sendResetModal}>
					<ConfirmDialog
						title="Send Password Reset"
						body={format(CONFIRM_SEND_PASSWORD_RESET, this.state.sendResetModal)}
						buttonText="Send Reset Email"
						onCancel={() => this.setState({ sendResetModal: null })}
						onAccept={() => sendPasswordReset(this.state.sendResetModal)}
					/>
				</Modal>
				<Modal show={this.state.setPassModal}>
					<SetPasswordDialog
						user={this.state.setPassModal}
						onCancel={() => this.setState({ setPassModal: null })}
						onAccept={password => setUserPassword(this.state.setPassModal, password)}
					/>
				</Modal>
				<Modal show={this.state.disableModal}>
					<ConfirmDialog
						title={this.state.disableModal?.disable ? 'Disable User' : 'Enable User'}
						body={
							<>
								{this.state.disableModal?.disable ? CONFIRM_DISABLE_USER : CONFIRM_ENABLE_USER}
								<br />
								<br />
								{this.state.disableModal?.email}
							</>
						}
						buttonText={this.state.disableModal?.disable ? 'Disable' : 'Enable'}
						buttonColor="warning"
						onCancel={() => this.setState({ disableModal: null })}
						onAccept={() => setUserDisabled(this.state.disableModal.email, this.state.disableModal.disable)}
					/>
				</Modal>
				<Modal show={this.state.deleteModal}>
					<ConfirmDialog
						title="Delete User"
						body={
							<>
								{CONFIRM_DELETE_USER}
								<br />
								<br />
								{this.state.deleteModal}
							</>
						}
						buttonText="Delete"
						buttonColor="danger"
						onCancel={() => this.setState({ deleteModal: null })}
						onAccept={() => deleteUser(this.state.deleteModal)}
					/>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		usersDisplay: getUsers(state),
		user: getUserProfile(state),
		currentUser: state.user.auth,
		locations: getLocations(state).list
	};
};

export default connect(mapStateToProps, {
	addUser,
	deleteUser,
	updateUser,
	sendPasswordReset,
	setUserPassword,
	setUserDisabled
})(UsersGrid);
