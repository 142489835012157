import { createSelector } from 'reselect';
import { GetSampleGradeResults, GetSpecialGradeResults,GetOtherGradeResults } from '../models/grading';
import getCalculatedCarrier from './carrierInspection';
import getCommodityFactors from './factors';

const getFactorResults = (carrier, factors) => {
	return {
		sample: GetSampleGradeResults(carrier.calculatedCarrier, factors),
		special: GetSpecialGradeResults(carrier.calculatedCarrier, factors),
		other: GetOtherGradeResults(carrier.calculatedCarrier, factors)
	};
};

export default createSelector([getCalculatedCarrier, getCommodityFactors], getFactorResults);
