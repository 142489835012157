import React from 'react';
import ReactDOM from 'react-dom';

// const Modal = ({ children }) => ReactDOM.createPortal(<div>{children}</div>, document.getElementById('modal-root'));

const Modal = ({ children, show }) => {
	const content = show && <div>{children}</div>;
	return ReactDOM.createPortal(content, document.getElementById('modal-root'));
};

export default Modal;
