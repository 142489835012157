import { CHANGE_VIEW, AUTH_DESTROYED } from '../../Actions/types';

const window = (state = '', action) => {
	switch (action.type) {
		// case SET_CURRENT_INSPECTION:
			// if (action.id !== null) {
			// return 'inspection';
			// } else {
			// 	return '';
			// }
		case CHANGE_VIEW:
            return action.view;
        case AUTH_DESTROYED: 
            return '';
		default:
			return state;
	}
};

export default window;
