// MAIN
export const commodityGroups = ['Corn', 'Soybeans', 'Wheat'];

export const commodityTypes = {
	Corn: ['Yellow', 'White'],
	Soybeans: ['Yellow'],
	Wheat: ['Soft Red Winter', 'Hard Red Winter', 'Hard Red Spring', 'Durum', 'Soft White', 'Hard White', 'Unclassed', 'Mixed']
};

export const lotTypes = ['Rail', 'Barge', 'Container', 'Truck'];

export const inspectionTypes = ['Domestic', 'Export'];

export const sampleTypes = ['Official', 'Submit'];

export const sampleGroupings = ['Individual', '5-Car Composite', '5-Car Average'];

// BILLING
export const sampleMethods = ['DT', 'Probe', 'Pelican', 'Ellis Cup'];
