import omit from 'lodash/omit';
import {
	AUTH_DESTROYED,
	RECEIVE_ARCHIVE_ADDED,
	RECEIVE_ARCHIVE_MODIFIED,
	RECEIVE_ARCHIVE_REMOVED,
	REQUEST_ARCHIVES,
	REQUEST_ARCHIVES_FINISHED
} from '../Actions/types';

const defaultState = { allIds: [], byId: {}, isFetching: false, isLoaded: false };

const archiveInspections = (state = defaultState, action) => {
	switch (action.type) {
		case AUTH_DESTROYED:
			return defaultState;

		case REQUEST_ARCHIVES:
			return {
				...state,
				isFetching: true
			};

		case RECEIVE_ARCHIVE_ADDED:
			return {
				...state,
				allIds: [...state.allIds, action.id],
				byId: { ...state.byId, [action.id]: action.added }
			};

		case RECEIVE_ARCHIVE_REMOVED:
			return {
				...state,
				allIds: state.allIds.filter(id => id !== action.id),
				byId: omit(state.byId, action.id)
			};

		case RECEIVE_ARCHIVE_MODIFIED:
			return {
				...state,
				byId: { ...state.byId, [action.id]: action.modified }
			};

		case REQUEST_ARCHIVES_FINISHED:
			return {
				...state,
				isFetching: false,
				isLoaded: true
			};

		default:
			return state;
	}
};

export default archiveInspections;
