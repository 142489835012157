/****************************************************************************
 * INIT
 ***************************************************************************/
export const APP_INITIALIZED = 'APP_INITIALIZED';

/****************************************************************************
 * Collection Requests
 ***************************************************************************/
export const REQUEST_INSPECTIONS = 'REQUEST_INSPECTIONS';
export const REQUEST_INSPECTIONS_FINISHED = 'REQUEST_INSPECTIONS_FINISHED';

export const REQUEST_CARRIERS = 'REQUEST_CARRIERS';
export const REQUEST_CARRIERS_FINISHED = 'REQUEST_CARRIERS_FINISHED';

export const REQUEST_REX = 'REQUEST_REX';
export const REQUEST_REX_FINISHED = 'REQUEST_REX_FINISHED';

export const REQUEST_OFF = 'REQUEST_OFF';
export const REQUEST_OFF_FINISHED = 'REQUEST_OFF_FINISHED';

export const REQUEST_LOCATIONS = 'REQUEST_LOCATIONS';
export const REQUEST_LOCATIONS_FINISHED = 'REQUEST_LOCATIONS_FINISHED';

export const REQUEST_ARCHIVES = 'REQUEST_ARCHIVES';
export const REQUEST_ARCHIVES_FINISHED = 'REQUEST_ARCHIVES_FINISHED';

export const REQUEST_USERS = 'REQUEST_USERS';
export const REQUEST_USERS_FINISHED = 'REQUEST_USERS_FINISHED';

/****************************************************************************
 * Received Changes from Firestore
 ***************************************************************************/
export const RECEIVE_INSPECTION_ADDED = 'RECEIVED_INSPECTION_ADDED';
export const RECEIVE_INSPECTION_MODIFIED = 'RECEIVED_INSPECTION_MODIFIED';
export const RECEIVE_INSPECTION_REMOVED = 'RECEIVED_INSPECTION_REMOVED';

export const RECEIVE_CARRIER_ADDED = 'RECEIVE_CARRIER_ADDED';
export const RECEIVE_CARRIER_MODIFIED = 'RECEIVE_CARRIER_MODIFIED';
export const RECEIVE_CARRIER_REMOVED = 'RECEIVE_CARRIER_REMOVED';

export const RECEIVE_REX_ADDED = 'RECEIVE_REX_ADDED';
export const RECEIVE_REX_MODIFIED = 'RECEIVE_REX_MODIFIED';
export const RECEIVE_REX_REMOVED = 'RECEIVE_REX_REMOVED';

export const RECEIVE_OFF_ADDED = 'RECEIVE_OFF_ADDED';
export const RECEIVE_OFF_MODIFIED = 'RECEIVE_OFF_MODIFIED';
export const RECEIVE_OFF_REMOVED = 'RECEIVE_OFF_REMOVED';

export const RECEIVE_USER_ADDED = 'RECEIVE_USER_ADDED';
export const RECEIVE_USER_MODIFIED = 'RECEIVE_USER_MODIFIED';
export const RECEIVE_USER_REMOVED = 'RECEIVE_USER_REMOVED';

export const RECEIVE_LOCATION_ADDED = 'RECEIVE_LOCATION_ADDED';
export const RECEIVE_LOCATION_MODIFIED = 'RECEIVE_LOCATION_MODIFIED';
export const RECEIVE_LOCATION_REMOVED = 'RECEIVE_LOCATION_REMOVED';

export const RECEIVE_ARCHIVE_ADDED = 'RECEIVE_ARCHIVE_ADDED';
export const RECEIVE_ARCHIVE_MODIFIED = 'RECEIVE_ARCHIVE_MODIFIED';
export const RECEIVE_ARCHIVE_REMOVED = 'RECEIVE_ARCHIVE_REMOVED';

/****************************************************************************
 * Inspection
 ***************************************************************************/
export const ADD_INSPECTION = 'ADD_INSPECTION';
export const INSPECTION_ADDED = 'INSPECTION_ADDED';
export const DELETE_INSPECTION = 'DELETE_INSPECTION';
export const UPDATE_INSPECTION = 'UPDATE_INSPECTION';
export const SET_CURRENT_INSPECTION = 'SET_CURRENT_INSPECTION';

/****************************************************************************
 * Carrier
 ***************************************************************************/
export const ADD_CARRIER = 'ADD_CARRIER';
export const CARRIER_ADDED = 'CARRIER_ADDED';
export const UPDATE_CARRIER = 'UPDATE_CARRIER';
export const SET_CURRENT_CARRIER = 'SET_CURRENT_CARRIER';

/****************************************************************************
 * Reinspection (REX)
 ***************************************************************************/
export const ADD_REX_CARRIER = 'ADD_REX_CARRIER';
export const REX_CARRIER_ADDED = 'REX_CARRIER_ADDED';

/****************************************************************************
 * Offloads (OFF)
 ***************************************************************************/
export const ADD_OFF_CARRIER = 'ADD_OFF_CARRIER';
export const OFF_CARRIER_ADDED = 'OFF_CARRIER_ADDED';

/****************************************************************************
 * User
 ***************************************************************************/
export const ADD_USER = 'ADD_USER';
export const USER_ADDED = 'USER_ADDED';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

/****************************************************************************
 * Error Handling
 ***************************************************************************/
export const AUTH_ERROR = 'AUTH_ERROR';
export const GENERIC_ERROR = 'GENERIC_ERROR';

/****************************************************************************
 * Auth
 ***************************************************************************/
export const INIT_AUTH = 'INIT_AUTH';
export const UPDATE_AUTH = 'UPDATE_AUTH';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_DESTROYED = 'AUTH_DESTROYED';

/****************************************************************************
 * View
 ***************************************************************************/
export const CHANGE_VIEW = 'CHANGE_VIEW';
export const CHANGE_INSPECTION_VIEW = 'CHANGE_INSPECTION_VIEW';
export const CHANGE_CARRIER_VIEW = 'CHANGE_CARRIER_VIEW';

/****************************************************************************
 * Calculated Factors
 ***************************************************************************/
export const SET_CURRENT_FACTOR = 'SET_CURRENT_FACTOR';
export const SET_FACTOR_SCROLL = 'SET_FACTOR_SCROLL';

export const CONFIRM_CARRIER = 'CONFIRM_CARRIER';

export const INSPECTION_COMPLETE = 'INSPECTION_COMPLETE';
export const COMPLETING_INSPECTION = 'COMPLETING_INSPECTION';
export const COMPLETING_INSPECTION_FAILED = 'COMPLETING_INSPECTION_FAILED';

export const IMPORTING_CARRIERS = 'IMPORTING_CARRIERS';
export const CARRIERS_IMPORTED = 'CARRIERS_IMPORTED';

/****************************************************************************
 * Snackbar
 ***************************************************************************/
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
